import React from 'react'
import './NotificationCenterModel.scss'

const NotificationCenterModel = ({ selectedNotificationCenter }) => {
  // console.log("agaga",selectedNotificationCenter)
    const getNotificationType = ()=>{
        if (selectedNotificationCenter.type.is_push) {
            if (selectedNotificationCenter.type.is_email) {
              if (selectedNotificationCenter.type.is_sms) {
                return (
                  <span>الكل </span>
                )
              }
              return (
                <span>اشعارات و بريد </span>
              )
            }
            else if (selectedNotificationCenter.type.is_sms) {
              return (
                <span>اشعارات ورسالة نصية </span>
              )
            }
            return (
              <span>اشعارات </span>
            )
          }
          else {
            if (selectedNotificationCenter.type.is_email) {
              if (selectedNotificationCenter.type.is_sms) {
                return (
                  <span>بريد ورسالة نصية </span>
                )
              }
              return (
                <span>  بريد </span>
              )
            }
            else if (selectedNotificationCenter.type.is_sms) {
              return (
                <span> رسالة نصية </span>
              )
            }
          }
    } 

    return (
        <div className='NotificationCenterModel'>
            <table>
                <tbody>
                    <tr>
                      <td>اسم العميل</td>
                      <td>{selectedNotificationCenter?.customer_name}</td>
                    </tr>
                    <tr>
                        <td>العنوان</td>
                        <td>{selectedNotificationCenter?.subject}</td>
                    </tr>
                    <tr>
                        <td>الموضوع</td>
                        <td dangerouslySetInnerHTML={{ __html: selectedNotificationCenter?.body }}></td>
                    </tr>
                    <tr>
                        <td>نوع الاشعارات</td>
                        <td>{getNotificationType()}</td>
                    </tr>
                    
                </tbody>
            </table>
        </div>
    )
}

export default NotificationCenterModel