import { DollarCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import './SubscriptionStatsGrid.scss';
import cash from '../../assets/imgs/icons/statistics/cash-icon.svg';

const SubscriptionStatsGrid = ({ allSupscriptionStats }) => {
  return (
    <div className="SubscriptionStatsGrid">
      <Row gutter={20}>
        <Col xs={24} sm={24} md={12}>
          <div className="item">
            <div className="item_icon_wrapper">
              <img src={cash} alt="total amount" />
            </div>
            <span className="num">{allSupscriptionStats?.totalAmount}</span>
            <p>المبلغ الكلي</p>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <div className="item">
            <div className="item_icon_wrapper">
              <img src={cash} alt="total amount" />
            </div>
            <span className="num">{allSupscriptionStats?.dailyTotalAmount}</span>
            <p> الدخل اليومي</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SubscriptionStatsGrid;
