import { EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const CustomerUserColums = (
  projectCount,
  setProjectCount,
  token,
  setAdminModalOpened,
  user,
  setTargetClient,
  setReloadingData
) => {
  const handleEditUser = (key, funEdit, record) => {
    setTargetClient(record);
    // console.log(record);
    // console.log(key);
    funEdit(true);
    // setAdminModalOpened(true);
    // setSelectedServId(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'الكود',
      dataIndex: 'otp',
      key: 'otp'
    },
    {
      title: 'تنتهي في',
      dataIndex: 'expires_at',
      key: 'expires_at',
      render: (_, record) => {
        // console.log(record?.expires_at);
        return <div style={{ direction: 'ltr' }}>{record?.expires_at}</div>;
      }
    },
    {
      title: 'تعديل ',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        // console.log(record);
        return (
          <Tooltip title="تعديل البيانات">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditUser(record.key, setAdminModalOpened, record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    }
    // {
    //   title: 'حذف ',
    //   key: 'action',
    //   render: (_, record) => {
    //     return (
    //       <Popconfirm
    //         title={
    //           <div style={{ maxWidth: '200px' }}>
    //             <p>هل أنت متأكد </p>
    //           </div>
    //         }
    //         okText="نعم"
    //         cancelText="لا"
    //         onConfirm={(key) => deleteProjectCall(record.key, record)}>
    //         <Tooltip title="حذف ">
    //           <Button
    //             className="delete-btn"
    //             size="large"
    //             type="dashed"
    //             shape="circle"
    //             icon={<DeleteOutlined />}
    //           />
    //         </Tooltip>
    //       </Popconfirm>
    //     );
    //   }
    // }
  ];
};

export default CustomerUserColums;
