import axios from 'axios';

const addProject = async (values, token) => {
  try {
    const {
      name_ar,
      name_en,
      desc_ar,
      desc_en,
      image_project,
      status,
      appleLink,
      webLink,
      androidLink,
      service_id
    } = values;
    const pickedValues = {
      name_ar,
      name_en,
      desc_ar,
      desc_en,
      status,
      image: image_project[0],
      appleLink,
      webLink,
      androidLink,
      service_id
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    const response = await axios.post('/Project/create', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: 'Bearer ' + token
      }
    });

    // console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default addProject;
