import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingcustomer: false,
  setLoadingcustomer: (load) => {},
  allcustomer: [],
  setAllcustomer: (services) => {},
  questionModalOpened: false,
  setQuestionModalOpened: (v) => {},
  selectedQuestionId: '',
  setSelectedQuestionId: (id) => {},
  selectedQuestion: null,
  setSelectedQuestion: (servKey) => {},
  selectedFilter: '',
  setSelectedFilter: (v) => {},
  searchText: '',
  setSearchText: (v) => {},
  selectedCustomer: null,
  setSelectedCustomer: (servKey) => {},
  customerModalOpened: false,
  setCustomerModalOpened: (v) => {},

};

const CustomerContext = createContext(INITIAL_VALUES);

export const CustomerProvider = ({ children }) => {
  const [selectedFilter, setSelectedFilter] = useState(INITIAL_VALUES.selectedFilter);

  const [searchText, setSearchText] = useState(INITIAL_VALUES.searchText);

  const [allcustomer, setAllcustomer] = useState(INITIAL_VALUES.allcustomer);
  const [loadingcustomer, setLoadingcustomer] = useState(INITIAL_VALUES.loadingcustomer);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [questionModalOpened, setQuestionModalOpened] = useState(
    INITIAL_VALUES.questionModalOpened
  );
  const [selectedQuestionId, setSelectedQuestionId] = useState(INITIAL_VALUES.selectedQuestionId);
  const [selectedQuestion, setSelectedQuestion] = useState(INITIAL_VALUES.selectedQuestion);
  const [selectedCustomer, setSelectedCustomer] = useState(INITIAL_VALUES.selectedCustomer);
  const [customerModalOpened, setCustomerModalOpened] = useState(INITIAL_VALUES.customerModalOpened);


  return (
    <CustomerContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingcustomer,
        setLoadingcustomer,
        selectedFilter,
        setSelectedFilter,
        allcustomer,
        setAllcustomer,
        questionModalOpened,
        setQuestionModalOpened,
        selectedQuestionId,
        setSelectedQuestionId,
        selectedQuestion,
        setSelectedQuestion,
        searchText,
        setSearchText,
        selectedCustomer,
        setSelectedCustomer,
        customerModalOpened,
        setCustomerModalOpened,
      }}>
      {children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
