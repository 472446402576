import axios from 'axios';

const createPosPackage = async (url, values, token, id) => {
  try {
    const {
      icon,
      name,
      description,
      price,
      number_pos
    } = values;
    const pickedValues = {
      name,
      description,
      price,
      number_pos
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    if (id !== 0) {
      formData.append('id', id);
    }

    if (icon && icon[0]) {
      formData.append('icon', icon[0]);
    }
    console.log(formData);
    const createSerivceRes = await axios.post( url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createPosPackage;
