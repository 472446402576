import React from 'react';
import { Descriptions, Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
const ReceiptDetailsComponent = React.forwardRef((props, ref) => {
  // const systemcustomers = useSystemcustomers();
  const { selectedRecipt, allPaymentLogsInfo } = props;
  const meta_data = selectedRecipt?.meta_data || {};

  const renderDiscountValue = (record) => {
    if (record.discount_type == '1') {
      // fixed discount price
      return record.discount_value;
    } else if (record.discount_type == 'percentage') {
      // percentage discount
      return `${record.discount_value}%`;
    } else {
      // no discount
      return 'بدون خصم';
    }
  };

  const renderTitle = (invoice_type) => {
    if (invoice_type === 1) {
      return 'الـبــائع : ';
    } else if (invoice_type === 2) {
      return 'المشـــترى : ';
    }
    return '';
  };

  const renderSupplier = (obj, type) => {
    const { name, phone, address, addressCode, VATRegistrationNumber, tax_number, taxNumber } = obj;
    return (
      <div className="supplier-details-wrap">
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}>
          {renderTitle(type)}
        </div>
        <Descriptions title="" bordered column={1}>
          {name && <Descriptions.Item label="الإســـم">{name}</Descriptions.Item>}
          {phone && <Descriptions.Item label="رقم الهاتف">{phone}</Descriptions.Item>}
          {address && <Descriptions.Item label="العنوان">{address}</Descriptions.Item>}
          {!address && addressCode && (
            <Descriptions.Item label="العنوان">{addressCode}</Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">{tax_number}</Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">{taxNumber}</Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderInfo = (obj, type) => {
    const { phone, address, addressCode, tax_number, taxNumber } = obj;
    return (
      <div className="supplier-details-wrap">
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}>
          {renderTitle(type)}
        </div>
        <Descriptions title="" bordered column={1}>
          <Descriptions.Item label="الإســـم">فاتورة</Descriptions.Item>
          {phone && <Descriptions.Item label="رقم الهاتف">{phone}</Descriptions.Item>}
          {address && <Descriptions.Item label="العنوان">{address}</Descriptions.Item>}
          {!address && addressCode && (
            <Descriptions.Item label="العنوان">{addressCode}</Descriptions.Item>
          )}
          {/* {VATRegistrationNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {VATRegistrationNumber}
            </Descriptions.Item>
          )} */}
          {tax_number && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">{tax_number}</Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">{taxNumber}</Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderModels = () => {
    const MergedObj = {
      ...(meta_data || []),
      ...selectedRecipt?.package,
      total: selectedRecipt?.total,
      discount_cost: selectedRecipt?.discount_cost
    };
    let dataSource = [];
    selectedRecipt?.package && dataSource.push(MergedObj);
    // dataSource.push(selectedRecipt?.package);
    // console.log(dataSource);
    if (dataSource.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}>
            توصيف الخدمة :
          </div>
          <Table
            bordered
            locale={{
              emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
            }}
            pagination={false}
            dataSource={dataSource?.map((item, index) => ({
              ...item,
              key: item.id,
              index
            }))}
            columns={[
              {
                title: 'م',
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span>{record.index + 1}</span>
                    </div>
                  );
                }
              },
              {
                title: 'اسم الباقة',
                dataIndex: 'name',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.name}</span>
                    </div>
                  );
                }
              },
              {
                title: 'وصف الباقة',
                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.description}</span>
                    </div>
                  );
                }
              },
              {
                title: 'عدد الشهور',
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      {/* <span>{record.price}</span> */}
                      <span>{record.monthNumber ? record.monthNumber : '0'}</span>
                    </div>
                  );
                }
              },
              {
                title: 'السعر',
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {record.id !== 1 ? parseInt(record.price) * record.monthNumber : '0:00'}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: 'الضريبة',
                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.tax || 0} %</span>
                    </div>
                  );
                }
              },
              {
                title: 'سعر الضريبة',
                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.total_tax?.toFixed(2) || 0}</span>
                    </div>
                  );
                }
              },
              {
                title: 'الخصــم',
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{renderDiscountValue(record)}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'سعر الخصــم',
              //   dataIndex: 'discount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.discount_cost ? record.discount_cost : '0.00'}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: 'الاجمالى',
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {/* {record.id !== 1 ? parseInt(record.total) + record.total_tax : '0.00'} */}
                        {record.total}
                      </span>
                    </div>
                  );
                }
              }
            ]}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderExtraPrices = () => {
    const addPrices = selectedRecipt?.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}>
            أســعار اضافية :
          </div>
          <Table
            className="invoice-extra-prices-table"
            bordered
            showHeader={false}
            locale={{
              emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
            }}
            pagination={false}
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              {
                title: 'الوصــف',
                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: 'الســعر',
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              }
            ]}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    const { total, discount_cost } = selectedRecipt;
    const { discountType, discountValue, total_tax, additional_price, total_price_before_tax } =
      meta_data ? meta_data : {};
    return (
      <div className="supplier-details-wrap">
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}>
          إجمالى المبالغ :
        </div>
        <Descriptions title="" bordered column={1}>
          {total && (
            <Descriptions.Item label="سعر الباقة الكلى بعد الخصم">{total}</Descriptions.Item>
          )}
          {discountValue && discountType && (
            <Descriptions.Item label="الخصم">
              {renderDiscountValue({
                discountType,
                discountValue
              })}
            </Descriptions.Item>
          )}
          {discount_cost && (
            <Descriptions.Item label="سعر الخصم">{discount_cost?.toFixed(2)}</Descriptions.Item>
          )}
          <Descriptions.Item label="أســعار اضافية">
            {additional_price ? additional_price : '0.00'}
          </Descriptions.Item>
          <Descriptions.Item label="السعر الاجمالى قبل اضافة الضريبة">
            {total_price_before_tax
              ? total_price_before_tax?.toFixed(2)
              : (parseInt(total) - total_tax || 0)?.toFixed(2)}
          </Descriptions.Item>

          {total_tax && (
            <Descriptions.Item label="سعر الضريبة">{total_tax?.toFixed(2)}</Descriptions.Item>
          )}
          {total && (
            <Descriptions.Item
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
              label="السعر الاجمالى للفاتورة">
              {total}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderInvoiceHeading = () => {
    if (props?.isIncomingInvoice) {
      return 'فاتورة ضريبية';
    } else {
      if (selectedRecipt?.userOrSupplier == 1 || selectedRecipt?.user) {
        return 'فاتورة ضريبية مبسطة';
      } else if (selectedRecipt?.userOrSupplier == 2 || selectedRecipt?.supplier) {
        return 'فاتورة بيع ضريبية';
      }
      return 'فاتورة بيع ضريبية';
    }
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '28px 28px',
        direction: 'rtl',
        display: 'grid',
        gap: '16px'
      }}>
      <div
        className="inovice-nums-qrcode"
        style={{
          display: 'grid',
          gap: '22px',
          alignItems: 'center',
          direction: 'rtl'
        }}>
        <div
          className="qr-code-logo-wrap"
          style={{
            display: 'grid',
            gap: '22px',
            alignItems: 'center',
            direction: 'rtl'
          }}>
          <div
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}>
            {selectedRecipt?.is_return == 1 ? <p>فاتورة مرتجعة</p> : null}
            <span>{renderInvoiceHeading()}</span>
          </div>
          <div
            style={{
              visibility: 'hidden'
            }}>
            -
          </div>
        </div>
      </div>

      <div className="supp-buyer-wrap">
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2
          }}>
          <div>{renderInfo(allPaymentLogsInfo, 1)}</div>
          <div
            className="qr-code-wrap"
            style={{
              justifySelf: 'end',
              display: 'grid',
              gridTemplateRows: 'auto 1fr'
            }}>
            <div
              style={{
                marginBottom: 2,
                opacity: 0,
                visibility: 'hidden'
              }}>
              --
            </div>
            <QRCode
              value={meta_data?.qr_string ? meta_data.qr_string : ''}
              width={112}
              height={112}
              renderAs="svg"
            />
          </div>
        </div>

        <div
          className="buyer-table"
          style={{
            display: 'grid',
            gap: 2,
            marginTop: 8
          }}>
          {selectedRecipt && renderSupplier(selectedRecipt, 2)}

          {(selectedRecipt?.invoiceNumber ||
            selectedRecipt?.date ||
            selectedRecipt?.dueDate ||
            selectedRecipt?.time) && (
            <div>
              <div
                style={{
                  marginBottom: 2,
                  opacity: 0,
                  visibility: 'hidden'
                }}>
                --
              </div>
              <Descriptions title="" bordered column={1}>
                {selectedRecipt?.invoiceNumber && (
                  <Descriptions.Item label="رقم الفاتورة">
                    {selectedRecipt?.invoiceNumber}
                  </Descriptions.Item>
                )}
                {selectedRecipt?.date && (
                  <Descriptions.Item label="تاريخ اصدار الفاتورة">
                    {selectedRecipt?.date}
                  </Descriptions.Item>
                )}
                {selectedRecipt?.dueDate && (
                  <Descriptions.Item label="تاريخ التوريد">
                    {selectedRecipt?.dueDate}
                  </Descriptions.Item>
                )}
                {selectedRecipt?.time && (
                  <Descriptions.Item label="وقت إصدار الفاتورة">
                    {selectedRecipt?.time ? selectedRecipt?.time : '---'}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </div>
          )}
        </div>

        {selectedRecipt?.user && renderSupplier(selectedRecipt?.user, selectedRecipt?.invoice_type)}
      </div>
      {meta_data && renderModels()}
      {/* {renderExtraPrices()} */}
      {renderInovicePrices()}
    </div>
  );
});

export default ReceiptDetailsComponent;
