import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';
import AddtechnicalSupportForm from './AddtechnicalSupportForm';
import './TechnicalSupportPage.scss';

import TechnicalSupportTable from './TechnicalSupportTable';
import SelectTicketSupportModal from './SelectTicketSupportModal';
import TechnicalSupportFilter from './TechnicalSupportFilter';
const TechnicalSupportPage = () => {
  const {
    TechnicalSupportModalOpened,
    setTechnicalSupportModalOpened,
    selectedServId,
    setSelectedServId,
    setSelectedTechnicalSupport
  } = useContext(TechnicalSupportContext);

  return (
    <div className="Selles-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setTechnicalSupportModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة</span>
        </Button>

        <TechnicalSupportFilter />
      </div>

      <TechnicalSupportTable />
      <SelectTicketSupportModal />

      {TechnicalSupportModalOpened && (
        <Modal
          className="add-Selles-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedServId !== 0 ? ' تعديل التذكرة ' : 'اضافة تذكرة جديدة'}
          visible={TechnicalSupportModalOpened}
          onOk={() => {
            setTechnicalSupportModalOpened(false);
            setSelectedServId(0);
            setSelectedTechnicalSupport(null);
          }}
          onCancel={() => {
            setTechnicalSupportModalOpened(false);
            setSelectedServId(0);
            setSelectedTechnicalSupport(null);
          }}
          footer={false}>
          <AddtechnicalSupportForm />
        </Modal>
      )}
    </div>
  );
};

export default TechnicalSupportPage;
