import React, { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import ActivityLogTable from './ActivityLogTable';
import { Modal } from 'antd';
import './activityLog.scss';

const ActivityLog = () => {
  const { user } = useContext(UesrContext);

  return (
    <div className="activityLog-page">
      {/* <div className="new-filter-wrap">
        <Button
          onClick={() => setNfcCustomersModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة كود</span>
        </Button>

      </div> */}

      <ActivityLogTable />

      {/* {NfcCustomersModalOpened && (
        <Modal
          className="add-activityLog-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedServId ? 'تعديل اكواد nfc' : 'إضافة اكواد nfc'}
          visible={NfcCustomersModalOpened}
          onOk={() => {
            setNfcCustomersModalOpened(false);
            setSelectedServId('');
            setSelectedNfcCustomers(null);
          }}
          onCancel={() => {
            setNfcCustomersModalOpened(false);
            setSelectedServId('');
            setSelectedNfcCustomers(null);
          }}
          footer={false}>
          <AddNfcCustomerForm />
        </Modal>
      )} */}
    </div>
  );
};

export default ActivityLog;
