import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingPaymentLogsData: false,
  setLoadingPaymentLogsData: (load) => {},
  allPaymentLogsData: [],
  setAllPaymentLogsData: (v) => {},
  allPaymentLogsInfo: [],
  setAllPaymentLogsInfo: (v) => {},
  PaymentLogsInvoiceModalOpened: false,
  setPaymentLogsInvoiceModalOpened: (v) => {},
  PaymentLogsDetailsModalOpened: false,
  setPaymentLogsDetailsModalOpened: (v) => {},
  selectedPaymentLogsId: '',
  setSelectedPaymentLogsId: (id) => {},
  selectedPaymentLogs: null,
  setSelectedPaymentLogs: (servKey) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const PaymentLogsContext = createContext(INITIAL_VALUES);

export const PaymentLogsProvider = ({ children }) => {
  const [allPaymentLogsData, setAllPaymentLogsData] = useState(INITIAL_VALUES.allPaymentLogsData);
  const [loadingPaymentLogsData, setLoadingPaymentLogsData] = useState(
    INITIAL_VALUES.loadingPaymentLogsData
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [PaymentLogsInvoiceModalOpened, setPaymentLogsInvoiceModalOpened] = useState(
    INITIAL_VALUES.PaymentLogsInvoiceModalOpened
  );
  const [PaymentLogsDetailsModalOpened, setPaymentLogsDetailsModalOpened] = useState(
    INITIAL_VALUES.PaymentLogsDetailsModalOpened
  );
  const [selectedPaymentLogsId, setSelectedPaymentLogsId] = useState(
    INITIAL_VALUES.selectedPaymentLogsId
  );
  const [selectedPaymentLogs, setSelectedPaymentLogs] = useState(
    INITIAL_VALUES.selectedPaymentLogs
  );
  const [allPaymentLogsInfo, setAllPaymentLogsInfo] = useState(INITIAL_VALUES.allPaymentLogsInfo);
  const [tablePagination, setTablePagination] = useState(INITIAL_VALUES?.tablePagination);

  return (
    <PaymentLogsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingPaymentLogsData,
        setLoadingPaymentLogsData,
        allPaymentLogsData,
        setAllPaymentLogsData,
        PaymentLogsInvoiceModalOpened,
        setPaymentLogsInvoiceModalOpened,
        selectedPaymentLogsId,
        setSelectedPaymentLogsId,
        selectedPaymentLogs,
        setSelectedPaymentLogs,
        PaymentLogsDetailsModalOpened,
        setPaymentLogsDetailsModalOpened,
        allPaymentLogsInfo,
        setAllPaymentLogsInfo,
        tablePagination,
        setTablePagination
      }}>
      {children}
    </PaymentLogsContext.Provider>
  );
};

export default PaymentLogsContext;
