/* eslint-disable react-hooks/exhaustive-deps */
import { CameraFilled, EditOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
// import MyDropZone from '../../common/dorp-zone/MyDropZone';
// import MyDropZonePreview from '../../common/dorp-zone';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddtechnicalSupportForm.scss';
// import UploadSellesFile from './UploadSellesFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useState } from 'react';
import { store } from 'react-notifications-component';
import GetCustomerSupport from '../../apis/TechnicalSupport/GetCustomerSupport';
import CreateTechnicalSupport from '../../apis/TechnicalSupport/createTechnicalSupport';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import UploadQuestionFile from '../blog-page/UploadQuestionFile';

const defaultValues = {
  email: '',
  password: ''
};

const AddtechnicalSupportForm = () => {
  const { user } = useContext(UesrContext);
  // console.log(user);

  const {
    TechnicalSupportModalOpened,
    setFetchCount,
    setTechnicalSupportModalOpened,
    selectedServId,
    selectedTechnicalSupport,
    allTechnicalSupport,
    loadingTechnicalSupport,
    setLoadingTechnicalSupport,
    setAllTechnicalSupport,
    setSelectedServId,
    setSelectedTechnicalSupport,
    setViewTicketModalOpened
  } = useContext(TechnicalSupportContext);
  let schema = Yup.object().shape({
    subject: Yup.string().required(' من فضلك أدخل الموضوع مطلوب'),
    description: Yup.string().required(' من فضلك أدخل الوصف مطلوب'),
    attach: Yup.mixed().optional('من فضلك أدخل مطلوب'),

    priority: Yup.string().required(' من فضلك أدخل الأولوية '),
    status: Yup.string().required(' من فضلك أدخل الحاله '),
    client_id: Yup.string().required(' من فضلك أدخل العميل  ')
  });
  const [customerSelectData, setCustomerSelectData] = useState([]);
  const [customerSelectDataTarget, setCustomerDataTarget] = useState([]);
  // console.log(selectedTechnicalSupport);
  // console.log(selectedServId);
  useEffect(() => {
    // console.log(selectedServId);
    // console.log(allSelles);
    if (selectedServId) {
      const foundSelles = allTechnicalSupport.find((serv) => serv.id === selectedServId);
      if (foundSelles) setSelectedTechnicalSupport(foundSelles);
    }
  }, [selectedServId]);
  useEffect(() => {
    const getTechnicalSupport = async () => {
      const getCustomerSupport = await GetCustomerSupport(user?.token);
      setCustomerSelectData(getCustomerSupport);
    };
    getTechnicalSupport();
  }, []);
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [urls, setUrls] = useState([]);

  // const [productFileToUpload, setProductFileToUpload] = useState(
  //   watch('attach') ? watch('attach') : null
  // );
  // useEffect(() => {
  //   setValue('attach', productFileToUpload);
  // }, [productFileToUpload, productFileToUpload?.length]);
  // function getBase64(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // }
  // const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
  //   const duplicates = [];
  //   for (let i of acceptedFiles) {
  //     if (formFiles?.length) {
  //       for (let f of formFiles) {
  //         if (i.name === f.name) {
  //           duplicates.push(i);
  //         }
  //       }
  //     }
  //   }
  //   for (let i = 0; i < acceptedFiles.length; i++) {
  //     for (let f of duplicates) {
  //       if (acceptedFiles[i].name === f.name) {
  //         acceptedFiles.splice(i, 1);
  //       }
  //     }
  //   }

  //   acceptedFiles.forEach(async (file) => {
  //     const preview = await getBase64(file);
  //     Object.assign(file, {
  //       preview
  //     });
  //     // setFormFiles((prevState) => [...prevState, file]);
  //     if (formFiles?.length > 0) {
  //       setFormFiles((currState) => [...currState, file]);
  //     } else {
  //       setFormFiles([file]);
  //     }
  //   });
  // };

  // const clearFileFromUpload = (file, setFilesToUpload) => {
  //   setFilesToUpload((prevState) => {
  //     const filtered = prevState.filter((asset) => asset.name !== file.name);
  //     if (filtered?.length === 0) return null;
  //     return filtered;
  //   });
  // };

  const onSubmit = async (data) => {
    try {
      let res;
      if (selectedTechnicalSupport) {
        res = await CreateTechnicalSupport(
          `/tickets`,
          data,
          user?.token,
          selectedTechnicalSupport.id,
          3
        );
      } else {
        res = await CreateTechnicalSupport('/tickets', data, user?.token, 0, 3);
      }
      // console.log('create Selles response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطء اثناء الأضافه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });
        setTechnicalSupportModalOpened(false);
        setSelectedServId(0);
        setSelectedTechnicalSupport(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم الأضافه ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  // console.log(selectedTechnicalSupport);
  useEffect(() => {
    if (selectedTechnicalSupport && selectedServId && customerSelectData?.length) {
      // console.log("ssssssss",dataTarget)
      setCustomerDataTarget(
        customerSelectData?.filter((el) => el.ref_id == selectedTechnicalSupport?.client.id)
      );
    }
  }, [selectedTechnicalSupport, selectedServId, customerSelectData?.length, setCustomerDataTarget]);
  // handle initial values
  useEffect(() => {
    if (selectedTechnicalSupport && selectedServId) {
      // let dataTarget = [];
      // dataTarget =
      //   customerSelectData.length &&
      //   customerSelectData.filter((el) => el.ref_id == selectedTechnicalSupport.client.id);
      // // console.log("ssssssss",dataTarget)
      console.log(selectedTechnicalSupport?.client?.id);
      setValue('priority', selectedTechnicalSupport?.priority);
      setValue('client_id', selectedTechnicalSupport?.client?.id);
      setValue('status', selectedTechnicalSupport?.status);
      setValue('subject', selectedTechnicalSupport?.subject);
      setValue('description', selectedTechnicalSupport?.description);
      // setValue('attach', selectedTechnicalSupport?.attach);
    }
  }, [selectedTechnicalSupport, selectedServId]);

  return (
    <Form
      className="add-Selles-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <div className="form-field-wrapper">
          <AntdTextField
            name="subject"
            type="text"
            placeholder="موضوع..."
            errorMsg={errors?.subject?.message}
            validateStatus={errors?.subject ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextarea
            name="description"
            type="text"
            placeholder="الوصف..."
            errorMsg={errors?.description?.message}
            validateStatus={errors?.description ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {!selectedTechnicalSupport?.client?.id && (
          <AntdSelectOption
            name="client_id"
            errorMsg={errors?.client_id?.message}
            validateStatus={errors?.client_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            disabled={selectedTechnicalSupport?.client?.id && true}
            options={
              customerSelectData?.length &&
              customerSelectData?.map((el) => ({
                title: el?.name,
                value: el?.ref_id
              }))
            }
            label={
              <p className="select-option-label">
                <EditOutlined />
                العميل
              </p>
            }
            formClassName="add-Selles-form"
          />
        )}

        <AntdSelectOption
          name="priority"
          errorMsg={errors?.priority?.message}
          validateStatus={errors?.priority ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'low', value: 'low' },
            { title: 'medium', value: 'medium' },
            { title: 'high', value: 'high' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              أولوية
            </p>
          }
          formClassName="add-Selles-form"
        />

        <AntdSelectOption
          name="status"
          errorMsg={errors?.status?.message}
          validateStatus={errors?.status ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'pending', value: 'pending' },
            { title: 'open', value: 'open' },
            { title: 'close', value: 'close' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              الحاله
            </p>
          }
          formClassName="add-Selles-form"
        />
        <div className="form-field-wrapper">
          <UploadQuestionFile
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            maxFiles={1}
            name="attach"
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            multiple={false}
            label={
              <>
                <EditOutlined />
                صورة التذكرة
              </>
            }
            urls={urls}
            errorMsg={errors?.service_imgs ? errors?.service_imgs?.message : ''}
          />
        </div>

        {/* <div className="form-field-wrapper">
          {!selectedServId && (
            <AntdTextField
              name="attach"
              type="file"
              placeholder="موضوع..."
              errorMsg={errors?.attach?.message}
              validateStatus={errors?.attach ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          )}
        </div> */}
        {/* <div className="product-img-wrap">
          <h3>صورة الخدمة</h3>
          <MyDropZone
            className="product-header-dropzone"
            multipleFiles={false}
            handleFilesDrop={(acceptedFiles) =>
              handleFilesDrop(acceptedFiles, productFileToUpload, setProductFileToUpload)
            }
            filesToUpload={productFileToUpload}
            formName="store-new-product-form"
            id="avatar-input"
            dropzoneText="صورة الخدمة"
            inputName="product_image"
            icon={<CameraFilled className="dropzone-camera" />}
            dropZoneUrls={selectedProduct?.image ? [selectedProduct.image] : null}>
            اسحب الصورة وضعها هنا
          </MyDropZone>
          <MyDropZonePreview
            filesToUpload={productFileToUpload}
            clearFileFromUpload={(f) => clearFileFromUpload(f, setProductFileToUpload)}
          />
          {errors?.product_image?.message && !watch('product_image') && (
            <p className="error-p">{errors.product_image.message}</p>
          )}
        </div> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          {selectedServId !== 0 ? 'تعديل ' : 'اضافه'}
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddtechnicalSupportForm;
