import React from 'react';

const PaymentLogsDetailsModal = ({ selectedPaymentLogs }) => {
  const handlePaymentStatus = (status) => {
    if (status === 1) return 'تم الدفع';
    else return 'لم يتم الدفع';
  };

  return (
    <div className="PaymentLogsDetails">
      <table className="payment_details_table">
        <tbody>
          <tr>
            <td>اسم المستخدم</td>
            <td>{selectedPaymentLogs.name}</td>
          </tr>
          {selectedPaymentLogs.email && (
            <tr>
              <td>البريد الالكتروني</td>
              <td>{selectedPaymentLogs.email}</td>
            </tr>
          )}
          <tr>
            <td>رقـم الهاتف</td>
            <td>{selectedPaymentLogs.phone}</td>
          </tr>
          {/* {selectedPaymentLogs.tax_number && (
            <tr>
              <td>الرقم الضريبي</td>
              <td>{selectedPaymentLogs.tax_number}</td>
            </tr>
          )} */}
          <tr>
            <td>نـوع الخدمة</td>
            <td>
              {selectedPaymentLogs.subscriptionType === 1
                ? 'تجارة الجملة والتجزئه'
                : 'قطاع الخدمات'}
            </td>
          </tr>
          {selectedPaymentLogs?.package && (
            <>
              <tr>
                <td>نـوع الباقة</td>
                {/* <td>{packageCheck(selectedPaymentLogs.package_id)}</td> */}
                <td>{selectedPaymentLogs?.package?.name}</td>
              </tr>
              {selectedPaymentLogs?.package.id !== 1 && (
                <tr>
                  <td>عدد الشهور</td>
                  <td>{selectedPaymentLogs?.package?.monthNumber}</td>
                </tr>
              )}
            </>
          )}
          <tr>
            <td>تاريخ بدأ الباقة</td>
            <td>{selectedPaymentLogs.start_date ? selectedPaymentLogs.start_date : '---'}</td>
          </tr>
          <tr>
            <td>تاريخ انتهاء الباقة</td>
            <td>{selectedPaymentLogs.end_date ? selectedPaymentLogs.end_date : '---'}</td>
          </tr>
          <tr>
            <td>تكلفة الباقة</td>
            {/* <td>{packageCheck(selectedPaymentLogs.package_id)}</td> */}
            <td>
              {selectedPaymentLogs?.package.id !== 1
                ? selectedPaymentLogs?.package?.price * selectedPaymentLogs?.package?.monthNumber
                : selectedPaymentLogs?.package?.price}
            </td>
          </tr>
          {selectedPaymentLogs?.package_id !== 1 && (
            <>
              <tr>
                <td>حاله الدفع</td>
                <td>{handlePaymentStatus(selectedPaymentLogs?.payment_status)}</td>
              </tr>
              {selectedPaymentLogs?.payment_date && (
                <tr>
                  <td>تاريخ الدفع</td>
                  <td>{selectedPaymentLogs?.payment_date}</td>
                </tr>
              )}
              <tr>
                <td>تكلفة الخصم</td>
                <td>
                  {selectedPaymentLogs?.discount_cost
                    ? selectedPaymentLogs?.discount_cost.toFixed(2)
                    : '0'}
                </td>
              </tr>
              <tr>
                <td>سعر الضريبة</td>
                <td>
                  {selectedPaymentLogs?.meta_data?.total_tax
                    ? selectedPaymentLogs?.meta_data?.total_tax.toFixed(2)
                    : '0'}
                </td>
              </tr>
              {selectedPaymentLogs?.meta_data?.total_price_before_tax > 0 && (
                <tr>
                  <td>المبلغ الكلي قبل اضافة الضريبة</td>
                  <td>{`${selectedPaymentLogs?.meta_data?.total_price_before_tax.toFixed(
                    2
                  )} ريال`}</td>
                </tr>
              )}
              <tr>
                <td>المبلغ الكلي المدفوع</td>
                <td>{`${selectedPaymentLogs?.total} ريال`}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentLogsDetailsModal;
