import axios from 'axios';

const deleteNfcCode = async (AdminId, token) => {
  try {
    // const formData = new FormData();

    // if (AdminId) {
    //   formData.append('id', AdminId);
    // }

    const response = await axios.post(`/customernfc/delete?id=${AdminId}`, '', {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    
    // if (response.status === 200 && response.data.status === 1) {
    return response;
    // }
  } catch (e) {
    console.log(e);
  }
};

export default deleteNfcCode;
