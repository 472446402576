const routerLinks = {
  ////////////////////////////
  loginPage: '/',
  homePage: '/',
  categoryPage: '/categories',
  servicesPage: '/services',
  paymentsPage: '/payments',

  PackagesPage: '/packages',
  PosPackagesPage: '/pos-packages',
  PackageDetailes: '/packageDetailes',

  projectsPage: '/projects',
  projectPage: '/projects/:projectId',
  questionsPage: '/questions',
  answersPage: '/answers',
  ProfilePage: '/Profile',
  infoPage: '/info',
  aboutUsPage: '/Fatoorah-features',
  DaftarPage: '/Daftar-us',
  ContactUsPage: '/ContactUs',
  newsLetterPage: '/news-letter',
  supportPage: '/support',
  TechnicalSupport: '/technicalSupport',
  chatSupport: '/chat-support/:id',
  SollemPage: '/Sollem',
  fatorahRequestPage: '/fatorah-request',
  fatorahRequestUserDetailPage: '/fatorah-request/user/detail/:id_user',
  fatorahOtpUsers: '/otp',
  blogPage: '/blog',
  notificationCenterPage: '/notification-center',
  subscriptionStatspage: '/subscription-stats',
  merchantsStatsPage: '/merchants-stats',
  PaymentLog: '/Payment-log',
  AdminPage: '/Admin',
  SystemErrors: '/SystemErrors',
  NfcCustomers: '/nfc-customers',
  ActivityLog: '/Activity-Log',
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
