import React, { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button, Form } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { store } from 'react-notifications-component';
import updateInfo from '../../apis/Info-apis/updateInfo';
import getInfo from '../../apis/Info-apis/getInfo';

import { ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
// import { GoogleMap, Marker, withScriptjs, withGoogleMap } from 'react-google-maps';

const schema = yup.object().shape({
  address: yup.string().required('العنوان مطلوب'),
  phone: yup
    .string()
    .required('من فضلك ادخل رقم الهاتف')
    .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
  email: yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
  facebook: yup.string().required('الفيسبوك مطلوب'),
  instagram: yup.string().required('الانستجرام مطلوب'),
  linkedin: yup.string().required('لينكدان مطلوب'),
  twitter: yup.string().required('تويتر مطلوب'),
  pos_price: yup
    .string()
    .required('سعر نقطه البيع مطلوب')
    .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
  pos_price_year: yup
    .string()
    .required('سعر نقطه البيع مطلوب')
    .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام')
});

const defaultValues = {
  address: '',
  phone: '',
  email: '',
  facebook: '',
  instagram: '',
  linkedin: '',
  twitter: '',
  pos_price: '',
  pos_price_year: '',
  policy_ar: '',
  policy_en: '',
  condition_ar: '',
  condition_en: '',
  return_ar: '',
  return_en: ''
};

const InfoForm = () => {
  const [policy_arEditorState, setpolicy_arEditorState] = useState(EditorState.createEmpty());
  const [policy_enEditorState, setpolicy_enEditorState] = useState(EditorState.createEmpty());

  const [condition_arEditorState, setcondition_arEditorState] = useState(EditorState.createEmpty());
  const [condition_enEditorState, setcondition_enEditorState] = useState(EditorState.createEmpty());

  const [return_arEditorState, setreturn_arEditorState] = useState(EditorState.createEmpty());
  const [return_enEditorState, setreturn_enEditorState] = useState(EditorState.createEmpty());

  const { user } = useContext(UesrContext);
  
  const {
    control,
    setValue,
    handleSubmit,
    getValues,

    setError,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();
  const onSubmit = async (data) => {
    try {
      if (!getValues()?.policy_ar || getValues()?.policy_ar === '<p><br></p>') {
        setError('policy_ar', {
          type: 'required',
          message: 'من فضلك ادخل سياسية الخصوصيه باللغة العربية'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('policy_ar');
      }

      if (!getValues()?.policy_en || getValues()?.policy_en === '<p><br></p>') {
        setError('policy_en', {
          type: 'required',
          message: 'من فضلك ادخل سياسية الخصوصيه باللغة الانجليزيه'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('policy_en');
      }

      if (!getValues()?.condition_ar || getValues()?.condition_ar === '<p><br></p>') {
        setError('condition_ar', {
          type: 'required',
          message: 'من فضلك ادخل الشروط والاحكام باللغة العربية'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('condition_ar');
      }

      if (!getValues()?.condition_en || getValues()?.condition_en === '<p><br></p>') {
        setError('condition_en', {
          type: 'required',
          message: 'من فضلك ادخل الشروط والأحكام باللغة الانجليزيه'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('condition_en');
      }

      if (!getValues()?.return_ar || getValues()?.return_ar === '<p><br></p>') {
        setError('return_ar', {
          type: 'required',
          message: 'من فضلك ادخل سياسة الخصوصية والاسترجاع باللغة العربية'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('return_ar');
      }

      if (!getValues()?.return_en || getValues()?.return_en === '<p><br></p>') {
        setError('return_en', {
          type: 'required',
          message: 'من فضلك ادخل سياسة الخصوصية والاسترجاع الانجليزيه'
        });
      } else {
        // setError('desc_ar', )
        clearErrors('return_en');
      }

      const fetchedData = await updateInfo(
        data,
        user?.token,
        aboutDataSource.lat,
        aboutDataSource.lng
      );
      
      if (fetchedData?.status === 200 && fetchedData?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        // setCurrentUser({ ...user, ...fetchedData.data, token: user?.token });
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل البيانات بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      
    }
  };

  useEffect(() => {
    setValue('policy_ar', stateToHTML(policy_arEditorState.getCurrentContent()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToHTML(policy_arEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('policy_en', stateToHTML(policy_enEditorState.getCurrentContent()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToHTML(policy_enEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('condition_ar', stateToHTML(condition_arEditorState.getCurrentContent()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToHTML(condition_arEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('condition_en', stateToHTML(condition_enEditorState.getCurrentContent()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToHTML(condition_enEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('return_ar', stateToHTML(return_arEditorState.getCurrentContent()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToHTML(return_arEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('return_en', stateToHTML(return_enEditorState.getCurrentContent()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateToHTML(return_enEditorState.getCurrentContent())]);

  const [aboutDataSource, setAboutDataSource] = useState(null);

  useEffect(() => {
    
    const getServices = async () => {
      try {
        const getServicesRes = await getInfo(user?.token);
        // console.log(getServicesRes);
        if (getServicesRes.status === 200 && getServicesRes?.data?.data) {
          setAboutDataSource(getServicesRes.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (aboutDataSource) {
      // console.log(aboutDataSource);
      const {
        address,
        phone,
        email,
        facebook,
        twitter,
        instagram,
        linkedin,
        condition_ar,
        condition_en,
        return_ar,
        return_en,
        policy_ar,
        policy_en,
        pos_price,
        pos_price_year
      } = aboutDataSource;

      setValue('address', address);
      setValue('phone', phone);
      setValue('email', email);
      setValue('facebook', facebook);
      setValue('twitter', twitter);
      setValue('instagram', instagram);
      setValue('linkedin', linkedin);
      // setValue('condition_ar', condition_ar);
      setValue('pos_price_year', pos_price_year);
      setValue('pos_price', pos_price);

      const contentBlockDescAr = htmlToDraft(policy_ar);
      if (contentBlockDescAr) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescAr.contentBlocks);
        const editorStateDescAr = EditorState.createWithContent(contentState);
        setpolicy_arEditorState(editorStateDescAr);
      }
      const contentBlockDescEn = htmlToDraft(policy_en);
      if (contentBlockDescEn) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescEn.contentBlocks);
        const editorStateDescEn = EditorState.createWithContent(contentState);
        setpolicy_enEditorState(editorStateDescEn);
      }
      const contentBlockCondationAr = htmlToDraft(condition_ar);
      if (contentBlockCondationAr) {
        const contentState = ContentState.createFromBlockArray(
          contentBlockCondationAr.contentBlocks
        );
        const editorStateCondationAr = EditorState.createWithContent(contentState);
        setcondition_arEditorState(editorStateCondationAr);
      }
      const contentBlockCondationEn = htmlToDraft(condition_en);
      if (contentBlockCondationEn) {
        const contentState = ContentState.createFromBlockArray(
          contentBlockCondationEn.contentBlocks
        );
        const editorStateCondationEn = EditorState.createWithContent(contentState);
        setcondition_enEditorState(editorStateCondationEn);
      }

      const contentBlockreturn_ar = htmlToDraft(return_ar);
      if (contentBlockreturn_ar) {
        const contentState = ContentState.createFromBlockArray(contentBlockreturn_ar.contentBlocks);
        const editorStatereturn_ar = EditorState.createWithContent(contentState);
        setreturn_arEditorState(editorStatereturn_ar);
      }
      const contentBlockreturn_en = htmlToDraft(return_en);
      if (contentBlockreturn_en) {
        const contentState = ContentState.createFromBlockArray(contentBlockreturn_en.contentBlocks);
        const editorStatereturn_en = EditorState.createWithContent(contentState);
        setreturn_enEditorState(editorStatereturn_en);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aboutDataSource]);

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className="add-project-form">
        {/* <WrappedMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_API_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        /> */}
        <div className="form-field-wrapper">
          <AntdTextField
            name="address"
            type="text"
            placeholder="العنوان بالعربيه..."
            errorMsg={errors?.address?.message}
            validateStatus={errors?.address ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="facebook"
            type="text"
            placeholder="فيسبوك..."
            errorMsg={errors?.facebook?.message}
            validateStatus={errors?.facebook ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="instagram"
            type="text"
            placeholder="انستجرام..."
            errorMsg={errors?.instagram?.message}
            validateStatus={errors?.instagram ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="linkedin"
            type="text"
            placeholder="لينكدان..."
            errorMsg={errors?.linkedin?.message}
            validateStatus={errors?.linkedin ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="twitter"
            type="text"
            placeholder="تويتر..."
            errorMsg={errors?.twitter?.message}
            validateStatus={errors?.twitter ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* pos_price_year */}
        <div className="form-field-wrapper">
          <AntdTextField
            name="pos_price"
            type="text"
            placeholder="سعر نقطه البيع للباقه الشهريه..."
            errorMsg={errors?.pos_price?.message}
            validateStatus={errors?.pos_price ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="pos_price_year"
            type="text"
            placeholder="سعر نقطه البيع للباقه السنوية..."
            errorMsg={errors?.pos_price_year?.message}
            validateStatus={errors?.pos_price_year ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            سياسة الخصوصية بالعربي.
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={policy_arEditorState}
            onEditorStateChange={(v) => {
              setpolicy_arEditorState(v);
              if (!getValues()?.policy_ar || getValues()?.policy_ar === '<p><br></p>') {
                setError('policy_ar', {
                  type: 'required',
                  message: 'من فضلك ادخل سياسة الخصوصية بالعربي.'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('policy_ar');
              }
            }}
          />
          <p className="error-p">{errors?.policy_ar ? errors?.policy_ar.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            سياسة الخصوصية بالعربي.
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={policy_enEditorState}
            onEditorStateChange={(v) => {
              setpolicy_enEditorState(v);
              if (!getValues()?.policy_en || getValues()?.policy_en === '<p><br></p>') {
                setError('policy_en', {
                  type: 'required',
                  message: 'من فضلك ادخل سياسة الخصوصية بالعربي.'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('policy_en');
              }
            }}
          />
          <p className="error-p">{errors?.policy_en ? errors?.policy_en.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            الشروط والأحكام بالعربي.
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={condition_arEditorState}
            onEditorStateChange={(v) => {
              setcondition_arEditorState(v);
              if (!getValues()?.condition_ar || getValues()?.condition_ar === '<p><br></p>') {
                setError('condition_ar', {
                  type: 'required',
                  message: 'من فضلك ادخل سياسة الخصوصية بالعربي.'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('condition_ar');
              }
            }}
          />
          <p className="error-p">{errors?.condition_ar ? errors?.condition_ar.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            الشروط والأحكام بالانجليزي.
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={condition_enEditorState}
            onEditorStateChange={(v) => {
              setcondition_enEditorState(v);
              if (!getValues()?.condition_en || getValues()?.condition_en === '<p><br></p>') {
                setError('condition_en', {
                  type: 'required',
                  message: 'من فضلك ادخل سياسة الخصوصية بالعربي.'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('condition_en');
              }
            }}
          />
          <p className="error-p">{errors?.condition_en ? errors?.condition_en.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            سياسية الخصوصية والاسترجاع بالعربي.
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={return_arEditorState}
            onEditorStateChange={(v) => {
              setreturn_arEditorState(v);
              if (!getValues()?.return_ar || getValues()?.return_ar === '<p><br></p>') {
                setError('return_ar', {
                  type: 'required',
                  message: 'من فضلك ادخل  سياسية الخصوصية والاسترجاع  بالعربي.'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('return_ar');
              }
            }}
          />
          <p className="error-p">{errors?.return_ar ? errors?.return_ar.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            سياسية الخصوصية والاسترجاع بالانجليزي.
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={return_enEditorState}
            onEditorStateChange={(v) => {
              setreturn_enEditorState(v);
              if (!getValues()?.return_en || getValues()?.return_en === '<p><br></p>') {
                setError('return_en', {
                  type: 'required',
                  message: 'من فضلك ادخل  سياسية الخصوصية والاسترجاع بالعربي.'
                });
              } else {
                // setError('desc_ar', )
                clearErrors('return_en');
              }
            }}
          />
          <p className="error-p">{errors?.return_en ? errors?.return_en.message : ''}</p>
        </div>

        <div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={isSubmitting}>
            تعديل
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default InfoForm;
