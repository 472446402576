/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
// import getAllSelles from '../../apis/TechnicalSupport/getAllSelles';
import GetAllTechnicalSupport from '../../apis/TechnicalSupport/getAllTechnicalSupport';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import TechnicalSupportTableColumns from './technicalSupportTableColumns.js';
const TechnicalSupportTable = () => {
  const { user } = useContext(UesrContext);
  // console.log(user?.id);
  const {
    TechnicalSupportModalOpened,
    setFetchCount,
    fetchCount,
    loadingTechnicalSupport,
    setTechnicalSupportModalOpened,
    setLoadingTechnicalSupport,
    selectedServId,
    setAllTechnicalSupport,
    selectedTechnicalSupport,
    allTechnicalSupport,
    setSelectedServId,
    setSelectedTechnicalSupport,
    setViewTicketModalOpened,
    filterValues
  } = useContext(TechnicalSupportContext);
  const [TechnicalSupportDataSource, setTechnicalSupportDataSource] = useState([]);
  // console.log(TechnicalSupportDataSource);

  useEffect(() => {
    const getTechnicalSupport = async () => {
      try {
        setLoadingTechnicalSupport(true);
        const getTechnicalSupportRes = await GetAllTechnicalSupport(user?.token, filterValues);
        // if (getTechnicalSupportRes.status === 200 && getTechnicalSupportRes?.data) {
        setAllTechnicalSupport(getTechnicalSupportRes?.data);
        setLoadingTechnicalSupport(false);
        // }
      } catch (error) {
        setLoadingTechnicalSupport(false);
        // console.log(error);
      }
    };
    getTechnicalSupport();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allTechnicalSupport) {
      const mappedTechnicalSupport = allTechnicalSupport.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setTechnicalSupportDataSource(mappedTechnicalSupport);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [allTechnicalSupport, allTechnicalSupport?.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="TechnicalSupport-table"
      dataSource={TechnicalSupportDataSource}
      columns={TechnicalSupportTableColumns(
        user?.token,
        setFetchCount,
        setLoadingTechnicalSupport,
        setTechnicalSupportModalOpened,
        setSelectedServId,
        setViewTicketModalOpened,
        setSelectedTechnicalSupport
      )}
      loading={loadingTechnicalSupport}
      scroll={{ x: 1200 }}
    />
  );
};

export default TechnicalSupportTable;
