import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

import { store } from 'react-notifications-component';
import deleteNotificationApi from '../../apis/Notification/deleteNotification';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import { useContext } from 'react';

const NotificationColumns = (
  token,
  setFetchCount,
  setLoadingNotificationCenterData,
  setNotificationCenterModalOpened,
  setSelectedNotificationCenter,
  setNotificationCenterDetailsModalOpened
) => {
  const {
    allcustomer,
  } = useContext(CustomerContext);
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingNotificationCenterData(true);
      const res = await deleteNotificationApi(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingNotificationCenterData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الاشعار بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingNotificationCenterData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingNotificationCenterData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleViewDetails = (record) => {
    setSelectedNotificationCenter(record)
    setNotificationCenterDetailsModalOpened(true)
  }

  const customerName = (record) => {
    if (record.customer_id == 0) {
      return 'الكل'
    }
    else if (allcustomer.length > 0 && record) {
      let found = allcustomer.find((item) => {
        return item.id === record.customer_id
      })

      return found.name
    }
  }



  return [
    {
      title: '#',
      dataIndex: 'key',
      width: '10%'
    },
    {
      title: 'نوع الاشعارات',
      dataIndex: 'type',
      width: 62,
      render: (_, record) => {
        if (record.type.is_push) {
          if (record.type.is_email) {
            if (record.type.is_sms) {
              return (
                <span>الكل </span>
              )
            }
            return (
              <span>اشعارات و بريد </span>
            )
          }
          else if (record.type.is_sms) {
            return (
              <span>اشعارات ورسالة نصية </span>
            )
          }
          return (
            <span>اشعارات </span>
          )
        }
        else {
          if (record.type.is_email) {
            if (record.type.is_sms) {
              return (
                <span>بريد ورسالة نصية </span>
              )
            }
            return (
              <span>  بريد </span>
            )
          }
          else if (record.type.is_sms) {
            return (
              <span> رسالة نصية </span>
            )
          }
        }

      },
    },
    {
      title: 'اسم العميل',
      dataIndex: 'customer_name',
      width: '10%',

    },
    {
      title: 'تفاصيل الاشعارات',
      dataIndex: 'view',
      width: 92,
      key: 'view',
      render: (_, record) => {
        return (
          <Tooltip title='تفاصيل الاشعارات'>
            <Button
              className="edit-btn"
              onClick={(key) => handleViewDetails(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EyeOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف الاشعار',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الاشعارات</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف الاشعارات؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default NotificationColumns;
