import axios from 'axios';
const editCustomerEndDate = async (url, values, token, id, type) => {
  console.log(values);

  try {
    const formData = new FormData();
    if (id !== 0) {
      formData.append('ref_key', id);
    }
    for (let key in values) {
      if (key === 'ref_key') {
        formData.append('package_id', values[key]);
      }else{
        
        formData.append(key, values[key]);
 
      }
    }
    const createQuestionsRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createQuestionsRes;
  } catch (error) {
    console.log(error);
  }
};

export default editCustomerEndDate;
