/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
// import getCategories from '../../apis/category-apis/getCategories';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import createQuestion from '../../apis/questions/createQuestion';

import './AddQuestionForm.scss';
import UploadQuestionFile from './UploadQuestionFile';

const defaultValues = {
  title: '',
  body: '',
  title_en: '',
  body_en: '',
  service_imgs: null
  // chooses: [{ name: '' }]
  // chooses: [{ name: '' }]
};
const AddQuestionForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setQuestionModalOpened,
    allQuestions,
    setSelectedQuestionId,
    selectedQuestionId,
    selectedQuestion,
    setSelectedQuestion
  } = useContext(QuestionsContext);

  let schema;
  if (selectedQuestion && selectedQuestionId) {
    schema = Yup.object().shape({
      title: Yup.string().required('من فضلك ادخل اسم الخاصيه'),
      title_en: Yup.string().required('من فضلك ادخل اسم الخاصيه بالانجليزي'),
      body: Yup.string().required('من فضلك ادخل وصف الخاصيه'),
      body_en: Yup.string().required('من فضلك ادخل وصف الخاصيه بالانجليزي')
    });
  } else {
    schema = Yup.object().shape({
      title: Yup.string().required('من فضلك ادخل اسم الخاصيه'),
      title_en: Yup.string().required('من فضلك ادخل اسم الخاصيه بالانجليزي'),
      body: Yup.string().required('من فضلك ادخل وصف الخاصيه'),
      body_en: Yup.string().required('من فضلك ادخل وصف الخاصيه بالانجليزي'),
      service_imgs: Yup.mixed().required('من فضلك اختار صورة الشركه')
    });
  }
  useEffect(() => {
    if (selectedQuestionId) {
      const foundQuestion = allQuestions.find((ques) => ques.id === selectedQuestionId);
      if (foundQuestion) setSelectedQuestion(foundQuestion);
    }
  }, [selectedQuestionId]);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    register,
    watch,
    unregister,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedQuestion && selectedQuestionId) {
      const { title, body, title_en, body_en } = selectedQuestion;
      setValue('title', title);
      setValue('body', body);
      setValue('body_en', body_en);
      // setValue('is_required', String(is_required));
      setValue('title_en', title_en);
    }
  }, [selectedQuestion, selectedQuestionId]);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (selectedQuestion?.icon) {
      // console.log('selected service: ', selectedQuestion);
      setUrls([selectedQuestion.icon]);
    }
  }, [selectedQuestion]);

  const onSubmit = async (data) => {
    try {
      let res;
      if (selectedQuestion) {
        res = await createQuestion(`/feature`, data, user?.token, selectedQuestion.id, 1);
      } else {
        res = await createQuestion('/feature', data, user?.token, 0, 1);
      }

      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء الخاصيه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.response_code === 200) {
        reset({ ...defaultValues });
        setQuestionModalOpened(false);
        setSelectedQuestionId('');
        setSelectedQuestion(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء الخاصيه المطلوب',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      // initialValues={selectedQuestion && selectedQuestionId ? { ...watch() } : defaultValues}
      // initialValues={{
      //   cat_id: '2'
      // }}
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <UploadQuestionFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="service_imgs"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الخاصيه
            </>
          }
          urls={urls}
          errorMsg={errors?.service_imgs ? errors?.service_imgs?.message : ''}
        />

        <AntdTextField
          name="title"
          type="text"
          label="اكتب اسم الخاصيه"
          placeholder="اسم الخاصيه..."
          errorMsg={errors?.title?.message}
          validateStatus={errors?.title ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        <AntdTextField
          name="title_en"
          type="text"
          label="اكتب اسم الخاصيه بالانجليزي"
          placeholder="اسم الخاصيه..."
          errorMsg={errors?.title_en?.message}
          validateStatus={errors?.title_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        <AntdTextField
          name="body"
          type="text"
          label="اكتب وصف الخاصيه"
          placeholder="وصف الخاصيه..."
          errorMsg={errors?.body?.message}
          validateStatus={errors?.body ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        <AntdTextField
          name="body_en"
          type="text"
          label="اكتب وصف الخاصيه بالانجليزي"
          placeholder="وصف الخاصيه..."
          errorMsg={errors?.body_en?.message}
          validateStatus={errors?.body_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          حفظ الخاصيه
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(watch(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddQuestionForm;
