import axios from 'axios';
const instance = axios.create({ baseURL: 'https://websiteapi.fatoorah.sa/api' });
// const instance = axios.create({ baseURL: 'https://testwebsiteapi.fatoorah.sa/api' });

export const deleteFatoorh = async (id, phone, type, token) => {
  try {
    // const instance = axios.create({
    //   // .. where we make our configurations
    //   baseURL: 'https://api.example.com'
    // });
    const response = await instance.get(`/deleteData?phone=${phone}&key=${id}&type=${type}`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export const AddPos = async (id, phone, token) => {
  try {
    // const instance = axios.create({
    //   // .. where we make our configurations
    //   baseURL: 'https://api.example.com'
    // });
    const response = await instance.get(`/addPos?phone=${phone}&key=${id}`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};
