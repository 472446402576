import axios from 'axios';

const deletePackageDetailesApi = async (PackageDetailesId, token) => {
  try {
    const formData = new FormData();

    if (PackageDetailesId) {
      formData.append('id', PackageDetailesId);
    }

    const response = await axios.post(`package-feature-delete`, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default deletePackageDetailesApi;
