import { Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import CustomerUserColums from './CustomerUserColums';
import EditUserForm from './EditUserForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import GetCustomerUser from '../../apis/customer-user-api/getCustomerUser';

const CustomerUserPage = () => {
  const [CustomerUserData, setCustomerUserData] = useState([]);
  const [count, setcount] = useState([]);
  const [loading, setloading] = useState(false);
  const [reloadingData, setReloadingData] = useState(true);
  const [targetClient, setTargetClient] = useState({});
  // console.log(targetClient);
  const [adminModalOpened, setAdminModalOpened] = useState(false);
  const [customerUserDataSource, setCustomerUserDataSource] = useState([]);
  let params = useParams();

  const { user } = useContext(UesrContext);
  // console.log(user.token);

  useEffect(() => {
    if (reloadingData) {
      const getCustomerUserCall = async () => {
        try {
          setloading(true);
          const fetchedData = await GetCustomerUser(user?.token, params?.id_user);
          console.log(fetchedData);
          if (!fetchedData) {
            setloading(false);
          } else {
            setloading(false);
            setCustomerUserData(fetchedData);
          }
        } catch (error) {
          setloading(false);
          store.addNotification({
            title: 'حدث خطأ ',
            message: 'حاول فى وقت لاحق',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
          console.log(error);
        }
      };
      getCustomerUserCall();
      setReloadingData(false);
    }
  }, [user?.token, count, params?.id_user, reloadingData]);
  // console.log(count);

  useEffect(() => {
    let isMounted = true;

    if (CustomerUserData) {
      const mappedPayments = CustomerUserData.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setCustomerUserDataSource(mappedPayments);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [CustomerUserData, CustomerUserData.length, count]);

  return (
    <div>
      <Table
        columns={CustomerUserColums(
          count,
          setcount,
          user?.token,
          setAdminModalOpened,
          user,
          setTargetClient,
          setReloadingData
        )}
        dataSource={customerUserDataSource}
        loading={loading}
        scroll={{ x: 1200 }}
      />
      {adminModalOpened && (
        <Modal
          className="add-Admin-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={'تعديل'}
          visible={adminModalOpened}
          onOk={() => {
            setAdminModalOpened(false);
            setTargetClient({});

            // setSelectedServId('');
            // setSelectedAdmin(null);
          }}
          onCancel={() => {
            setAdminModalOpened(false);
            setTargetClient({});
            // setSelectedServId('');
            // setSelectedAdmin(null);
          }}
          footer={false}>
          <EditUserForm
            {...{ setTargetClient, targetClient, setAdminModalOpened, setReloadingData }}
          />
        </Modal>
      )}
    </div>
  );
};

export default CustomerUserPage;
