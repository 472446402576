import { Input, Select } from 'antd';
import React, { useContext } from 'react';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';

const TechnicalSupportFilter = () => {
  const { Option } = Select;
  // const { allTechnicalSupport } = useContext(SellesContext);
  const {
    TechnicalSupportModalOpened,
    setFetchCount,
    fetchCount,
    loadingTechnicalSupport,
    setTechnicalSupportModalOpened,
    setLoadingTechnicalSupport,
    selectedServId,
    setAllTechnicalSupport,
    selectedTechnicalSupport,
    allTechnicalSupport,
    setSelectedServId,
    setSelectedTechnicalSupport,
    setFilterValues
  } = useContext(TechnicalSupportContext);
  // console.log(allTechnicalSupport);
  function handleChangeSearch(e) {
    console.log(e.target.value);
    setFilterValues((prv) => ({ ...prv, search: e.target.value }));
    setFetchCount((prevState) => prevState + 1);
    // const filteredSelles = allTechnicalSupport.filter((serv) => serv.key === value);
  }

  return (
    <div className="Selles-filter">
      {/* <Select
        className="questions-filter-select"
        defaultValue="all"
        style={{ width: 162 }}
        size="large"
        onChange={handleChange}>
        <Option value="all">جميع الخدامات</Option>
        {allTechnicalSupport?.length > 0 &&
          allTechnicalSupport.map((op, index) => (
            <Option key={index} value={op.key}>
              {op.name_ar}
            </Option>
          ))}
      </Select> */}
      <Input
        type="text"
        name="email"
        onChange={handleChangeSearch}
        placeholder=" الإسم أو عنوان التذكرة أو رقم الهاتف"
        style={{ width: '250px', height: '42px' }}
      />
    </div>
  );
};

export default TechnicalSupportFilter;
