import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import NfcCustomersContext from '../../contexts/nfc-customers-context/NfcCustomersProvider';
import NfcCustomersTableColumns from './NfcCustomersTableColumns';
import { Excel } from 'antd-table-saveas-excel';
import { Table } from 'antd';
import getAllNfcCostumers from '../../apis/nfc-customers/getAllNfcCostumers';

const NfcCustomersTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    setLoadingNfcCustomers,
    setNfcCustomersModalOpened,
    setSelectedServId,
    setSelectedNfcCustomers,
    loadingNfcCustomers,
    allNfcCustomersTable,
    setAllNfcCustomersTable
  } = useContext(NfcCustomersContext);

  const [NfcCustomersDataSource, setNfcCustomersDataSource] = useState([]);

  useEffect(() => {
    const getNfcCustomers = async () => {
      try {
        setLoadingNfcCustomers(true);
        const getNfcCustomersRes = await getAllNfcCostumers(user?.token);
        // console.log(getNfcCustomersRes);
        // if (getNfcCustomersRes.status === 200 && getNfcCustomersRes?.data?.data) {
        setAllNfcCustomersTable(getNfcCustomersRes);
        setLoadingNfcCustomers(false);
        // }
      } catch (error) {
        setLoadingNfcCustomers(false);
        console.log(error);
      }
    };
    getNfcCustomers();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allNfcCustomersTable) {
      const mappedNfcCustomers = allNfcCustomersTable.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setNfcCustomersDataSource(mappedNfcCustomers);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allNfcCustomersTable, allNfcCustomersTable?.length, fetchCount]);

  const cols = NfcCustomersTableColumns(
    user?.token,
    user?.type,
    setFetchCount,
    setLoadingNfcCustomers,
    setNfcCustomersModalOpened,
    setSelectedServId
  );

  const expordDataToExcel = () => {
    let slicedCols = cols.slice(0, -2);
    console.log(cols);
    console.log(slicedCols);
    const excel = new Excel();
    console.log('ssss', excel);
    console.log(NfcCustomersDataSource);

    excel
      .addSheet('Sheet 1')
      .addColumns(slicedCols)
      .addDataSource(NfcCustomersDataSource, {
        str2Percent: true
      })
      .saveAs('المسوقين.xlsx');
  };
  return (
    <Table
      pagination={{ pageSize: 10 }}
      showSizeChanger={false}
      className="NfcCustomers-table"
      dataSource={NfcCustomersDataSource}
      columns={NfcCustomersTableColumns(
        user?.token,
        user?.type,
        setFetchCount,
        setLoadingNfcCustomers,
        setNfcCustomersModalOpened,
        setSelectedServId,
        setSelectedNfcCustomers
      )}
      loading={loadingNfcCustomers}
      scroll={{ x: 1200 }}
    />
  );
};

export default NfcCustomersTable;
