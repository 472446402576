import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import MerchantsStatsContext from '../../contexts/merchants-stats-context/MerchantsStatsProvider';

import './MerchantsStatsCharts.scss';

// const options = {
//     width: '100%',
//     height: 400,
//     chartArea: { 'width': '95%', 'height': '95%' },
//     colors: ['#FEC868', '#509b8f'],
//     legend: { textStyle: { fontSize: 14 } }
// };
// const options2 = {
//     width: '100%',
//     height: 400,
//     chartArea: { 'width': '95%', 'height': '95%' },
//     colors: ['#cf1322', '#0088FE'],
//     legend: { textStyle: { fontSize: 14 } }
// };
// const options3 = {
//     width: '100%',
//     height: 400,
//     chartArea: { 'width': '95%', 'height': '95%' },
//     colors: ['#dc3912', '#0088FE'],
//     legend: { textStyle: { fontSize: 14 } }
// };
// const options4 = {
//     width: '100%',
//     height: 400,
//     chartArea: { 'width': '95%', 'height': '95%' },
//     colors: ['#FF7B54', '#FFB26B'],
//     legend: { textStyle: { fontSize: 14 } }
// };

export const options = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017',
    width: '20%'
  }
};

const MerchantsStatsCharts = ({ allMerchantsStats }) => {
  const [data, setData] = useState([]);
  const { loadingMerchantsStats } = useContext(MerchantsStatsContext);

  useEffect(() => {
    if (!loadingMerchantsStats && allMerchantsStats) {
      let customData = [];
      allMerchantsStats?.SaleInvoiceChart.forEach((item) => {
        customData.push([(item.dayNum + 1).toString(), +item.total_price?.toFixed()]);
      });
      allMerchantsStats?.buyInvoiceChart?.forEach((item, idx) => {
        customData[idx].push(item.total_price ? +item.total_price.toFixed() : 0);
      });
      customData.reverse();
      customData.unshift(['اليوم', 'المبيعات', 'المشتريات']);
      setData(customData);
    }
  }, [allMerchantsStats]);

  return (
    <div className="MerchantsStatsCharts">
      <div>
        {allMerchantsStats && !loadingMerchantsStats ? (
          <Chart
            width={'100%'}
            height={'auto'}
            chartType="ColumnChart"
            data={data}
            options={{
              colors: ['#0088FE', '#cf1322'],
              width: '100%',
              height: 400,
              bar: { groupWidth: '40%' },
              legend: { position: 'right' }
            }}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default MerchantsStatsCharts;
