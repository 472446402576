import axios from 'axios';

const getCustomersPdfApi = async (token, selectedFilter, EmailOrPhoneOrSales) => {
    try {
        let pram;
        if (selectedFilter === '0') {
            pram = '';
        } else if (selectedFilter === '1') {
            pram = '?expire_soon=true';
        } else if (selectedFilter === '2') {
            pram = '?status=2';
        } else if (selectedFilter === '3') {
            pram = '?package_id=1';
        } else if (selectedFilter === '4') {
            pram = '?package_id=2';
        } else if (selectedFilter === '5') {
            pram = '?package_id=3';
        } else if (selectedFilter === '6') {
            pram = '?package_id=4';
        } else if (selectedFilter === '7') {
            pram = '?package_id=5';
        } else if (selectedFilter === '8') {
            pram = '?payment_status=1';
        } else if (selectedFilter === '9') {
            pram = '?payment_status=2';
        } else if (selectedFilter === '10') {
            pram = `?email=${EmailOrPhoneOrSales}`;
        } else if (selectedFilter === '11') {
            pram = `?phone=${EmailOrPhoneOrSales}`;
        } else if (selectedFilter === '12') {
            pram = `?sales_id=${EmailOrPhoneOrSales}`;
        } else if (selectedFilter === '444') {
            pram = `?sollemKey=1`;
        } else {
            pram = '';
        }
        const response = await axios.get(`/customers/export-to-pdf${pram}`, {
            baseURL: process.env.REACT_APP_BASE_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                responseType: 'blob'
            }
        });


    } catch (e) {
        console.log(e);
    }




};

export default getCustomersPdfApi;