import axios from 'axios';

const createAdmin = async (url, values, token, id, type) => {
  try {
    const {
      password,
      phone,
      subscription_type,
      email,
      name,
      discount,
      my_ratio,
      code,
      platform,
      status,
      code_packages
    } = values;
    const pickedValues = {
      phone,
      email,
      name,
      code
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    // for (let key in pickedValues) {
    //   formData.append(key, pickedValues[key]);
    // }
    // if (discount || discount === '0') {
    formData.append('discount', discount);
    // }

    if (my_ratio) {
      formData.append('my_ratio', my_ratio);
    }
    if (platform) {
      formData.append('platform', platform);
    }
    if (status) {
      formData.append('status', status);
    }
    if (subscription_type) {
      formData.append('subscription_type', subscription_type);
    }
    if (code_packages) {
      formData.append('code_packages', [code_packages]);
    }

    if (password) {
      formData.append('password', password);
    }

    if (type) {
      formData.append('type', type);
    }
    if (id !== 0) {
      formData.append('admin_id', id);
    }

    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createAdmin;
