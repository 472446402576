import { Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import getAllNotification from '../../apis/Notification/getAllNotification';
import NotificationCenterContext from '../../contexts/notification-center-context/NotificationCenterProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import NotificationColumns from './notificationTableColumns';

const NotificationTable = () => {
    const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingcustomer,
    setLoadingNotificationCenterData,
    allNotificationCenterData,
    setAllNotificationCenterData,
    setSelectedNotificationCenter,
    setNotificationCenterModalOpened,
    selectedNotificationCenter,
    loadingNotificationCenterData,
    setNotificationCenterDetailsModalOpened
  } = useContext(NotificationCenterContext);
  const [notificationDataSource, setNotificationDataSource] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getnotifications = async () => {
      try {
        setLoadingNotificationCenterData(true);
        let  getnotificationsRes = await getAllNotification(user?.token);
        
        if (getnotificationsRes) {
          if (isMounted) {
            setAllNotificationCenterData(getnotificationsRes);
            setLoadingNotificationCenterData(false);
          }
        }
      } catch (error) {
        setLoadingNotificationCenterData(false);
        console.log(error);
      }
    };
    getnotifications();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, ]);

  useEffect(() => {
    let isMounted = true;

    if (allNotificationCenterData) {
      const mappedcustomer = allNotificationCenterData.map((ques) => ({
        ...ques,
        key: ques.id,
        type:{is_push:+ques.is_push ,is_sms:+ques.is_sms,is_email:+ques.is_email }
      }));

      if (isMounted) {
        setNotificationDataSource(mappedcustomer.reverse());
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allNotificationCenterData, allNotificationCenterData.length, fetchCount]);



   

    return (
        <Table
            pagination={{ pageSize: 5 }}
            className="Payments-table"
            dataSource={notificationDataSource}
            columns={NotificationColumns(
                user?.token,
                setFetchCount,
                setLoadingNotificationCenterData,
                setNotificationCenterModalOpened,
                setSelectedNotificationCenter,
                setNotificationCenterDetailsModalOpened
            )}
            loading={loadingNotificationCenterData}
            scroll={{ x: 1200 }}
        />
    )
}

export default NotificationTable