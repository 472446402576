/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
// import getBlogUsApi from '../../apis/about-us-api/getBlogUsApi';
import getAllQuestions from '../../apis/questions/getAllQuestions';
import blogColumns from './blogTableColumns';
import BlogContext from '../../contexts/blog-context/BlogProvider';
import getBlogApi from '../../apis/blog-api/getBlogApi';

const BlogTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingBlogData,
    setLoadingBlogData,
    allBlogData,
    setAllBlogData,
    setBlogModalOpened,
    setSelectedBlogId,
    setBlogDetailsModalOpened,
    setSelectedBlog
  } = useContext(BlogContext);
  const [blogDataSource, setBlogDataSource] = useState([]);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoadingBlogData(true);
        const getServicesRes = await getBlogApi(user?.token, 2);
        if (getServicesRes.status === 200 && getServicesRes?.data?.data) {
          setAllBlogData(getServicesRes.data.data);
          setLoadingBlogData(false);
        }
      } catch (error) {
        setLoadingBlogData(false);
        console.log(error);
      }
    };
    getServices();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allBlogData) {
      const mappedBlogData = allBlogData.map((serv) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setBlogDataSource(mappedBlogData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allBlogData, allBlogData.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="services-table"
      dataSource={blogDataSource}
      columns={blogColumns(
        user?.token,
        setFetchCount,
        setLoadingBlogData,
        setBlogModalOpened,
        setSelectedBlogId,
        setBlogDetailsModalOpened,
        setSelectedBlog
      )}
      loading={loadingBlogData}
      scroll={{ x: 1200 }}
    />
  );
};

export default BlogTable;
