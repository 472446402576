import React, { useContext } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import AddPaymentForm from './AddPaymentForm';
import PaymentsTable from './PaymentsTable';
import PaymentsContext from '../../contexts/Payments-context/PaymentsProvider';

import './PaymentsPage.scss';

const PaymentsPage = () => {
  const { PaymentModalOpened, setPaymentModalOpened, setSelectedServId, setSelectedPayment } =
    useContext(PaymentsContext);

  return (
    <div className="Payments-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setPaymentModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة الدفع</span>
        </Button>

        {/* <PaymentsFilter /> */}
      </div>

      <PaymentsTable />

      {PaymentModalOpened && (
        <Modal
          className="add-Payment-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedServId ? 'تعديل الدفع' : 'اضـافة الدفع جديدة'}
          visible={PaymentModalOpened}
          onOk={() => {
            setPaymentModalOpened(false);
            setSelectedServId('');
            setSelectedPayment(null);
          }}
          onCancel={() => {
            setPaymentModalOpened(false);
            setSelectedServId('');
            setSelectedPayment(null);
          }}
          footer={false}>
          <AddPaymentForm />
        </Modal>
      )}
    </div>
  );
};

export default PaymentsPage;
