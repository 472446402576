import axios from 'axios';

const createService = async (url, values, token, id) => {
  try {
    const { service_imgs } = values;

    const formData = new FormData();
    // for (let key in pickedValues) {
    //   formData.append(key, pickedValues[key]);
    // }
    if (id !== 0) {
      formData.append('id', id);
    }
    if (service_imgs && service_imgs[0]) {
      formData.append('icon', service_imgs[0]);
    }

    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createService;
