import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingNotificationCenterData: false,
  setLoadingNotificationCenterData: (load) => {},
  allNotificationCenterData: [],
  setAllNotificationCenterData: (v) => {},
  notificationCenterModalOpened: false,
  setNotificationCenterModalOpened: (v) => {},
  notificationCenterDetailsModalOpened: false,
  setNotificationCenterDetailsModalOpened: (v) => {},
  selectedNotificationCenterId: '',
  setSelectedNotificationCenterId: (id) => {},
  selectedNotificationCenter: null,
  setSelectedNotificationCenter: (servKey) => {}
};

const NotificationCenterContext = createContext(INITIAL_VALUES);

export const NotificationCenterProvider = ({ children }) => {
  const [allNotificationCenterData, setAllNotificationCenterData] = useState(INITIAL_VALUES.allNotificationCenterData);
  const [loadingNotificationCenterData, setLoadingNotificationCenterData] = useState(INITIAL_VALUES.loadingNotificationCenterData);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [notificationCenterModalOpened, setNotificationCenterModalOpened] = useState(INITIAL_VALUES.notificationCenterModalOpened);
  const [notificationCenterDetailsModalOpened, setNotificationCenterDetailsModalOpened] = useState(INITIAL_VALUES.notificationCenterDetailsModalOpened);
  const [selectedNotificationCenterId, setSelectedNotificationCenterId] = useState(INITIAL_VALUES.selectedNotificationCenterId);
  const [selectedNotificationCenter, setSelectedNotificationCenter] = useState(INITIAL_VALUES.selectedNotificationCenter);

  return (
    <NotificationCenterContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingNotificationCenterData,
        setLoadingNotificationCenterData,
        allNotificationCenterData,
        setAllNotificationCenterData,
        notificationCenterModalOpened,
        setNotificationCenterModalOpened,
        selectedNotificationCenterId,
        setSelectedNotificationCenterId,
        selectedNotificationCenter,
        setSelectedNotificationCenter,
        notificationCenterDetailsModalOpened,
        setNotificationCenterDetailsModalOpened
      }}>
      {children}
    </NotificationCenterContext.Provider>
  );
};

export default NotificationCenterContext;
