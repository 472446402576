import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

let date = new Date();
const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

let finalDate = (date) => {
  let dateString = date;

  const dateObj = new Date(dateString);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString();
  const day = dateObj.getDate().toString();
  const hour = dateObj.getHours().toString();
  const minute = dateObj.getMinutes().toString();

  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
    day < 10
      ? '0' + day
      : day + ` ${hour > 12 ? hour - 12 : hour}:${minute < 10 ? '0' + minute : minute}`
  }  `;
  return formattedDate;
};

const DateForm = ({ targetDate, setTargetDate }) => {
  const [startDate, setStartDate] = useState(new Date());
  const changeDateRef = useRef(true);
  useEffect(() => {
    if (targetDate !== null && changeDateRef.current) {
      setStartDate(new Date(targetDate));
      changeDateRef.current = false;
    }
  }, [targetDate]);
  useEffect(() => {
    if (finalDate(startDate)) {
      // console.log(finalDate(startDate));
      setTargetDate(finalDate(startDate));
    }
  }, [startDate, setTargetDate]);
  return (
    <>
      <ReactDatePicker
        className="date-box"
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        minDate={date}
        selectsStart
        startDate={startDate}
        filterTime={filterPassedTime}
        showTimeSelect
        timeIntervals={1}
        dateFormat="yyyy/MM/dd h:mm aa"
        // dateFormat="yyyy/MM/dd , h:mm aa "
      />
    </>
  );
};
export default DateForm;
// import { DatePicker, Space } from 'antd';
// import moment from 'moment/moment';
// const { RangePicker } = DatePicker;
// const onChange = (value, dateString) => {
//   console.log('Selected Time: ', value);
//   console.log('Formatted Selected Time: ', dateString);
// };
// const range = (start, end) => {
//   const result = [];
//   for (let i = start; i < end; i++) {
//     result.push(i);
//   }
//   return result;
// };
// const DateForm = () => {
//   const onOk = (value) => {
//     console.log(value);
//     console.log('onOk: ', value);
//   };
//   // console.log(range(0, 24));

//   const disabledDateTime = () => ({
//     disabledHours: () => range(0, 24).splice(15, 20),
//     disabledMinutes: () => range(30, 60),
//     disabledSeconds: () => [55, 56]
//   });
//   const disabledRangeTime = (_, type) => {
//     if (type === 'start') {
//       return {
//         disabledHours: () => range(0, 60).splice(4, 20),
//         disabledMinutes: () => range(30, 60),
//         disabledSeconds: () => [55, 56]
//       };
//     }
//     return {
//       disabledHours: () => range(0, 60).splice(20, 4),
//       disabledMinutes: () => range(0, 31),
//       disabledSeconds: () => [55, 56]
//     };
//   };
//   return (
//     <Space direction="vertical" size={12}>
//       <DatePicker
//         // showTime
//         onChange={onChange}
//         onOk={onOk}
//         disabledTime={disabledDateTime}
//         showTime={{ format: 'HH:mm' }}
//         disabledDate={(current) => current.isBefore(moment().subtract(1, 'day'))}
//       />
//     </Space>
//   );
// };
// export default DateForm;

/* disabledDate={(current) =>
                current.isBefore(moment().subtract(1, 'day'))
              }
const handlePickerChange = (date, dateString) => {

    setInvoiceDeferredPayment({
      invoice_due_date: dateString
    });
    if (dateString == '') {
      setInvoiceDateError(true);
    } else {
      setInvoiceDateError(false);
    }
  */
