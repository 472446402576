import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingPackages: false,
  setLoadingPackages: (load) => {},
  allPackages: [],
  setAllPackages: (Packages) => {},
  PackageModalOpened: false,
  setPackageModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedPackage: null,
  setSelectedPackage: (servKey) => {}
};

const PackagesContext = createContext(INITIAL_VALUES);

export const PackagesProvider = ({ children }) => {
  const [allPackages, setAllPackages] = useState(INITIAL_VALUES.allPackages);
  const [loadingPackages, setLoadingPackages] = useState(INITIAL_VALUES.loadingPackages);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [PackageModalOpened, setPackageModalOpened] = useState(INITIAL_VALUES.PackageModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedPackage, setSelectedPackage] = useState(INITIAL_VALUES.selectedPackage);

  return (
    <PackagesContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingPackages,
        setLoadingPackages,
        allPackages,
        setAllPackages,
        PackageModalOpened,
        setPackageModalOpened,
        selectedServId,
        setSelectedServId,
        selectedPackage,
        setSelectedPackage
      }}>
      {children}
    </PackagesContext.Provider>
  );
};

export default PackagesContext;
