import React, { useContext } from 'react';
import { Button, Modal } from 'antd';
import AddPackageForm from './AddPackageForm';
import PackagesTable from './PackagesTable';
import PackagesContext from '../../contexts/Packages-context/PackagesProvider';

import './PackagesPage.scss';
import PosPackagesContext from '../../contexts/Pos-Packages-context/PosPackagesProvider';
import { PlusOutlined } from '@ant-design/icons';

const PosPackagesPage = () => {
  const { PackageModalOpened, setPackageModalOpened, setSelectedServId, setSelectedPackage , selectedPackage} =
    useContext(PosPackagesContext);
    console.log(setSelectedServId);
  return (
    <div className="Packages-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setPackageModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة باقه</span>
        </Button>

      </div>

      <PackagesTable />
      
      {PackageModalOpened && (
        <Modal
          className="add-Package-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedPackage ? 'تعديل الباقه' : 'اضـافة باقه جديدة'}
          visible={PackageModalOpened}
          onOk={() => {
            setPackageModalOpened(false);
            setSelectedServId('');
            setSelectedPackage(null);
          }}
          onCancel={() => {
            setPackageModalOpened(false);
            setSelectedServId('');
            setSelectedPackage(null);
          }}
          footer={false}
        >
          <AddPackageForm />
        </Modal>
      )}
    </div>
  );
};

export default PosPackagesPage;
