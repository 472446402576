import axios from 'axios';

const deletePaymentApi = async (PaymentId, token) => {
  try {
    const formData = new FormData();

    if (PaymentId) {
      formData.append('id', PaymentId);
    }

    const response = await axios.post(`payment-delete`, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default deletePaymentApi;
