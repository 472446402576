import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  filterValues: null,
  setFilterValues: () => {},
  loadingTechnicalSupport: false,
  setLoadingTechnicalSupport: (load) => {},
  allTechnicalSupport: [],
  setAllTechnicalSupport: (TechnicalSupport) => {},
  TechnicalSupportModalOpened: false,
  setTechnicalSupportModalOpened: (v) => {},
  ViewTicketModalOpened: false,
  setViewTicketModalOpened: (v) => {},
  selectedServId: 0,
  setSelectedServId: (id) => {},
  selectedTechnicalSupport: null,
  setSelectedTechnicalSupport: (servKey) => {},
  allMsgs: {},
  setAllMsgs: (v) => {}
};

const TechnicalSupportContext = createContext(INITIAL_VALUES);

export const TechnicalSupportProvider = ({ children }) => {
  const [allTechnicalSupport, setAllTechnicalSupport] = useState(
    INITIAL_VALUES.allTechnicalSupport
  );
  const [loadingTechnicalSupport, setLoadingTechnicalSupport] = useState(
    INITIAL_VALUES.loadingTechnicalSupport
  );
  const [filterValues, setFilterValues] = useState(INITIAL_VALUES.filterValues);

  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [TechnicalSupportModalOpened, setTechnicalSupportModalOpened] = useState(
    INITIAL_VALUES.TechnicalSupportModalOpened
  );
  const [ViewTicketModalOpened, setViewTicketModalOpened] = useState(
    INITIAL_VALUES.ViewTicketModalOpened
  );
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedTechnicalSupport, setSelectedTechnicalSupport] = useState(
    INITIAL_VALUES.selectedTechnicalSupport
  );
  const [allMsgs, setAllMsgs] = useState(INITIAL_VALUES.allMsgs);

  return (
    <TechnicalSupportContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        filterValues,
        setFilterValues,
        loadingTechnicalSupport,
        setLoadingTechnicalSupport,
        allTechnicalSupport,
        setAllTechnicalSupport,
        TechnicalSupportModalOpened,
        setTechnicalSupportModalOpened,
        selectedServId,
        setSelectedServId,
        selectedTechnicalSupport,
        setSelectedTechnicalSupport,
        ViewTicketModalOpened,
        setViewTicketModalOpened,
        allMsgs,
        setAllMsgs
      }}>
      {children}
    </TechnicalSupportContext.Provider>
  );
};
export default TechnicalSupportContext;
