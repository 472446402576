import { Button, Modal } from 'antd';
import React, { useContext, useEffect } from 'react';
import NfcCustomersContext from '../../contexts/nfc-customers-context/NfcCustomersProvider';
import { PlusOutlined } from '@ant-design/icons';
import NfcCustomersTable from './NfcCustomersTable';
import AddNfcCustomerForm from './AddNfcCustomerForm';
import './nfcCustomers.scss';
import getAllFatorah from '../../apis/fatorah-apis/getAllFatorah';
import UesrContext from '../../contexts/user-context/UserProvider';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';

const NfcCustomers = () => {
  const {
    NfcCustomersModalOpened,
    setNfcCustomersModalOpened,
    selectedServId,
    setSelectedServId,
    setSelectedNfcCustomers,
    setAllNfcCustomers
  } = useContext(NfcCustomersContext);
  const { allcustomer } = useContext(CustomerContext);
  const { user } = useContext(UesrContext);

  useEffect(() => {
    const getAllCustomers = async () => {
      const getcustomerRes = await getAllFatorah(user?.token);
      if (getcustomerRes) {
        setAllNfcCustomers(getcustomerRes);
      }
    };
    getAllCustomers();
  }, [user, allcustomer]);

  return (
    <div className="NfcCustomers-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setNfcCustomersModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة كود</span>
        </Button>

        {/* <NfcCustomersFilter /> */}
      </div>

      <NfcCustomersTable />

      {NfcCustomersModalOpened && (
        <Modal
          className="add-NfcCustomers-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedServId ? 'تعديل اكواد nfc' : 'إضافة اكواد nfc'}
          visible={NfcCustomersModalOpened}
          onOk={() => {
            setNfcCustomersModalOpened(false);
            setSelectedServId('');
            setSelectedNfcCustomers(null);
          }}
          onCancel={() => {
            setNfcCustomersModalOpened(false);
            setSelectedServId('');
            setSelectedNfcCustomers(null);
          }}
          footer={false}>
          <AddNfcCustomerForm />
        </Modal>
      )}
    </div>
  );
};

export default NfcCustomers;
