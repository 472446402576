import axios from 'axios';
const createQuestion = async (url, values, token, id, type) => {
  try {
    // const { cat_id } = values;
    // const formData = new FormData();

    // if (id !== 0) {
    //   formData.append('ref_key', id);
    // }
    const { title, body, title_en, body_en, service_imgs, cat_id } = values;
    const pickedValues = { title, body, title_en, body_en };
    const formData = new FormData();
    const PackageFormData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    if (id !== 0) {
      formData.append('ref_key', id);
    }
    if (service_imgs && service_imgs[0]) {
      formData.append('icon', service_imgs[0]);
    }
    formData.append('type', type);

    if (cat_id && id !== 0) {
      PackageFormData.append('package_id', cat_id);
      PackageFormData.append('ref_key', id);
    }
    const createQuestionsRes = await axios.post(url, cat_id ? PackageFormData : formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createQuestionsRes;
  } catch (error) {
    console.log(error);
  }
};

export default createQuestion;
