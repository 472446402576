/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddPackageForm.scss';
import UploadPackageFile from './UploadPackageFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createPackage from '../../apis/Packages/createPackage';
import UesrContext from '../../contexts/user-context/UserProvider';
import PackagesContext from '../../contexts/Packages-context/PackagesProvider';
import UploadActivePackageFile from './UploadActivePackageFile';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import PosPackagesContext from '../../contexts/Pos-Packages-context/PosPackagesProvider';
import createPosPackage from '../../apis/Pos-Packages/createPosPackage';

const defaultValues = {
  icon: null,
  name: '',
  price: '',
  description: '',
  number_pos: '',
  // name_en: '',
  // price_en: '',
  // description_en: '',
  // extra: '',
  // extra_en: ''
};

const AddPackageForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setPackageModalOpened,
    allPackages,
    setSelectedServId,
    selectedServId,
    selectedPackage,
    setSelectedPackage
  } = useContext(PosPackagesContext);

  let schema;

  if (selectedPackage && selectedServId) {
    schema = Yup.object().shape({
      name: Yup.string().required('الأسم مطلوب'),
      price: Yup.string().required('السعر مطلوب'),
      description: Yup.string().required('الوصف مطلوب'),
      // name_en: Yup.string().required('الأسم  بالانجليزي مطلوب'),
      // price_en: Yup.string().required('السعر بالانجليزي  مطلوب'),
      // // offer_price: Yup.string(),
      // description_en: Yup.string().required('الوصف   بالانجليزي مطلوب')
      // extra: Yup.string(),
      // extra_en: Yup.string()
    });
  } else {
    schema = Yup.object().shape({
      icon: Yup.mixed().required('من فضلك اختار صورة الباقه'),
      name: Yup.string().required('الأسم مطلوب'),
      price: Yup.string().required('السعر مطلوب'),
      description: Yup.string().required('الوصف مطلوب'),
      number_pos: Yup.string().required('عدد نقاط البيع مطلوب'),

      // icon_active: Yup.mixed().required('من فضلك اختار صورة الباقه المختاره'),
      // name_en: Yup.string().required('الأسم  بالانجليزي مطلوب'),
      // price_en: Yup.string().required('السعر  بالانجليزي مطلوب'),
      // offer_price: Yup.string(),
      // description_en: Yup.string().required('الوصف  بالانجليزي مطلوب')
      // extra: Yup.string(),
      // extra_en: Yup.string()
    });
  }
  
  
  useEffect(() => {
    
    
    if (selectedServId) {
      const foundPackage = allPackages.find((serv) => serv.id === selectedServId);
      if (foundPackage) setSelectedPackage(foundPackage);
    }
  }, [selectedServId]);

  const [urls, setUrls] = useState([]);
  const [urlsActive, seturlsActive] = useState([]);
  useEffect(() => {
    if (selectedPackage?.icon) {
      
      setUrls([selectedPackage.icon]);
    }
    if (selectedPackage?.icon_active) {
      
      seturlsActive([selectedPackage.icon_active]);
    }
  }, [selectedPackage]);
  
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (data) => {
    // console.log(selectedPackage);
    try {
      let res;
      if (selectedPackage) {
        res = await createPosPackage(`/package-pos/update/${selectedPackage.id}`, data, user?.token, selectedPackage.id);
      } else {
        res = await createPosPackage('/package-pos/create', data, user?.token, 0);
      }
      // console.log('create Package response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء الدفع',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setPackageModalOpened(false);
        setSelectedServId('');
        setSelectedPackage(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء الدفع المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedPackage && selectedServId) {
      const {
        name,
        price,
        description,
        number_pos,
        // name_en,
        // price_en,
        // description_en,
        // extra,
        // extra_en,
        // offer_price,
        // is_offer
      } = selectedPackage;
      setValue('name', name);
      setValue('price', price);
      setValue('description', description);
      setValue('number_pos', number_pos);
      // setValue('name_en', name_en);
      // setValue('price_en', price_en);
      // setValue('description_en', description_en);
      // setValue('extra', extra);
      // setValue('extra_en', extra_en);
      // setValue('offer_price', offer_price);
      // setValue('is_offer', String(is_offer));
    }
  }, [selectedPackage, selectedServId]);

  return (
    <Form
      className="add-Package-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <UploadPackageFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="icon"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الباقه
            </>
          }
          urls={urls}
          errorMsg={errors?.icon ? errors?.icon?.message : ''}
        />
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الأسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="description"
            type="text"
            placeholder="الوصف..."
            errorMsg={errors?.description?.message}
            validateStatus={errors?.description ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="price"
            type="text"
            placeholder="السعر..."
            errorMsg={errors?.price?.message}
            validateStatus={errors?.price ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="number_pos"
            type="text"
            placeholder="عدد نقاط البيع..."
            errorMsg={errors?.number_pos?.message}
            validateStatus={errors?.number_pos ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* <AntdSelectOption
          name="is_offer"
          errorMsg={errors?.is_offer?.message}
          validateStatus={errors?.is_offer ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'يوجد خصم', value: '1' },
            { title: 'لا يوجد خصم', value: '0' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              الخصم
            </p>
          }
          formClassName="add-Package-form"
        />
        <div className="form-field-wrapper">
          <AntdTextField
            name="offer_price"
            type="text"
            placeholder="السعر بعد الخصم..."
            errorMsg={errors?.offer_price?.message}
            validateStatus={errors?.offer_price ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="name_en"
            type="text"
            placeholder="الأسم  بالانجليزي..."
            errorMsg={errors?.name_en?.message}
            validateStatus={errors?.name_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="description_en"
            type="text"
            placeholder=" الوصف  بالانجليزي..."
            errorMsg={errors?.description_en?.message}
            validateStatus={errors?.description_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="extra_en"
            type="text"
            placeholder="مميزات بالانجليزي..."
            errorMsg={errors?.extra_en?.message}
            validateStatus={errors?.extra_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="price_en"
            type="text"
            placeholder="السعر بالانجليزي..."
            errorMsg={errors?.price_en?.message}
            validateStatus={errors?.price_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة الباقه
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddPackageForm;
