import axios from 'axios';

export const signinApi = async (values, langIsoCode) => {
  const { name, password } = values;
  try {
    const formData = new FormData();
    formData.append('email', name);
    formData.append('password', password);
    const signinRes = await axios.post('/login', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        lang: langIsoCode
      }
    });
    
    return signinRes;
  } catch (error) {
    console.log(error);
  }
};
