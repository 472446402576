import React, { useContext } from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import AddAdminForm from './AddAdminForm';
import AdminTable from './AdminTable';
import AdminContext from '../../contexts/Admin-context/AdminProvider';

import './AdminPage.scss';

const AdminPage = () => {
  const {
    AdminModalOpened,
    setAdminModalOpened,
    selectedServId,
    setSelectedServId,
    setSelectedAdmin
  } = useContext(AdminContext);

  return (
    <div className="Admin-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setAdminModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة مسوق</span>
        </Button>

        {/* <AdminFilter /> */}
      </div>

      <AdminTable />

      {AdminModalOpened && (
        <Modal
          className="add-Admin-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedServId ? 'تعديل مسوق' : 'اضـافة مسوق '}
          visible={AdminModalOpened}
          onOk={() => {
            setAdminModalOpened(false);
            setSelectedServId('');
            setSelectedAdmin(null);
          }}
          onCancel={() => {
            setAdminModalOpened(false);
            setSelectedServId('');
            setSelectedAdmin(null);
          }}
          footer={false}>
          <AddAdminForm />
        </Modal>
      )}
    </div>
  );
};

export default AdminPage;
