import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import { store } from 'react-notifications-component';
import deleteNfcCode from '../../apis/nfc-customers/deleteNfcCode';

const NfcCustomersTableColumns = (
  token,
  type,
  setFetchCount,
  setLoadingAdmin,
  setAdminModalOpened,
  setSelectedServId,
  setSelectedAdmin
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingAdmin(true);
      const res = await deleteNfcCode(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingAdmin(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الخدمة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingAdmin(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingAdmin(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleEditAdmin = (record) => {
    // console.log(record, 'setSelectedAdmin');
    // console.log(key);
    setAdminModalOpened(true);
    setSelectedServId(record?.key);
    setSelectedAdmin(record);
  };

  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'العميل',
      dataIndex: 'name',
      width: 62,
      render: (_, record) => {
        return (
          <div
            className="service-img"
            dangerouslySetInnerHTML={{ __html: record.customer.name }}></div>
        );
      }
    },
    {
      title: 'tag-nfid',
      dataIndex: 'tag_nfid',
      width: 62
    },
    // {
    //   title: 'المسؤل',
    //   dataIndex: 'admin',
    //   width: 62
    // },
    {
      title: 'الحالة',
      dataIndex: 'status',
      width: 62,
      render: (_, record) => {
        if (record.status === 1) {
          return <Tag color="cyan">مفعل </Tag>;
        } else {
          return <Tag color="red">غير مفعل </Tag>;
        }
      }
    },
    {
      title: 'تعديل',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditAdmin(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من الحذف</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default NfcCustomersTableColumns;
