import React from 'react';

const SystemErrorsDetailsModal = ({ selectedSystemErrors }) => {
  return (
    <div className="SystemErrorsDetails">
      <table className="SystemErrors_details_table">
        <tbody>
          <tr>
            <td>رقم الخطأ</td>
            <td>{selectedSystemErrors.id}</td>
          </tr>
          <tr>
            <td>الرسايل</td>
            <td>
              <p className="message">
                {selectedSystemErrors.message ? selectedSystemErrors.message : '---'}
              </p>
            </td>
          </tr>
          <tr>
            <td>رقم السطر</td>
            <td>{selectedSystemErrors.line}</td>
          </tr>
          <tr>
            <td>الملف</td>
            <td>
              <p className="message">{selectedSystemErrors.file}</p>
            </td>
          </tr>
          <tr>
            <td>التاريخ</td>
            <td>{selectedSystemErrors.created_at.slice(0, 10)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SystemErrorsDetailsModal;
