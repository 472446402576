import axios from 'axios';

const sendEmailsMessageApi = async (values, token) => {
  try {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('message', values.message);

    const response = await axios.post('/Emails/send', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    return response;
  } catch (e) {
    console.log(e);
  }
};

export default sendEmailsMessageApi;
