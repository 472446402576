import axios from 'axios';

const updateCustomerPasswordApi = async (values, token) => {
  try {
    const formData = new FormData();
    formData.append('password', values.password);
    formData.append('confirm_password', values.confirm);
    // formData.append('ref_key', values.ref_key);
    const response = await axios.post(`/customers-update-password?ref_key=${values.ref_key}`, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    return response;
  } catch (e) {
    console.log(e);
  }
};

export default updateCustomerPasswordApi;