import { Button, Modal } from 'antd'
import React, { useContext, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import './notificationCenterPage.scss'
import NotificationCenterContext from '../../contexts/notification-center-context/NotificationCenterProvider';
import AddNotificationForm from './AddNotificationForm';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import getAllFatorah from '../../apis/fatorah-apis/getAllFatorah';
import UesrContext from '../../contexts/user-context/UserProvider';
import NotificationTable from './NotificationTable';
import NotificationCenterModel from './NotificationCenterModel';

const NotificationCenterPage = () => {
    const { user } = useContext(UesrContext);

    const { setNotificationCenterModalOpened, notificationCenterModalOpened  , notificationCenterDetailsModalOpened , setNotificationCenterDetailsModalOpened , selectedNotificationCenter} = useContext(NotificationCenterContext);
    const {
        fetchCount,
        setLoadingcustomer,
        selectedFilter,
        setAllcustomer,
        searchText,
      } = useContext(CustomerContext);

    useEffect(() => {
        let isMounted = true;
        const getcustomer = async () => {
            try {
                setLoadingcustomer(true);
                let getcustomerRes;

                getcustomerRes = await getAllFatorah(user?.token, '0');

                if (getcustomerRes) {
                    if (isMounted) {
                        setAllcustomer(getcustomerRes);
                        setLoadingcustomer(false);
                    }
                }
            } catch (error) {
                setLoadingcustomer(false);
                console.log(error);
            }
        };
        getcustomer();

        return () => {
            isMounted = false;
        };
    }, [fetchCount, selectedFilter, searchText]);

    return (
        <div className='notificationcenterpage'>
            <Button
                onClick={() => setNotificationCenterModalOpened(true)}
                className="add-serv-btn"
                type="primary"
                icon={<PlusOutlined />}>
                <span className="btn-text">اضافة اشعار جديد </span>
            </Button>
            {notificationCenterModalOpened && (
                <Modal
                    className="add-service-modal"
                    width="90%"
                    style={{ maxWidth: '900px' }}
                    title='اضافة اشعار'
                    visible={notificationCenterModalOpened}
                    onOk={() => {
                        setNotificationCenterModalOpened(false);
                    }}
                    onCancel={() => {
                        setNotificationCenterModalOpened(false);
                    }}
                    footer={false}>
                    <AddNotificationForm />
                </Modal>
            )}
            {notificationCenterDetailsModalOpened && (
                <Modal
                    className="add-service-modal"
                    width="90%"
                    style={{ maxWidth: '900px' }}
                    title='تفاصيل الاشعار'
                    visible={notificationCenterDetailsModalOpened}
                    onOk={() => {
                        setNotificationCenterDetailsModalOpened(false);
                    }}
                    onCancel={() => {
                        setNotificationCenterDetailsModalOpened(false);
                    }}
                    footer={false}>
                    <NotificationCenterModel selectedNotificationCenter={selectedNotificationCenter} />
                </Modal>
            )}
            <NotificationTable/>
        </div>
    )
}

export default NotificationCenterPage