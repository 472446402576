/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import DaftarTable from './DaftarTable';

import './DaftarPage.scss';

const DaftarPage = () => {
  return (
    <div className="Daftar-page">
      {/* <div className="new-filter-wrap">
        <Button
          onClick={() => setAboutModalOpened(true)}
          className="add-about-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة الميزه</span>
        </Button>

      </div> */}

      <DaftarTable />
    </div>
  );
};

export default DaftarPage;
