import React, { useContext, useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { Button, Form } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { EditOutlined, SendOutlined } from '@ant-design/icons';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { store } from 'react-notifications-component';
import createAdmin from '../../apis/Admin/createAdmin';

const defaultValues = {
  name: '',
  phone: '',
  email: '',
  password: '',
  code: ''
};

const ProfileForm = () => {
  const { user, setCurrentUser } = useContext(UesrContext);

  let schema;
  if (user.type === 2) {
    schema = yup.object().shape({
      name: yup.string().required('الأسم مطلوب'),
      phone: yup
        .string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),

      code: yup.string().required('الكود مطلوب')
    });
  } else {
    schema = yup.object().shape({
      name: yup.string().required('الأسم مطلوب'),
      phone: yup
        .string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح')
    });
  }

  // console.log('user: ', user);
  const {
    control,
    setValue,
    handleSubmit,

    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();
  const onSubmit = async (data) => {
    try {
      const fetchedData = await createAdmin(`/Admin/update`, data, user?.token, user.id, null);
      // console.log(fetchedData.data.data);
      if (!fetchedData?.data?.data) {
        store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setCurrentUser({ ...user, ...fetchedData.data.data, token: user?.token });
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تعديل البيانات بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      
    }
  };
  useEffect(() => {
    
    if (user) {
      setValue('email', user.email);
      // setValue('password', password);
      // setValue('platform', user.platform);
      setValue('code', user.code);
      setValue('phone', user.phone);
      // setValue('status', String(user.status));
      setValue('name', user.name);

      // convertPathToFile(user.image).then((file) => {
      //   setValue('image', [file]);
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCode = () => () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    console.log('random', r);

    setValue('code', r);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
        className="add-project-form">
        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="password"
            type="password"
            placeholder="الرقم السري..."
            errorMsg={errors?.password?.message}
            validateStatus={errors?.password ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div
          className={`form-field-wrapper form-field-wrapper-div ${
            user.type === 2 ? 'Active' : 'notActive'
          }`}>
          <div>
            <AntdTextField
              name="code"
              type="text"
              placeholder="الكود..."
              errorMsg={errors?.code?.message}
              validateStatus={errors?.code ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
          <div>
            <button type="button" onClick={generateCode()}>
              انشأ الكود
            </button>
          </div>
        </div>

        <div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            icon={<SendOutlined />}
            loading={isSubmitting}>
            تعديل
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProfileForm;
