import axios from 'axios';

const CreateTechnicalSupport = async (url, values, token, id) => {
  // console.log(url);
  try {
    const { client_id, priority, status, subject, description, attach } = values;
    // console.log(values);
    // console.log(values);

    const formData = new FormData();

    if (client_id) {
      formData.append('client_id', client_id);
    }
    if (priority) {
      formData.append('priority', priority);
    }
    if (status) {
      formData.append('status', status);
    }
    if (subject) {
      formData.append('subject', subject);
    }
    if (description) {
      formData.append('description', description);
    }
    if (attach) {
      formData.append('attach', attach[0]);
    }
    if (id !== 0) {
      formData.append('id', id);
    }
    // const formData = new FormData();
    // for (let key in values) {
    //   formData.append(key, values[key]);
    // }
    const createSerivceRes = await axios.post(
      `${url}${id !== null && id !== 0 ? '/' + id : ''}`,
      formData,
      {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token
        }
      }
    );
    console.log(createSerivceRes);

    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default CreateTechnicalSupport;
