import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { DeleteOutlined, EyeTwoTone, LinkOutlined } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import deleteAnswerApi from '../../apis/answers/deleteAnswer';

const PopOverAnswers = ({ content, title }) => (
  <Popover title={title} content={content} style={{ maxWidth: '280px' }}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);

const answersTableColumns = (
  token,
  setFetchCount,
  setLoadingAnswers,
  setAnswerModalOpened,
  selectedAnswerId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingAnswers(true);
      const res = await deleteAnswerApi(key, token);
      if (res.status === 200 && res.data.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingAnswers(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الاجابة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingAnswers(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingAnswers(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'index',
      width: 72
    },
    {
      title: 'اســم صاحب الطلب',
      dataIndex: 'name',
      width: 220
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      width: 152
    },
    {
      title: 'الخدمــة الخاصة بالسؤال',
      dataIndex: 'cat',
      width: 180
    },
    {
      title: 'تفاصيل الطلب',
      dataIndex: 'projectDesc',
      width: 82,
      render: (v) => {
        return <PopOverAnswers content={v} title="تفاصيل الطلب" />;
      }
    },
    {
      title: 'اعرض الاجابات',
      dataIndex: 'answers',
      width: 122,
      render: (v, record) => {
        return (
          <Tooltip title="اعرض الاجابات">
            <Button
              className="view-answers-btn"
              size="large"
              type="dashed"
              shape="circle"
              icon={<LinkOutlined />}
              onClick={() => {
                setAnswerModalOpened(true);
                selectedAnswerId(record.key);
              }}
            />
          </Tooltip>
        );
      }
    },

    {
      title: 'حذف الاجابة',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title="هل أنت متأكد من حذف الاجابة"
            onConfirm={(key) => handleDeleteRequest(record.key)}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف الاجابة">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default answersTableColumns;
