/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';

const UploadQuestionFile = (props) => {
  const { name, label, accept, errorMsg, register, unregister, setValue, multiple } = props;
  const { selectedQuestion, selectedServId } = useContext(QuestionsContext);

  // const [myDroppedFiles, setMyDroppedFiles] = useState([]);
  const [urls, setUrls] = useState([]);
  // const onDrop = useCallback(
  //   (droppedFiles) => {
  //     setValue(name, droppedFiles, { shouldValidate: true });
  //     // setMyDroppedFiles(droppedFiles);

  //     // read file
  //     const reader = new FileReader();
  //     reader.addEventListener('load', () => {
  //       setUrls([reader.result]);
  //     });
  //     reader.readAsDataURL(droppedFiles[0]);
  //   },
  //   [name]
  // );

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    // onDrop,
    accept: accept,
    multiple
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setValue(name, acceptedFiles, { shouldValidate: true });

      // read file
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUrls([reader.result]);
      });
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles, name]);
  

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  useEffect(() => {
    if (selectedQuestion && selectedQuestion.icon) {
      setUrls([`${process.env.REACT_APP_BASE_URL}/${selectedQuestion.icon}`]);
    } else {
      console.log('not selected');
      setUrls([]);
    }
  }, [selectedQuestion, selectedServId]);

  return (
    <div className="upload-files-wrapper">
      <label className="file-input-label" htmlFor={name}>
        {label}
      </label>
      <div className="dropzone-itself" {...getRootProps()}>
        <input
          name={name}
          lbale={label}
          accept={accept}
          className="file-input-itself"
          id={name}
          {...getInputProps()}
        />
        <div className={'dashed-border' + (isDragActive ? 'gray-500-bg' : 'gray-200-bg')}>
          {!urls?.length && <p className="text-center my-2">اسحب صورة الخاصيه وضعها هنا ...</p>}
          {urls?.length > 0 && (
            <div className="imgs-preview-wrapper">
              {urls.map((url, index) => {
                return (
                  <div className="img-preview" key={index}>
                    <img src={`${url}`} alt="preview" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default UploadQuestionFile;
