import axios from 'axios';

const getPaymentLog = async (token, values) => {
  // const url = `https://testwebsiteapi.fatoorah.sa/api/payment-logs?page=${values.page}`;
  const url = `/payment-logs?page=${values.page}`;
  try {
    const response = await axios.get(url, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default getPaymentLog;
