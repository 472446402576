import axios from 'axios';

const UpdateCustomerUser = async (url, values, token) => {
  try {
    const { phone, email, name, status } = values;
    const pickedValues = {
      phone,
      email,
      name
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    if (status) {
      formData.append('status', status);
    }
    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    // console.log(createSerivceRes);
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default UpdateCustomerUser;
