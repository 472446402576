import axios from 'axios';

const createNotification = async (url, values, token, id) => {
  const formData = new FormData();

  let is_push =0;
  let is_sms =0;
  let is_email =0;

  formData.append('subject',values.subject)
  formData.append('body',values.body)

  if (values.notificationType) {
    values.notificationType.map((item) => {
      if (item === 'sms') {
        formData.append('is_sms', 1)
        is_sms = 1;
      }
      else if (item === 'email') {
        formData.append('is_email', 1)
        is_email = 1;
      }
      else if (item === 'push') {
        formData.append('is_push', 1)
        is_push = 1;
      }
    })
  }

  let customersUrl;
  if(values.customer.length >= 1){
    // values.customer.map((item)=>{
    //   customersUrl
    // })
    customersUrl =  values.customer.toString()
    
  }
  else if (values.customer.length === 1) {
    customersUrl = values.customer[0]
  }
  // '&is_push=${is_push}&is_push=${is_push}&is_push=${is_push}'
  
  try {




    const createSerivceRes = await axios.post(`${url}?customer_id=${customersUrl}&is_push=${is_push}&is_email=${is_email}&is_sms=${is_sms}&subject=${values.subject}`, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createNotification;
