import React from 'react'
import './blogDetailsModel.scss'

const BlogDetailsModel = ({selectedBlog}) => {
  
    return (
        <table className='blog_details_table'>
          <tbody>
          <tr>
            <td>العنوان</td>
            <td>{selectedBlog.title}</td>
          </tr>
          <tr>
            <td>الصورة</td>
            <td><img src={selectedBlog.photo}/></td>
          </tr>
          <tr>
            <td>وصف الصورة</td>
            <td>{selectedBlog.img_desc}</td>
          </tr>
          <tr>
            <td>المحتوي</td>
            <td dangerouslySetInnerHTML={{ __html: selectedBlog.content }}></td>
          </tr>
          <tr>
            <td>تاريخ النشر</td>
            <td>{selectedBlog.created_at}</td>
          </tr>

          
          </tbody>
        </table>
    )
}

export default BlogDetailsModel