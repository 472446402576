import React, { useContext, useEffect, useState } from 'react';
import MerchantsStatsContext from '../../contexts/merchants-stats-context/MerchantsStatsProvider';
import { Table } from 'antd';
import './merchantsStatsTable.scss';

const MerchantsStatsTable = ({ allMerchantsStats }) => {
  const [data, setData] = useState([]);
  const { loadingMerchantsStats } = useContext(MerchantsStatsContext);
  var dataSource = [];
  data.map((i) => {
    var obj = {
      day: i[0],
      sales: i[1],
      Purchases: i[2]
    };
    dataSource.push(obj);
    dataSource.sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0));
  });
  // console.log(dataSource.sort((a, b) => (a.day > b.day ? 1 : b.day > a.day ? -1 : 0)));
  const columns = [
    {
      title: 'اليوم',
      dataIndex: 'day'
    },
    {
      title: 'المبيعات',
      dataIndex: 'sales'
    },
    {
      title: 'المشتريات',
      dataIndex: 'Purchases'
    }
  ];

  useEffect(() => {
    if (!loadingMerchantsStats && allMerchantsStats) {
      let customData = [];
      allMerchantsStats?.SaleInvoiceChart.forEach((item) => {
        customData.push([(item.dayNum + 1).toString(), +item.total_price.toFixed()]);
      });
      allMerchantsStats?.buyInvoiceChart.forEach((item, idx) => {
        customData[idx].push(item.total_price ? +item.total_price.toFixed() : 0);
      });
      customData.reverse();
      setData(customData);
    }
  }, [allMerchantsStats]);

  return (
    <div className="merchantsStatsTable">
      <Table
        pagination={{ pageSize: 7 }}
        scroll={{ x: 400 }}
        dataSource={dataSource}
        columns={columns}
        loading={loadingMerchantsStats}
      />
    </div>
  );
};

export default MerchantsStatsTable;
