import { Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import getContact from '../../apis/contact-api/getContact';
import UesrContext from '../../contexts/user-context/UserProvider';
import columns from './contactUsColums';

const ContactUsPage = () => {
  const [contact, setcontact] = useState([]);
  const [count, setcount] = useState([]);
  const [loading, setloading] = useState(false);
  const [contactsDataSource, setcontactsDataSource] = useState([]);

  const { user } = useContext(UesrContext);

  useEffect(() => {
    const getContactCall = async () => {
      try {
        setloading(true);
        const fetchedData = await getContact(user?.token);
        // console.log(fetchedData);
        if (!fetchedData) {
          setloading(false);
        } else {
          setloading(false);

          setcontact(fetchedData);
        }
      } catch (error) {
        setloading(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        console.log(error);
      }
    };
    getContactCall();
  }, [user?.token, count]);

  useEffect(() => {
    let isMounted = true;

    if (contact) {
      const mappedPayments = contact.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setcontactsDataSource(mappedPayments);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [contact, contact.length, count]);

  return (
    <div>
      <Table
        columns={columns(count, setcount, user?.token)}
        dataSource={contactsDataSource}
        loading={loading}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default ContactUsPage;
