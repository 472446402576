/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
import { stateToHTML } from 'draft-js-export-html';
import UesrContext from '../../contexts/user-context/UserProvider';
import AboutContext from '../../contexts/aboutus-context/AboutUsProvider';
import './AboutForm.scss';
import createQuestion from '../../apis/questions/createQuestion';
import UploadQuestionFile from './UploadQuestionFile';

import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import createAbout from '../../apis/about-us-api/createAbout';
import getFeatureDetailsApi from '../../apis/about-us-api/getFeatureDetailsApi';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';

const defaultValues = {
  title: '',
  body: '',
  detailsIds: [],
  service_imgs: null
};
const AboutUsForm = () => {
  const [descArEditorState, setDescArEditorState] = useState(EditorState.createEmpty());
  const [descEnEditorState, setDescEnEditorState] = useState(EditorState.createEmpty());
  const [titleArEditorState, settitleArEditorState] = useState(EditorState.createEmpty());
  const [titleEnEditorState, settitleEnEditorState] = useState(EditorState.createEmpty());
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setAboutModalOpened,
    allAboutData,
    setSelectedAboutId,
    selectedAboutId,
    selectedAbout,
    setSelectedAbout,
  } = useContext(AboutContext);

  let schema;
  if (selectedAbout && selectedAboutId) {
    schema = Yup.object().shape({
      // title: Yup.string().required('من فضلك ادخل اسم الخاصيه'),
      // title_en: Yup.string().required('من فضلك ادخل اسم الخاصيه بالانجليزي'),
      // body: Yup.string().test('body', 'من فضلك ادخل تفاصيل الخاصيه باللغة العربية', (v) => {
      //   let result = true;
      //   if (!v || v === '<p><br></p>') {
      //     result = false;
      //   }
      //   return result;
      // }),
      // body_en: Yup.string().test('body_en', 'من فضلك ادخل تفاصيل الخاصيه باللغة الانجليزية', (v) => {
      //   let result = true;
      //   if (!v || v === '<p><br></p>') {
      //     result = false;
      //   }
      //   return result;
      // }),
    });
  } else {
    schema = Yup.object().shape({
      // title: Yup.string().required('من فضلك ادخل اسم الخاصيه'),
      // title_en: Yup.string().required('من فضلك ادخل اسم الخاصيه بالانجليزي'),
      // // body: Yup.string().required('من فضلك ادخل وصف الخاصيه'),
      // // body_en: Yup.string().required('من فضلك ادخل وصف الخاصيه بالانجليزي'),
      // body: Yup.string().test('body', 'من فضلك ادخل تفاصيل الخاصيه باللغة العربية', (v) => {
      //   let result = true;
      //   if (!v || v === '<p><br></p>') {
      //     result = false;
      //   }
      //   return result;
      // }),
      // body_en: Yup.string().test('body_en', 'من فضلك ادخل تفاصيل الخاصيه باللغة الانجليزية', (v) => {
      //   let result = true;
      //   if (!v || v === '<p><br></p>') {
      //     result = false;
      //   }
      //   return result;
      // }),
      // service_imgs: Yup.mixed().required('من فضلك اختار الصورة')
    });
  }

  const [featureDetails, setFeatureDetails] = useState([]);
  console.log(featureDetails)
  

  useEffect(() => {
    const fetchFeatureDetails = async () => {
      try {
        const res = await getFeatureDetailsApi(user?.token);
        const data = res?.data?.data;
        console.log(data)
        if (Array.isArray(data)) {
          setFeatureDetails(data.map((d) => ({ title: d?.description, value: d?.id })));
        } else {
          console.error('Expected an array but received:', data);
          setFeatureDetails([]); // Fallback to an empty array
        }
      } catch (error) {
        console.log(error);
        setFeatureDetails([]); // Set to empty array in case of error
      }
    };

    fetchFeatureDetails();
  }, []);

  // useEffect(() => {
  //   if (selectedAboutId) {
  //     const foundData = allAboutData.find((d) => d.id === selectedAboutId);
  //     if (foundData) setSelectedAbout(foundData);
  //   }
  // }, [selectedAboutId]);
  // const [urls, setUrls] = useState([]);

  // useEffect(() => {
  //   if (selectedAbout?.image) {
  //     setUrls([selectedAbout?.image]);
  //   }
  // }, [selectedAbout]);

  const {
    control,

    setValue,
    getValues,
    handleSubmit,
    register,
    watch,
    setError,
    unregister,
    clearErrors,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const onSubmit = async (values) => {
    try {
      // console.log(values);
      // if (!getValues()?.body || getValues()?.body === '<p><br></p>') {
      //   setError('body', {
      //     type: 'required',
      //     message: 'من فضلك ادخل تفاصيل الخدمة باللغة العربية'
      //   });
      // } else {
      //   // setError('desc_ar', )
      //   clearErrors('body');
      // }

      let res;

      if (selectedAbout) {
        res = await createAbout(`/feature`, values, user?.token, selectedAbout.id, 2);
      } else {
        res = await createAbout('/feature', values, user?.token, 0, 2);
      }
      
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشــاء ميزه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });
        // setValue('body', '');
        // setValue('body_en', '');
        setDescArEditorState(EditorState.createEmpty());
        setDescEnEditorState(EditorState.createEmpty());

        settitleArEditorState(EditorState.createEmpty());
        settitleEnEditorState(EditorState.createEmpty());

        setAboutModalOpened(false);
        setSelectedAboutId('');
        setSelectedAbout(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم حفظ البيانات المدخلة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    setValue('body', stateToHTML(descArEditorState.getCurrentContent()));
  }, [stateToHTML(descArEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('body_en', stateToHTML(descEnEditorState.getCurrentContent()));
  }, [stateToHTML(descEnEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('title', stateToHTML(titleArEditorState.getCurrentContent()));
  }, [stateToHTML(titleArEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('title_en', stateToHTML(titleEnEditorState.getCurrentContent()));
  }, [stateToHTML(titleEnEditorState.getCurrentContent())]);

  // handle initial values
  useEffect(() => {
    if (selectedAboutId && selectedAbout) {
      const { title, body, body_en, title_en , icon , details} = selectedAbout;
      // setValue('title', title);
      // setValue('title_en', title_en);
      // setValue('body', body);
      // setValue('body_en', body_en);
      // setValue('is_required', String(is_required));
      if (icon) {
        setUrls([selectedAbout.icon]);
      }
      if (details) {
        // const detailsIds[] = details.map(item => item.id);
        setValue('detailsIds[]', details);
      }
      const contentBlockDescAr = htmlToDraft(body);
      if (contentBlockDescAr) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescAr.contentBlocks);
        const editorStateDescAr = EditorState.createWithContent(contentState);
        setDescArEditorState(editorStateDescAr);
      }
      const contentBlockDescEn = htmlToDraft(body_en);
      if (contentBlockDescEn) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescEn.contentBlocks);
        const editorStateDescEn = EditorState.createWithContent(contentState);
        setDescEnEditorState(editorStateDescEn);
      }

      const contentBlocktitleAr = htmlToDraft(title);
      if (contentBlocktitleAr) {
        const contentState = ContentState.createFromBlockArray(contentBlocktitleAr.contentBlocks);
        const editorStatetitleAr = EditorState.createWithContent(contentState);
        settitleArEditorState(editorStatetitleAr);
      }
      const contentBlocktitleEn = htmlToDraft(title_en);
      if (contentBlocktitleEn) {
        const contentState = ContentState.createFromBlockArray(contentBlocktitleEn.contentBlocks);
        const editorStatetitleEn = EditorState.createWithContent(contentState);
        settitleEnEditorState(editorStatetitleEn);
      }
    }
  }, [selectedAbout]);

  const [urls, setUrls] = useState([]);

  return (
    <Form
      // initialValues={selectedQuestion && selectedQuestionId ? { ...watch() } : defaultValues}
      // initialValues={{
      //   cat_id: '2'
      // }}
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body"><AntdSelectOption
            name="detailsIds[]"
            errorMsg={errors?.details?.message}
            validateStatus={errors?.details ? 'error' : ''}
            control={control}
            setValue={setValue}
            options={featureDetails?.length ? featureDetails : []}
            placeholder={' اختار الصلاحية الجديده'}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختار الصلاحية الجديده
              </p>
            }
            mode={true}
            formClassName="add-service-form"
          />
        

        <UploadQuestionFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="service_imgs"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الميزه
            </>
          }
          urls={urls}
          errorMsg={errors?.service_imgs ? errors?.service_imgs?.message : ''}
        />

        {/* <AntdTextField
          name="title"
          type="text"
          label="اكتب اسم الميزه"
          placeholder="اسم الميزه..."
          errorMsg={errors?.title?.message}
          validateStatus={errors?.title ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        <AntdTextField
          name="title_en"
          type="text"
          label="اكتب اسم الميزه بالانجليزي"
          placeholder="اسم الميزه..."
          errorMsg={errors?.title_en?.message}
          validateStatus={errors?.title_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        /> */}

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            اسم الخدمة باللغة العربية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={titleArEditorState}
            onEditorStateChange={(v) => {
              settitleArEditorState(v);
              if (!getValues()?.title || getValues()?.title === '<p><br></p>') {
                setError('title', {
                  type: 'required',
                  message: 'من فضلك ادخل اسم الخدمة باللغة العربية'
                });
              } else {
                // setError('title', )
                clearErrors('title');
              }
            }}
          />
          <p className="error-p">{errors?.title ? errors?.title.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            اسم الخدمة باللغة الانجليزية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={titleEnEditorState}
            // onBlur={(v) => console.log(v)}
            onEditorStateChange={(v) => {
              settitleEnEditorState(v);
              if (!getValues()?.title_en || getValues()?.title_en === '<p><br></p>') {
                setError('title_en', {
                  type: 'required',
                  message: 'من فضلك ادخل اسم الخدمة باللغة الانجليزية'
                });
              } else {
                // setError('title_en', )
                clearErrors('title_en');
              }
            }}
          />
          <p className="error-p">{errors?.title_en ? errors?.title_en.message : ''}</p>
        </div>

        {/* <AntdTextField
          name="body"
          type="text"
          label="اكتب وصف الميزه"
          placeholder="وصف الميزه..."
          errorMsg={errors?.body?.message}
          validateStatus={errors?.body ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        <AntdTextField
          name="body_en"
          type="text"
          label="اكتب وصف الميزه بالانجليزي"
          placeholder="وصف الميزه..."
          errorMsg={errors?.body_en?.message}
          validateStatus={errors?.body_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        /> */}

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            تفاصيل الخدمة باللغة العربية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descArEditorState}
            onEditorStateChange={(v) => {
              setDescArEditorState(v);
              if (!getValues()?.body || getValues()?.body === '<p><br></p>') {
                setError('body', {
                  type: 'required',
                  message: 'من فضلك ادخل تفاصيل الخدمة باللغة العربية'
                });
              } else {
                // setError('body', )
                clearErrors('body');
              }
            }}
          />
          <p className="error-p">{errors?.body ? errors?.body.message : ''}</p>
        </div>

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            تفاصيل الخدمة باللغة الانجليزية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descEnEditorState}
            // onBlur={(v) => console.log(v)}
            onEditorStateChange={(v) => {
              setDescEnEditorState(v);
              if (!getValues()?.body_en || getValues()?.body_en === '<p><br></p>') {
                setError('body_en', {
                  type: 'required',
                  message: 'من فضلك ادخل تفاصيل الخدمة باللغة الانجليزية'
                });
              } else {
                // setError('body_en', )
                clearErrors('body_en');
              }
            }}
          />
          <p className="error-p">{errors?.body_en ? errors?.body_en.message : ''}</p>
        </div>
        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          حفظ الميزه
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(watch(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AboutUsForm;
