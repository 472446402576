import axios from 'axios';

const getAllMerchantsStats = async (token, values) => {
  // console.log('vvsd', process.env.REACT_APP_BASE_URL);
  const url = `https://client-api.fatoorah.sa/apiAdmin/statisticsMerchant/home?from=${
    values?.from ? values?.from : ''
  }&to=${values?.to ? values?.to : ''}`;
  try {
    const response = await axios.get(url,
      {
        params: {
          from: values?.from,
          to: values?.to
        }
      },
      {
        // baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    // const response = await axios.get(
    //   `${url}/statisticsMerchant/home?from=${values?.from ? values?.from : ''}&to=${
    //     values?.to ? values?.to : ''
    //   }`,
    //   {
    //     params: {
    //       from: values?.from,
    //       to: values?.to
    //     }
    //   },
    //   {
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json',
    //       Authorization: 'Bearer ' + token
    //     }
    //   }
    // );

    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default getAllMerchantsStats;
