import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingSupscriptionStats: false,
  setLoadingSupscriptionStats: (load) => {},
  allSupscriptionStats: null,
  setAllSupscriptionStats: (supscriptionstats) => {},
  serviceModalOpened: false,
  setServiceModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedService: null,
  setSelectedService: (servKey) => {}
};

const SupscriptionStatsContext = createContext(INITIAL_VALUES);

export const SupscriptionStatsProvider = ({ children }) => {
  const [allSupscriptionStats, setAllSupscriptionStats] = useState(INITIAL_VALUES.allSupscriptionStats);
  const [loadingSupscriptionStats, setLoadingSupscriptionStats] = useState(INITIAL_VALUES.loadingSupscriptionStats);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [serviceModalOpened, setServiceModalOpened] = useState(INITIAL_VALUES.serviceModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedService, setSelectedService] = useState(INITIAL_VALUES.selectedService);

  return (
    <SupscriptionStatsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingSupscriptionStats,
        setLoadingSupscriptionStats,
        allSupscriptionStats,
        setAllSupscriptionStats,
        serviceModalOpened,
        setServiceModalOpened,
        selectedServId,
        setSelectedServId,
        selectedService,
        setSelectedService
      }}>
      {children}
    </SupscriptionStatsContext.Provider>
  );
};

export default SupscriptionStatsContext;
