/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Checkbox, Form, Select } from 'antd';
import { store } from 'react-notifications-component';
import { stateToHTML } from 'draft-js-export-html';
import UesrContext from '../../contexts/user-context/UserProvider';
import './AddNotificationForm.scss';

import { EditorState, ContentState, convertFromRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import createBlog from '../../apis/blog-api/createBlog';
import editBlog from '../../apis/blog-api/editBlog';
import NotificationCenterContext from '../../contexts/notification-center-context/NotificationCenterProvider';
import createNotification from '../../apis/Notification/createNotification';
import getAllFatorah from '../../apis/fatorah-apis/getAllFatorah';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';

const options = [
  {
    label: 'رسالة نصية',
    value: 'sms'
  },
  {
    label: 'بريد الكتروني',
    value: 'email'
  },
  {
    label: ' اشعارات',
    value: 'push'
  }
];

const defaultValues = {
  subject: '',
  body: '',
  customer: '',
  notificationType: []
};
const AddNotificationForm = () => {
  const { setNotificationCenterModalOpened, notificationCenterModalOpened } =
    useContext(NotificationCenterContext);
  const onChange = (checkedValues) => {};

  const { allcustomer } = useContext(CustomerContext);

  const { setFetchCount } = useContext(NotificationCenterContext);

  const [selectDisabled, setSelectDisabled] = useState(false);
  const [searchByVal, setSearchByVal] = useState(0);

  const [descArEditorState, setDescArEditorState] = useState(EditorState.createEmpty());
  const { user } = useContext(UesrContext);

  let schema = Yup.object().shape({
    subject: Yup.string().required('من فضلك ادخل  الموضوع   '),
    body: Yup.string().test('body', 'من فضلك ادخل المحتوي   ', (v) => {
      let result = true;
      if (!v || v === '<p><br></p>') {
        result = false;
      }
      return result;
    })
  });

  const {
    control,

    setValue,
    getValues,
    handleSubmit,
    register,
    watch,
    setError,
    unregister,
    clearErrors,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (values) => {
    try {
      // let modifiedData = { title: values.subject, body: values.body, photo: values.service_imgs[0], img_desc: values.img_desc }
      // res = await createBlog('/blog-store', modifiedData, user?.token);
      let res = await createNotification('/notifications', values, user?.token);

      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشــاء ميزه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });
        setDescArEditorState(EditorState.createEmpty());
        setNotificationCenterModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء اشعار  ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    setValue('body', stateToHTML(descArEditorState.getCurrentContent()));
  }, [stateToHTML(descArEditorState.getCurrentContent())]);

  const handleSelectChange = (value) => {
    if (value.includes('all')) {
      setValue('customer', '0');
      setSelectDisabled(true);
    } else {
      setValue('customer', value);
    }
  };

  const handcheckChange = (value) => {
    setValue('notificationType', value);
  };

  const onSearch = (value) => {};

  const handleSearchByChange = (value) => {
    setSearchByVal(value);
  };

  return (
    <Form
      className="add-notification-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <Form.Item
          name="notificationType"
          rules={[
            {
              required: true,
              message: '   من فضلك   ادخل نوع الاشعارات !'
            }
          ]}>
          <Checkbox.Group options={options} onChange={handcheckChange} />
        </Form.Item>
        <Select
          className="customer-filter-select"
          defaultValue="0"
          style={{ width: 162, marginBottom: '10px' }}
          size="large"
          onChange={handleSearchByChange}>
          <Select.Option value="0"> البحث بالبريد </Select.Option>
          <Select.Option value="1"> البحث بالاسم </Select.Option>
        </Select>
        <Form.Item
          className="client_item"
          label="العميل"
          name="customer"
          rules={[
            {
              required: true,
              message: '   من فضلك ادخل  العميل !'
            }
          ]}>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: '100%'
            }}
            placeholder="اختر العميل"
            onChange={handleSelectChange}
            disabled={selectDisabled}
            onSearch={onSearch}
            optionFilterProp="children"
            className="customer_select">
            <Select.Option value="all">الكل</Select.Option>

            {allcustomer &&
              allcustomer.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {searchByVal ? item.name : item.email}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <AntdTextField
          name="subject"
          type="text"
          label="    الموضوع "
          placeholder="    الموضوع..."
          errorMsg={errors?.subject?.message}
          validateStatus={errors?.subject ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
          className="client_item"
        />

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            المحتوي
          </p>
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descArEditorState}
            onEditorStateChange={(v) => {
              setDescArEditorState(v);

              if (!getValues()?.body || getValues()?.body === '<p><br></p>') {
                setError('body', {
                  type: 'required',
                  message: 'من فضلك ادخل المحتوي   '
                });
              } else {
                clearErrors('body');
              }
            }}
          />
          <p className="error-p">{errors?.body ? errors?.body.message : ''}</p>
        </div>

        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          اضافة اشعار
        </Button>
      </div>
    </Form>
  );
};

export default AddNotificationForm;
