/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddPackageForm.scss';
import UploadPackageFile from './UploadPackageFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createPackage from '../../apis/Packages/createPackage';
import UesrContext from '../../contexts/user-context/UserProvider';
import PackagesContext from '../../contexts/Packages-context/PackagesProvider';
import UploadActivePackageFile from './UploadActivePackageFile';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import getAllQuestions from '../../apis/questions/getAllQuestions';

const defaultValues = {
  icon: null,
  icon_active: null,
  name: '',
  price: '',
  description: '',
  name_en: '',
  price_en: '',
  description_en: '',
  extra: '',
  extra_en: '',
  details : [],
  monthly_price_ar: '',
  monthNumber: '',
  monthly_price_en: ''
};

const AddPackageForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setPackageModalOpened,
    allPackages,
    setSelectedServId,
    selectedServId,
    selectedPackage,
    setSelectedPackage
  } = useContext(PackagesContext);
  const [features, setFeatures] = useState([]);
  useEffect(() => {
    const getServices = async () => {
      try {
        const getServicesRes = await getAllQuestions(user?.token, 2);
        if (getServicesRes.status === 200 && getServicesRes?.data?.data) {
          const newArray = getServicesRes?.data?.data?.map(item => {
            return { id: item.id, value: item.id, title: item.title };
          });
          setFeatures(newArray);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();
  }, []);
  let schema;

  if (selectedPackage && selectedServId) {
    schema = Yup.object().shape({
      name: Yup.string().required('الأسم مطلوب'),
      price: Yup.number().typeError('لا يسمح الا بالارقــام').required('السعر مطلوب'),
      monthly_price_ar: Yup.number().typeError('لا يسمح الا بالارقــام').required('السعر الشهرى مطلوب'),
      monthly_price_en: Yup.number().typeError('لا يسمح الا بالارقــام').required('عدد شهور الباقة مطلوب').min(0 , 'يجب ان يكون أكبر من صفر'),
      monthNumber: Yup.number().typeError('لا يسمح الا بالارقــام').required('عدد شهور الباقة مطلوب').min(0 , 'يجب ان يكون أكبر من صفر'),
      description: Yup.string().required('الوصف مطلوب'),
      price_en: Yup.number().typeError('لا يسمح الا بالارقــام').required('السعر بالانجليزي  مطلوب'),
      name_en: Yup.string().required('الأسم  بالانجليزي مطلوب'),
      // offer_price: Yup.string(),
      description_en: Yup.string().required('الوصف   بالانجليزي مطلوب')
      // extra: Yup.string(),
      // extra_en: Yup.string()
    });
  } else {
    schema = Yup.object().shape({
      icon: Yup.mixed().required('من فضلك اختار صورة الباقه'),
      icon_active: Yup.mixed().required('من فضلك اختار صورة الباقه المختاره'),
      name: Yup.string().required('الأسم مطلوب'),
      description: Yup.string().required('الوصف مطلوب'),
      name_en: Yup.string().required('الأسم  بالانجليزي مطلوب'),
      price: Yup.number().typeError('لا يسمح الا بالارقــام').required('السعر مطلوب'),
      monthly_price_ar: Yup.number().typeError('لا يسمح الا بالارقــام').required('السعر الشهرى مطلوب'),
      monthly_price_en: Yup.number().typeError('لا يسمح الا بالارقــام').required('عدد شهور الباقة مطلوب').min(0 , 'يجب ان يكون أكبر من صفر'),
      monthNumber: Yup.number().typeError('لا يسمح الا بالارقــام').required('عدد شهور الباقة مطلوب').min(0 , 'يجب ان يكون أكبر من صفر'),
      price_en: Yup.number().typeError('لا يسمح الا بالارقــام').required('السعر بالانجليزي  مطلوب'),
      // offer_price: Yup.string(),
      description_en: Yup.string().required('الوصف  بالانجليزي مطلوب')
      // extra: Yup.string(),
      // extra_en: Yup.string()
    });
  }
  
  
  useEffect(() => {
    if (selectedServId) {
      const foundPackage = allPackages.find((serv) => serv.id === selectedServId);
      if (foundPackage) setSelectedPackage(foundPackage);
    }
  }, [selectedServId]);

  const [urls, setUrls] = useState([]);
  const [urlsActive, seturlsActive] = useState([]);
  useEffect(() => {
    if (selectedPackage?.icon) {
      
      setUrls([selectedPackage.icon]);
    }
    if (selectedPackage?.icon_active) {
      
      seturlsActive([selectedPackage.icon_active]);
    }
  }, [selectedPackage]);
  
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (data) => {
    // console.log(selectedPackage);
    try {
      let res;
      if (selectedPackage) {
        res = await createPackage(`/package`, data, user?.token, selectedPackage.id);
      } else {
        res = await createPackage('/package', data, user?.token, 0);
      }
      // console.log('create Package response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء الدفع',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setPackageModalOpened(false);
        setSelectedServId('');
        setSelectedPackage(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء الدفع المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedPackage && selectedServId) {
      const {
        name,
        price,
        description,
        name_en,
        price_en,
        description_en,
        extra,
        extra_en,
        offer_price,
        is_offer,
        details,
        packageFeatureDetails,
        monthly_price,
        monthly_price_ar,
        monthly_price_en,
        monthNumber
      } = selectedPackage;
      console.log(selectedPackage)
      setValue('name', name);
      setValue('price', price);
      setValue('monthly_price', monthly_price);
      setValue('monthly_price_ar', monthly_price_ar);
      setValue('monthly_price_en', monthly_price_en);
      setValue('monthNumber', monthNumber);
      setValue('description', description);
      setValue('name_en', name_en);
      setValue('price_en', price_en);
      setValue('description_en', description_en);
      setValue('extra', extra);
      setValue('extra_en', extra_en);
      setValue('offer_price', offer_price);
      setValue('is_offer', String(is_offer));
      setValue('details', packageFeatureDetails);
    }
  }, [selectedPackage, selectedServId]);

  return (
    <Form
      className="add-Package-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <UploadPackageFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="icon"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الباقه
            </>
          }
          urls={urls}
          errorMsg={errors?.icon ? errors?.icon?.message : ''}
        />
        <UploadActivePackageFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="icon_active"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الباقه المختاره
            </>
          }
          urls={urlsActive}
          errorMsg={errors?.icon ? errors?.icon?.message : ''}
        />
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الأسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="description"
            type="text"
            placeholder="الوصف..."
            errorMsg={errors?.description?.message}
            validateStatus={errors?.description ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
          <AntdSelectOption
            name="details"
            errorMsg={errors?.details?.message}
            validateStatus={errors?.details ? 'error' : ''}
            control={control}
            setValue={setValue}
            options={features?.length ? features : []}
            placeholder={'اختر المميزات'}
            label={
              <p className="select-option-label">
                <EditOutlined />
                اختر المميزات
              </p>
            }
            mode={true}
            formClassName="add-Package-form"
          />
          {/* <AntdTextField
            name="extra"
            type="text"
            placeholder="مميزات ..."
            errorMsg={errors?.extra?.message}
            validateStatus={errors?.extra ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          /> */}
        <div className="form-field-wrapper">
          <AntdTextField
            name="monthNumber"
            type="text"
            placeholder="أدخل عدد شهور الباقة..."
            errorMsg={errors?.monthNumber?.message}
            validateStatus={errors?.monthNumber ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
            label={
              <p className="select-option-label">
                <EditOutlined />
                عدد شهور الباقة
              </p>
            }
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="monthly_price_ar"
            type="text"
            placeholder="أدخل سعر الباقة الشهرى..."
            errorMsg={errors?.monthly_price_ar?.message}
            validateStatus={errors?.monthly_price_ar ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
            label={
              <p className="select-option-label">
                <EditOutlined />
                سعر الباقة الشهرى
              </p>
            }
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="monthly_price_en"
            type="text"
            placeholder="أدخل سعر الباقة الشهرى..."
            errorMsg={errors?.monthly_price_en?.message}
            validateStatus={errors?.monthly_price_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
            label={
              <p className="select-option-label">
                <EditOutlined />
                سعر الباقة الشهرى بالانجليزي
              </p>
            }
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="price"
            type="text"
            placeholder="السعر..."
            errorMsg={errors?.price?.message}
            validateStatus={errors?.price ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="price_en"
            type="text"
            placeholder="السعر بالانجليزي..."
            errorMsg={errors?.price_en?.message}
            validateStatus={errors?.price_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <AntdSelectOption
          name="is_offer"
          errorMsg={errors?.is_offer?.message}
          validateStatus={errors?.is_offer ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'يوجد خصم', value: '1' },
            { title: 'لا يوجد خصم', value: '0' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              الخصم
            </p>
          }
          formClassName="add-Package-form"
        />
        <div className="form-field-wrapper">
          <AntdTextField
            name="offer_price"
            type="text"
            placeholder="السعر بعد الخصم..."
            errorMsg={errors?.offer_price?.message}
            validateStatus={errors?.offer_price ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="name_en"
            type="text"
            placeholder="الأسم  بالانجليزي..."
            errorMsg={errors?.name_en?.message}
            validateStatus={errors?.name_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="description_en"
            type="text"
            placeholder=" الوصف  بالانجليزي..."
            errorMsg={errors?.description_en?.message}
            validateStatus={errors?.description_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="extra_en"
            type="text"
            placeholder="مميزات بالانجليزي..."
            errorMsg={errors?.extra_en?.message}
            validateStatus={errors?.extra_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          {selectedPackage ? 'تعديل الباقه' : 'اضافة الباقه'}
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddPackageForm;
