import { Button, Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import getOtpUser from '../../apis/otp-user-api/getOtpUser';
import UesrContext from '../../contexts/user-context/UserProvider';
import EditUserForm from './EditUserForm';
import OtpUserColums from './OtpUserColums';
import { PlusOutlined } from '@ant-design/icons';
import './OtpUser.scss';
const OtpUserPage = () => {
  const [CustomerUserData, setCustomerUserData] = useState([]);
  const [count, setcount] = useState([]);
  const [loading, setloading] = useState(false);
  const [reloadingData, setReloadingData] = useState(true);
  const [targetClient, setTargetClient] = useState(null);
  // console.log(targetClient);
  const [adminModalOpened, setAdminModalOpened] = useState(false);
  const [customerUserDataSource, setCustomerUserDataSource] = useState([]);

  const { user } = useContext(UesrContext);
  // console.log(user.token);

  useEffect(() => {
    if (reloadingData) {
      const getCustomerUserCall = async () => {
        try {
          setloading(true);
          const fetchedData = await getOtpUser(user?.token);
          // console.log(fetchedData);
          if (!fetchedData) {
            setloading(false);
          } else {
            setloading(false);
            setCustomerUserData(fetchedData);
          }
        } catch (error) {
          setloading(false);
          store.addNotification({
            title: 'حدث خطأ ',
            message: 'حاول فى وقت لاحق',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 2000,
              showIcon: true,
              onScreen: true
            }
          });
          console.log(error);
        }
      };
      getCustomerUserCall();
      setReloadingData(false);
    }
  }, [user?.token, count, reloadingData]);
  // console.log(count);

  useEffect(() => {
    let isMounted = true;

    if (CustomerUserData) {
      const mappedPayments = CustomerUserData.map((serv, index) => ({
        ...serv,
        key: index + 1
      }));
      if (isMounted) {
        setCustomerUserDataSource(mappedPayments);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [CustomerUserData, CustomerUserData.length, count]);

  return (
    <div className="customer-page">
      <Button
        onClick={(e) => {
          // console.log(e);
          setAdminModalOpened(true);
        }}
        className="otp-add-about-btn"
        type="primary"
        icon={<PlusOutlined />}>
        <span className="btn-text">اضافة</span>
      </Button>
      <Table
        className="customer-table"
        columns={OtpUserColums(
          count,
          setcount,
          user?.token,
          setAdminModalOpened,
          user,
          setTargetClient,
          setReloadingData
        )}
        dataSource={customerUserDataSource}
        loading={loading}
        scroll={{ x: 1200 }}
      />
      {adminModalOpened && (
        <Modal
          className="add-Admin-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={targetClient?.phone ? 'تعديل' : 'اضافة'}
          visible={adminModalOpened}
          onOk={() => {
            setAdminModalOpened(false);
            setTargetClient(null);
          }}
          onCancel={() => {
            setAdminModalOpened(false);
            setTargetClient(null);
          }}
          footer={false}>
          <EditUserForm
            {...{ setTargetClient, targetClient, setAdminModalOpened, setReloadingData }}
          />
        </Modal>
      )}
    </div>
  );
};

export default OtpUserPage;
