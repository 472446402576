import axios from 'axios';

const deleteContact = async (token, id) => {
  // console.log(token)
  // console.log(id);
  try {
    const response = await axios.post(`/clients/${id}/delete`, id, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    // console.log(response);

    if (response.status === 200 && response.data.response_code === 200) {
      // console.log(response.data);

      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteContact;
