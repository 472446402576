/* eslint-disable react-hooks/exhaustive-deps */
import { Tag } from 'antd';
import React, { useContext, useEffect } from 'react';
import AnswersContext from '../../contexts/answers-context/AnswersProvider';
import AnswersDataRender from './AnswersDataRender';
import './AnswersMoalContent.scss';

const AnswersModalContent = () => {
  const { selectedAnswerId, selectedAnswer, setSelecteAnswer, allAnswers } =
    useContext(AnswersContext);

  useEffect(() => {
    if (selectedAnswerId) {
      const foundAnswer = allAnswers.find((ques) => ques.id === selectedAnswerId);
      if (foundAnswer) setSelecteAnswer(foundAnswer);
    }
  }, [selectedAnswerId]);

  

  return (
    <div className="answers-modal-content">
      <div className="person-data">
        <div className="data-desc-wrapper">
          <div className="person-name">
            <p className="title-span">اسم صاحب الطلب : </p>
            <p className="name-p">
              <span className="name-span">{selectedAnswer?.firstName}</span>
              <span> </span>
              <span className="name-span">{selectedAnswer?.lastName}</span>
            </p>
          </div>
          <div className="person-phone">
            <p>رقم الهاتف : </p>
            <p className="phone-p">{selectedAnswer?.phone}</p>
          </div>
          <div className="person-phone">
            <p>البريد الاكترونى : </p>
            <p className="phone-p">{selectedAnswer?.email}</p>
          </div>
          <div className="project-status">
            <p>حالــة الاستبيــان : </p>
            {String(selectedAnswer?.status) === '1' ? (
              <Tag className="status-tag" color="#ff0040">
                لم يتم الاجابة على الاستبيان
              </Tag>
            ) : (
              <Tag className="status-tag" color="#87d068">
                تم الاجابة على الاستبيــان
              </Tag>
            )}
          </div>
          <div className="project-cat">
            <p>الخدمــة التابعة لهذا الطلــب : </p>
            <Tag className="cat-tag" color="#ff9800">
              {selectedAnswer?.cat?.name}
            </Tag>
          </div>
        </div>
        <div className="project-desc">
          <p className="desc-title">تفاصيل المشـروع المطلوب : </p>
          <div className="desc-content">
            <p>{selectedAnswer?.projectDesc}</p>
          </div>
        </div>
      </div>

      <AnswersDataRender answers={selectedAnswer?.answers} />
    </div>
  );
};

export default AnswersModalContent;
