import { Button, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';

// import deletePackageApi from '../../apis/Packages/deletePackage';
// import { store } from 'react-notifications-component';

const PackagesColumns = (
  token,
  setFetchCount,
  setLoadingPackages,
  setPackageModalOpened,
  setSelectedServId
) => {
  // const handleDeleteRequest = async (key) => {
  //   try {
  //     setLoadingPackages(true);
  //     const res = await deletePackageApi(key, token);
  //     if (res?.status === 200 && res?.data?.response_code === 200) {
  //       setFetchCount((prevState) => prevState + 1);
  //       setLoadingPackages(false);
  //       store.addNotification({
  //         title: 'العملية تمت بنجاح',
  //         message: 'تم حذف الخدمة بنجاح',
  //         type: 'success',
  //         insert: 'top',
  //         container: 'top-right',
  //         animationIn: ['animate__animated', 'animate__fadeIn'],
  //         animationOut: ['animate__animated', 'animate__fadeOut'],
  //         dismiss: {
  //           duration: 2000,
  //           showIcon: true,
  //           onScreen: true
  //         }
  //       });
  //     } else {
  //       setLoadingPackages(false);
  //       store.addNotification({
  //         title: 'حدث خطأ ',
  //         message: res?.data?.message || 'حاول فى وقت لاحق',
  //         type: 'danger',
  //         insert: 'top',
  //         container: 'top-right',
  //         animationIn: ['animate__animated', 'animate__fadeIn'],
  //         animationOut: ['animate__animated', 'animate__fadeOut'],
  //         dismiss: {
  //           duration: 2000,
  //           showIcon: true,
  //           onScreen: true
  //         }
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     setLoadingPackages(false);
  //     store.addNotification({
  //       title: 'حدث خطأ ',
  //       message: 'حاول فى وقت لاحق',
  //       type: 'danger',
  //       insert: 'top',
  //       container: 'top-right',
  //       animationIn: ['animate__animated', 'animate__fadeIn'],
  //       animationOut: ['animate__animated', 'animate__fadeOut'],
  //       dismiss: {
  //         duration: 2000,
  //         showIcon: true,
  //         onScreen: true
  //       }
  //     });
  //   }
  // };

  const handleEditPackage = (key) => {
    setPackageModalOpened(true);
    setSelectedServId(key);
    
  };

  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'صورة الباقه',
      dataIndex: 'icon',
      width: 100,
      render: (_, record) => {
        return (
          <div className="Package-img">
            <img
              src={`${process.env.REACT_APP_BASE_IMG_URL}/${record.icon}`}
              alt={record.id}
              width="100"
            />
          </div>
        );
      }
    },
    {
      title: 'صورة الباقه المختاره',
      dataIndex: 'icon_active',
      width: 100,
      render: (_, record) => {
        return (
          <div className="Package-img">
            <img
              src={`${process.env.REACT_APP_BASE_IMG_URL}${record.icon_active}`}
              alt={record.id}
              width="100"
            />
          </div>
        );
      }
    },
    {
      title: 'الأسم',
      dataIndex: 'name',
      width: 62
    },
    {
      title: 'السعر',
      dataIndex: 'price',
      width: 62
    },
    {
      title: 'تعديل الباقه',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الباقه">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditPackage(record.key)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    }
    // {
    //   title: 'حذف الدفع',
    //   dataIndex: 'delete',
    //   width: 92,
    //   render: (_, record) => {
    //     return (
    //       <Popconfirm
    //         title={
    //           <div style={{ maxWidth: '200px' }}>
    //             <p>هل انت متأكد من حذف الدفع</p>
    //           </div>
    //         }
    //         okText="نعم"
    //         cancelText="لا"
    //         onConfirm={(key) => handleDeleteRequest(record.key)}>
    //         <Tooltip title="حذف الدفع؟">
    //           <Button
    //             className="delete-btn"
    //             size="large"
    //             type="dashed"
    //             shape="circle"
    //             icon={<DeleteOutlined />}
    //           />
    //         </Tooltip>
    //       </Popconfirm>
    //     );
    //   }
    // }
  ];
};

export default PackagesColumns;
