import axios from 'axios';

const createPackage = async (url, values, token, id) => {
  try {
    const {
      icon,
      icon_active,
      name,
      description,
      price,
      name_en,
      description_en,
      price_en,
      offer_price,
      extra,
      extra_en,
      is_offer,
      details,
      monthNumber,
      monthly_price_ar,
      monthly_price_en
    } = values;
    console.log(details);
    const pickedValues = {
      name,
      description,
      price,
      name_en,
      description_en,

      price_en,
      is_offer
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    details.forEach((id, index) => {
      console.log(id, index)
      formData.append(`details[${index}][detail_id]`, id);
    });
    if (id !== 0) {
      formData.append('id', id);
    }
    if (offer_price) {
      formData.append('offer_price', offer_price);
    }
    if (extra) {
      formData.append('extra', extra);
    }
    if (monthly_price_ar) {
      formData.append('monthly_price_ar', monthly_price_ar);
    }
    if (monthly_price_en) {
      formData.append('monthly_price_en', monthly_price_en);
    }
    if (monthNumber) {
      formData.append('monthNumber', monthNumber);
    }
    if (extra_en) {
      formData.append('extra_en', extra_en);
    }
    if (icon && icon[0]) {
      formData.append('icon', icon[0]);
    }
    if (icon_active && icon_active[0]) {
      formData.append('icon_active', icon_active[0]);
    }

    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createPackage;
