import axios from 'axios';

const deleteContact = async (id, token) => {
  try {
    const formData = new FormData();

    if (id) {
      formData.append('id', id);
    }

    const response = await axios.post('/contacts-delete', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    
    if (response.status === 200 && response.data.response_code === 200) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteContact;
