import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { store } from 'react-notifications-component';

import parse from 'html-react-parser';
import deleteQuestionApi from '../../apis/questions/deleteQuestion';
import deleteBlogApi from '../../apis/blog-api/deleteBlog';


const blogColumns = (
  token,
  setFetchCount,
  setLoadingBlogData,
  setBlogModalOpened,
  setSelectedBlogId,
  setBlogDetailsModalOpened,
  setSelectedBlog
) => {
  const handleDeleteRequest = async (key) => {
    // console.log(key)
    try {
      setLoadingBlogData(true);
      const res = await deleteBlogApi(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingBlogData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingBlogData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingBlogData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleViewBlogDetailsModal = (record) => {

    setSelectedBlog(record)
    setBlogDetailsModalOpened(true)
  }

  const handleEditService = (record) => {
    // console.log(record)
     setBlogModalOpened(true);
     setSelectedBlogId(record.key);
     setSelectedBlog(record)
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      key: 'id'
    },

    {
      title: 'عنوان المقالة ',
      dataIndex: 'title',
      key: 'title',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record.title }}></div>
        );
      }
    },
    {
      title: 'صورة المقالة',
      dataIndex: 'photo',
      width: 180,
      render: (_, record) => {

        return (
          <div className="service-img">
            <img
              src={record.photo}
              alt={record.id}
              width="100"
            />
          </div>
        );
      }
    },
    {
      title: ' اسم المستخدم ',
      dataIndex: 'user_id',
      key: 'user_id',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record.user_id }}></div>
        );
      }
    },
    {
      title: 'تفاصيل المقالة',
      dataIndex: 'details',
      width: 82,
      render: (_, record) => {
        return (<Tooltip title="تفاصيل المقالة">
          <Button
            className="edit-btn"
            onClick={() => handleViewBlogDetailsModal(record)}
            size="large"
            type="dashed"
            shape="circle"
            icon={<EyeOutlined />}
          />
        </Tooltip>);
      }
    },

    {
      title: 'تعديل المقالة',
      dataIndex: 'edit',
      width: 92,
      key: 'edit',
      render: (_, record) => {
        return (
          <Tooltip title="تعديل المقالة">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditService(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف المقالة',
      dataIndex: 'delete',
      width: 92,
      key: 'delete',
      render: (_, record) => {
        return (
          <Popconfirm
            title="هل أنت متأكد من حذف المقالة؟"
            onConfirm={(key) => { handleDeleteRequest(record.key) }}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف المقالة؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default blogColumns;
