import axios from 'axios';

const deleteProject = async (values, token) => {
  try {
    const response = await axios.post('/Project/delete/' + values, '', {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    
    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteProject;
