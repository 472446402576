/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddAdminForm.scss';
// import UploadAdminFile from './UploadAdminFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createAdmin from '../../apis/Admin/createAdmin';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AdminContext from '../../contexts/Admin-context/AdminProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import getAllPackages from '../../apis/Packages/getAllPackages';

const defaultValues = {
  email: '',
  password: ''
};

const AddAdminForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setAdminModalOpened,
    allAdmin,
    setSelectedServId,
    selectedServId,
    selectedAdmin,
    setSelectedAdmin
  } = useContext(AdminContext);

  // console.log(selectedAdmin);
  // console.log(selectedServId);
  useEffect(() => {
    // console.log(selectedServId);
    // console.log(allAdmin);
    if (selectedServId) {
      const foundAdmin = allAdmin.find((serv) => serv.id === selectedServId);
      // console.log(foundAdmin);
    }
  }, [selectedServId]);
  // console.log(selectedServId);

  let schema;
  if (selectedServId) {
    schema = Yup.object().shape({
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
      name: Yup.string().required('الاسم مطلوب'),
      discount: Yup.number()
        .typeError('لا يسمح الا بالارقــام')
        .required('الخصم مطلوب')
        .min(0, 'لا يسمح ان يكون اقل من 0')
        .max(100, 'لا يسمح ان يكون اكثر من 100'),

      my_ratio: Yup.number()
        .typeError('لا يسمح الا بالارقــام')
        .required('النسبه مطلوب')
        .min(0, 'لا يسمح ان يكون اقل من 0')
        .max(100, 'لا يسمح ان يكون اكثر من 100'),
      code: Yup.string().required('الكود مطلوب'),
      platform: Yup.string().required('المنصه مطلوب'),
      status: Yup.string().required('الحاله مطلوب'),
      subscription_type: Yup.string().required(' نوع الاشتراك مطلوب'),
      code_packages: Yup.array().required('إختيار الباقة مطلوب')
    });
  } else {
    schema = Yup.object().shape({
      password: Yup.string().required('الرقم السري مطلوب'),
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
      name: Yup.string().required('الاسم مطلوب'),
      discount: Yup.number()
        .typeError('لا يسمح الا بالارقــام')
        .required('الخصم مطلوب')
        .min(0, 'لا يسمح ان يكون اقل من 0')
        .max(100, 'لا يسمح ان يكون اكثر من 100'),

      my_ratio: Yup.number()
        .typeError('لا يسمح الا بالارقــام')
        .required('النسبه مطلوب')
        .min(0, 'لا يسمح ان يكون اقل من 0')
        .max(100, 'لا يسمح ان يكون اكثر من 100'),
      code: Yup.string().required('الكود مطلوب'),
      platform: Yup.string().required('المنصه مطلوب'),
      subscription_type: Yup.string().required(' نوع الاشتراك مطلوب'),

      status: Yup.string().required('الحاله مطلوب'),
      code_packages: Yup.array().required('إختيار الباقة مطلوب')
    });
  }

  // const [urls, setUrls] = useState([]);
  // useEffect(() => {
  //   if (selectedAdmin?.icon) {
  //     console.log('selected Admin: ', selectedAdmin);
  //     setUrls([selectedAdmin.icon]);
  //   }
  // }, [selectedAdmin]);

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    // register,
    watch,
    // unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [categories, setCategoires] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getAllPackages(user?.token);

        if (res?.length) {
          setCategoires(res.map((d) => ({ title: d?.name, value: d?.id })));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
  }, []);

  const onSubmit = async (data) => {
    // console.log(selectedAdmin);
    try {
      let res;
      if (selectedAdmin) {
        res = await createAdmin(`/Admin/update`, data, user?.token, selectedAdmin.id, 2);
      } else {
        res = await createAdmin('/Admin/create', data, user?.token, 0, 2);
      }
      // console.log('create Admin response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء مسوق',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setAdminModalOpened(false);
        setSelectedServId('');
        setSelectedAdmin(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء مسوق المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  // handle initial values
  useEffect(() => {
    if (selectedAdmin) {
      const {
        email,
        password,
        platform,
        code,
        phone,
        status,
        code_packages,
        subscription_type,
        name,
        my_ratio,
        discount
      } = selectedAdmin;
      setValue('email', email);
      setValue('password', password);
      setValue('platform', platform);
      setValue('code', code);
      setValue('phone', phone);
      setValue('status', String(status));
      setValue('code_packages', code_packages);
      setValue('subscription_type', String(subscription_type));
      setValue('name', name);
      setValue('my_ratio', my_ratio);
      setValue('discount', discount);
    }
  }, [selectedAdmin]);

  const generateCode = () => () => {
    let r = (Math.random() + 1).toString(36).substring(7);
    // console.log('random', r);

    setValue('code', r);
  };
  return (
    <Form
      className="add-Admin-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="password"
            type="password"
            placeholder="الرقم السري..."
            errorMsg={errors?.password?.message}
            validateStatus={errors?.password ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="discount"
            type="text"
            placeholder="نسبة الخصم..."
            errorMsg={errors?.discount?.message}
            validateStatus={errors?.discount ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="my_ratio"
            type="text"
            placeholder="نسبة المسوق..."
            errorMsg={errors?.my_ratio?.message}
            validateStatus={errors?.my_ratio ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper form-field-wrapper-div">
          <div>
            <AntdTextField
              name="code"
              type="text"
              placeholder="الكود..."
              errorMsg={errors?.code?.message}
              validateStatus={errors?.code ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
          <div>
            <button type="button" onClick={generateCode()}>
              انشأ الكود
            </button>
          </div>
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="platform"
            type="text"
            placeholder="المنصه..."
            errorMsg={errors?.platform?.message}
            validateStatus={errors?.platform ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <AntdSelectOption
          name="status"
          errorMsg={errors?.status?.message}
          validateStatus={errors?.status ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نشط', value: '1' },
            { title: 'غير نشط', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              حالة المسوق
            </p>
          }
          formClassName="add-Admin-form"
        />
        <AntdSelectOption
          name="subscription_type"
          errorMsg={errors?.subscription_type?.message}
          validateStatus={errors?.subscription_type ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'الكل ', value: '3' },
            { title: 'جملة', value: '1' },
            { title: 'خدمات', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              نوع الاشتراك
            </p>
          }
          formClassName="add-Admin-form"
        />
        <AntdSelectOption
          name="code_packages"
          errorMsg={errors?.code_packages?.message}
          validateStatus={errors?.code_packages ? 'error' : ''}
          control={control}
          setValue={setValue}
          // options={[
          //   { title: 'نشط', value: '1' },
          //   { title: 'غير نشط', value: '2' }
          // ]}
          options={categories?.length ? categories : []}
          placeholder={'اختر نوع الباقة'}
          label={
            <p className="select-option-label">
              <EditOutlined />
              اختر الباقات
            </p>
          }
          formClassName="add-Admin-form"
          mode={true}
        />
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}
        >
          {selectedAdmin ? 'تعديل مسوق' : 'إضافة مسوق'}
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddAdminForm;
