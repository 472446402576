import axios from 'axios';

const createPackageDetailes = async (url, values, token, id) => {
  try {
    const { icon, details, title, title_en } = values;
    // const pickedValues = { details, title };
    const formData = new FormData();

    formData.append('title_en', title_en);
    formData.append('title', title);

    // for (let i = 0; i < details.length; i++) {
    //   formData.append('details', JSON.stringify(details[i]));
    // }
    formData.append('details', JSON.stringify(details));

    if (id !== 0) {
      formData.append('id', id);
    }
    if (icon && icon[0]) {
      formData.append('icon', icon[0]);
    }

    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createPackageDetailes;
