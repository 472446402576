import React, { useContext, useRef } from 'react';
import { Button, Modal, Tabs, Tooltip } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import ReceiptDetailsComponent from './ReceiptDetailsComponent';
import PaymentLogsContext from '../../contexts/payment-logs-conrext/PaymentLogsProvider';
import './invoiceModalOpened.scss';

const InvoiceModalOpened = ({ selectedPaymentLogs, allPaymentLogsInfo }) => {
  const {
    setSelectedPaymentLogsId,
    setSelectedPaymentLogs,
    PaymentLogsInvoiceModalOpened,
    setPaymentLogsInvoiceModalOpened
  } = useContext(PaymentLogsContext);
  const a4Ref = useRef();

  const handlePrint = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 94px !important;
        height: 94px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border: 1px solid #888;
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      table > thead > tr > th {
        background-color: #eee !important;
        -webkit-print-color-adjust: exact;
        border-color: #888 !important;
      }
      .ant-table-content > table > tbody > tr > td{
        border-right: 1px solid #888 !important;
      }
      td{
        padding: 10px !important;
        border-right: 1px solid #888;
      }
      td:last-child{
        border-right:none !important;
      }
    `
  });

  const renderModalBody = () => {
    return (
      <ReceiptDetailsComponent
        ref={a4Ref}
        selectedRecipt={selectedPaymentLogs}
        allPaymentLogsInfo={allPaymentLogsInfo}
      />
    );
  };

  return (
    <div>
      (
      <Modal
        className="shared-custom-modal selected-inovice-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={
          <>
            {/* {renderModalTitle()} */}
            تفاصيل الفاتورة
            {/* <Tooltip title="طباعة الفاتورة"> */}
            <Tooltip title="">
              <Button
                onClick={handlePrint}
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </Tooltip>
          </>
        }
        visible={PaymentLogsInvoiceModalOpened}
        onOk={() => {
          setPaymentLogsInvoiceModalOpened(false);
          setSelectedPaymentLogsId('');
          setSelectedPaymentLogs(null);
        }}
        onCancel={() => {
          setPaymentLogsInvoiceModalOpened(false);
          setSelectedPaymentLogsId('');
          setSelectedPaymentLogs(null);
        }}
        footer={false}>
        {renderModalBody()}

        {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedRecipt, null, 2)}
      </pre> */}
      </Modal>
      )
    </div>
  );
};

export default InvoiceModalOpened;
