/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import AboutContext from '../../contexts/aboutus-context/AboutUsProvider';
import AboutTable from './AboutTable';
import AboutUsForm from './AboutUsForm';
import './AboutUsPage.scss';

const AboutUsPage = () => {
  const {
    aboutModalOpened,
    setAboutModalOpened,
    setSelectedAboutId,
    selectedAboutId,
    setSelectedAbout
  } = useContext(AboutContext);

  return (
    <div className="about-us-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setAboutModalOpened(true)}
          className="add-about-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة الميزه</span>
        </Button>

        {/* <ServicesFilter /> */}
      </div>

      <AboutTable />

      {aboutModalOpened && 
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedAboutId ? 'تعديل ميزة' : 'إضـافة ميزة جديدة'}
          visible={aboutModalOpened}
          onOk={() => {
            setAboutModalOpened(false);
            setSelectedAboutId('');
            setSelectedAbout(null);
          }}
          onCancel={() => {
            setAboutModalOpened(false);
            setSelectedAboutId('');
            setSelectedAbout(null);
          }}
          footer={false}
        >
          <AboutUsForm />
        </Modal>
      }
    </div>
  );
};

export default AboutUsPage;
