import { DeleteOutlined, EditOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { store } from 'react-notifications-component';
import deleteCustomerUser from '../../apis/customer-user-api/deleteCustomerUser';
import toggleClientUserStatusApi from '../../apis/customer-user-api/toggleClientUserStatusApi';

// const PopOverAnswers = ({ content, title }) => (
//   <Popover title={title} content={content}>
//     <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
//   </Popover>
// );

// const deleteProjectCall = async (data, count, setCount, token) => {
//   try {
//     const res = await deleteCustomerUser(data, token);
//     // console.log(res);
//     if (res.response_code === 200) {
//       setCount(count + 1);
//       store.addNotification({
//         title: 'العملية تمت بنجاح',
//         message: 'تم حذف  بنجاح',
//         type: 'success',
//         insert: 'top',
//         container: 'top-right',
//         animationIn: ['animate__animated', 'animate__fadeIn'],
//         animationOut: ['animate__animated', 'animate__fadeOut'],
//         dismiss: {
//           duration: 2000,
//           showIcon: true,
//           onScreen: true
//         }
//       });
//     } else {
//       store.addNotification({
//         title: 'حدث خطأ ',
//         message: res?.data?.message || 'حاول فى وقت لاحق',
//         type: 'danger',
//         insert: 'top',
//         container: 'top-right',
//         animationIn: ['animate__animated', 'animate__fadeIn'],
//         animationOut: ['animate__animated', 'animate__fadeOut'],
//         dismiss: {
//           duration: 2000,
//           showIcon: true,
//           onScreen: true
//         }
//       });
//     }
//   } catch (error) {
//     console.log(error);
//     store.addNotification({
//       title: 'حدث خطأ ',
//       message: 'حاول فى وقت لاحق',
//       type: 'danger',
//       insert: 'top',
//       container: 'top-right',
//       animationIn: ['animate__animated', 'animate__fadeIn'],
//       animationOut: ['animate__animated', 'animate__fadeOut'],
//       dismiss: {
//         duration: 2000,
//         showIcon: true,
//         onScreen: true
//       }
//     });
//   }
// };
// const handleEditUser = (key, funEdit) => {
//   console.log(key);
//   funEdit(true);
//   // setAdminModalOpened(true);

//   // setSelectedServId(key);
// };
// const handleToggleActive = async (record) => {
//   try {
//     // setLoadingcustomer(true);
//     const formData = new FormData();
//     formData.append('ref_key', record?.ref_key);
//     formData.append('status', record?.status == '1' ? 0 : 1);

//     let res = await toggleUserStatusApi(token, formData);
//     if (res.status === 200) {
//       // setLoadingcustomer(false);
//       // setFetchCount((prev) => prev + 1);
//       store.addNotification({
//         title: 'العملية تمت بنجاح',
//         message: 'تم تغيير الحالة',
//         type: 'success',
//         insert: 'top',
//         container: 'top-right',
//         animationIn: ['animate__animated', 'animate__fadeIn'],
//         animationOut: ['animate__animated', 'animate__fadeOut'],
//         dismiss: {
//           duration: 2000,
//           showIcon: true,
//           onScreen: true
//         }
//       });
//     } else {
//       // setLoadingcustomer(false);
//       // setFetchCount((prev) => prev + 1);
//       store.addNotification({
//         title: 'حدث خطأ ',
//         message: res?.data?.message || 'حاول فى وقت لاحق',
//         type: 'danger',
//         insert: 'top',
//         container: 'top-right',
//         animationIn: ['animate__animated', 'animate__fadeIn'],
//         animationOut: ['animate__animated', 'animate__fadeOut'],
//         dismiss: {
//           duration: 2000,
//           showIcon: true,
//           onScreen: true
//         }
//       });
//     }
//   } catch (error) {
//     // setFetchCount((prev) => prev + 1);
//     store.addNotification({
//       title: 'حدث خطأ ',
//       message: 'حاول فى وقت لاحق',
//       type: 'danger',
//       insert: 'top',
//       container: 'top-right',
//       animationIn: ['animate__animated', 'animate__fadeIn'],
//       animationOut: ['animate__animated', 'animate__fadeOut'],
//       dismiss: {
//         duration: 2000,
//         showIcon: true,
//         onScreen: true
//       }
//     });
//   }
// };
// const renderUserStatusBtn = (record) => {
//   if (record?.id === user?.id) {
//     return '---';
//   } else {
//     if (record?.status === statusTypes?.active)
//       return (
//         <button
//           onClick={() => {
//             handleToggleActive(record);
//           }}
//           className="active toggle-user-status-btn">
//           مفعل
//         </button>
//       );
//     else if (record?.status == statusTypes?.unactive) {
//       return (
//         <button
//           onClick={() => {
//             handleToggleActive(record);
//           }}
//           className="not-active toggle-user-status-btn">
//           غير مفعل
//         </button>
//       );
//     }
//   }
// };
// const columns = (projectCount, setProjectCount, token, setAdminModalOpened) => [
//   {
//     title: '#',
//     dataIndex: 'key',
//     key: 'key'
//   },
//   {
//     title: 'الأسم ',
//     dataIndex: 'name',
//     key: 'name'
//   },
//   {
//     title: 'رقم الهاتف',
//     dataIndex: 'phone',
//     key: 'phone'
//   },

//   {
//     title: 'البريد الالكتروني',
//     dataIndex: 'email',
//     key: 'email'
//   },
//   {
//     title: 'الحــالة',
//     dataIndex: 'userStatus',
//     render: (_, record) => {
//       return renderUserStatusBtn(record);
//     }
//   },
//   {
//     title: 'تعديل المسوق',
//     dataIndex: 'edit',
//     width: 92,
//     render: (_, record) => {
//       return (
//         <Tooltip title="تعديل البيانات">
//           <Button
//             className="edit-btn"
//             onClick={(key) => handleEditUser(record.key, setAdminModalOpened)}
//             size="large"
//             type="dashed"
//             shape="circle"
//             icon={<EditOutlined />}
//           />
//         </Tooltip>
//       );
//     }
//   },
//   {
//     title: 'حذف الرساله',
//     key: 'action',
//     render: (_, record) => {
//       return (
//         <Popconfirm
//           title={
//             <div style={{ maxWidth: '200px' }}>
//               <p>هل أنت متأكد من حذف ذلك الرساله</p>
//             </div>
//           }
//           okText="نعم"
//           cancelText="لا"
//           onConfirm={(key) => deleteProjectCall(record.key, projectCount, setProjectCount, token)}>
//           <Tooltip title="حذف الرساله؟">
//             <Button
//               className="delete-btn"
//               size="large"
//               type="dashed"
//               shape="circle"
//               icon={<DeleteOutlined />}
//             />
//           </Tooltip>
//         </Popconfirm>
//       );
//     }
//   }
//   // render: (_, record) => (
//   //   <div className="opation-btn">
//   //     <div className="delete">
//   //       <Popconfirm
//   //         title="هل أنت متأكد من حذف ذلك الرساله"
//   //         onConfirm={(key) => deleteProjectCall(record.key, projectCount, setProjectCount, token)}
//   //         okText="نعم"
//   //         cancelText="لا">
//   //         <Tooltip title="حذف الرساله">
//   //           <Button
//   //             className="delete-btn"
//   //             size="large"
//   //             type="dashed"
//   //             shape="circle"
//   //             icon={<DeleteOutlined />}
//   //           />
//   //         </Tooltip>
//   //         {/* <button>حذف</button> */}
//   //       </Popconfirm>
//   //     </div>
//   //   </div>
//   // )
// ];

// export default columns;
// const userRoles = {
//   manager: { type: 1, name: 'مدير' },
//   accountant: { type: 2, name: 'محاسب' },
//   partner: { type: 3, name: 'شريك' },
//   dataEntry: { type: 4, name: 'مدخل بيانات' },
//   posUser: { type: 5, name: 'موظف نقطة بيع' },
//   delegate: { type: 6, name: 'مندوب' }
// };
const userRoles = [
  { type: 1, name: 'مدير' },
  { type: 2, name: 'محاسب' },
  { type: 3, name: 'شريك' },
  { type: 4, name: 'مدخل بيانات' },
  { type: 5, name: 'موظف نقطة بيع' },
  { type: 6, name: 'مندوب' }
];
let handleRoleFun = (el_data) => {
  // console.log(el_data.role);
  let targetRole = userRoles.map((el) => {
    if (el.type == el_data.role) {
      return <div>{el.name}</div>;
    }
  });
  return targetRole;
};

const CustomerUserColums = (
  projectCount,
  setProjectCount,
  token,
  setAdminModalOpened,
  user,
  setTargetClient,
  setReloadingData
) => {
  // console.log(token);
  const statusTypes = {
    active: 1,
    unactive: 0
  };
  const PopOverAnswers = ({ content, title }) => (
    <Popover title={title} content={content}>
      <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
    </Popover>
  );
  const deleteProjectCall = async (key, record) => {
    try {
      const res = await deleteCustomerUser(token, record?.id);
      console.log(token);
      // console.log(res);
      if (res.response_code === 200) {
        setReloadingData(true);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف  بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setReloadingData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const handleEditUser = (key, funEdit, record) => {
    setTargetClient(record);
    // console.log(record);
    // console.log(key);
    funEdit(true);
    // setAdminModalOpened(true);
    // setSelectedServId(key);
  };
  const handleToggleActive = async (record) => {
    // console.log(record);
    try {
      // setLoadingcustomer(true);
      const formData = new FormData();
      formData.append('ref_key', record?.ref_key);
      formData.append('status', record?.status == '1' ? 0 : 1);
      let res = await toggleClientUserStatusApi(token, formData, record?.id);
      if (res.status === 200) {
        // setLoadingcustomer(false);
        setReloadingData(true);

        // setFetchCount((prev) => prev + 1);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تغيير الحالة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        // setLoadingcustomer(false);
        setReloadingData(false);
        // setFetchCount((prev) => prev + 1);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      // setFetchCount((prev) => prev + 1);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const renderUserStatusBtn = (record) => {
    // console.log(user?.id);

    // console.log(record.id);
    // console.log(record);

    if (record?.id === user?.id) {
      console.log(user);
      return '---';
    } else {
      if (record?.status === statusTypes?.active)
        return (
          <button
            style={{ margin: '0 auto' }}
            onClick={() => {
              handleToggleActive(record);
            }}
            className="active toggle-user-status-btn">
            مفعل
          </button>
        );
      else if (record?.status == statusTypes?.unactive) {
        return (
          <button
            style={{ margin: '0 auto' }}
            onClick={() => {
              handleToggleActive(record);
            }}
            className="not-active toggle-user-status-btn">
            غير مفعل
          </button>
        );
      }
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: ' اسم المستخدم',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      key: 'phone'
    },
    {
      title: 'البريد الالكتروني',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'صلاحيات المستخدم',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => {
        // console.log(record);
        return handleRoleFun(record);
      }
    },
    {
      title: 'الحــالة',
      dataIndex: 'userStatus',
      render: (_, record) => {
        return renderUserStatusBtn(record);
        // return (
        //   <button
        //     onClick={(e) => {
        //       console.log(e);
        //       // handleToggleActive(record);
        //     }}
        //     className="not-active toggle-user-status-btn">
        //     غير مفعل
        //   </button>
        // );
      }
    },
    {
      title: 'تعديل ',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        // console.log(record);
        return (
          <Tooltip title="تعديل البيانات">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditUser(record.key, setAdminModalOpened, record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف ',
      key: 'action',
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل أنت متأكد </p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => deleteProjectCall(record.key, record)}>
            <Tooltip title="حذف ">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
    // render: (_, record) => (
    //   <div className="opation-btn">
    //     <div className="delete">
    //       <Popconfirm
    //         title="هل أنت متأكد من حذف ذلك الرساله"
    //         onConfirm={(key) => deleteProjectCall(record.key, projectCount, setProjectCount, token)}
    //         okText="نعم"
    //         cancelText="لا">
    //         <Tooltip title="حذف الرساله">
    //           <Button
    //             className="delete-btn"
    //             size="large"
    //             type="dashed"
    //             shape="circle"
    //             icon={<DeleteOutlined />}
    //           />
    //         </Tooltip>
    //         {/* <button>حذف</button> */}
    //       </Popconfirm>
    //     </div>
    //   </div>
    // )
  ];
};

export default CustomerUserColums;
