import { Button, Popconfirm, Tooltip, Popover } from 'antd';
import { DeleteOutlined, EyeTwoTone } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import deleteDaftarApi from '../../apis/deleteDaftarApi';
const PopOverAnswers = ({ content, title }) => (
  <Popover title={title} content={content}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);
const daftarTableColumns = (
  token,
  setFetchCount,
  setLoadingDaftarData,
  setDaftarModalOpened,
  setSelectedDaftarId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingDaftarData(true);
      const res = await deleteDaftarApi(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingDaftarData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingDaftarData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingDaftarData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      key: 'id'
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      width: 180
    },

    {
      title: 'البريد الألكتروني',
      dataIndex: 'email',
      width: 180
    },
    {
      title: 'رقم الموبايل',
      dataIndex: 'phone',
      width: 180
    },
    {
      title: 'تفاصيل الطلب',
      dataIndex: 'note',
      width: 82,
      render: (v) => {
        return <PopOverAnswers content={v} title="تفاصيل الطلب" />;
      }
    },
    {
      title: 'حذف الطلب',
      dataIndex: 'delete',
      width: 92,
      key: 'delete',
      render: (_, record) => {
        return (
          <Popconfirm
            title="هل أنت متأكد من حذف الطلب؟"
            onConfirm={(key) => handleDeleteRequest(record.key)}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف الطلب؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default daftarTableColumns;
