import axios from 'axios';

const toggleUserStatusApi = async (token, values) => {
  try {
    const res = await axios.post('/customer/status', values, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default toggleUserStatusApi;
