import axios from 'axios';

const addProjectFeature = async (values, id, token) => {
  try {
    const { name_ar, name_en, icon } = values;
    const pickedValues = {
      name_ar,
      name_en,
      icon: icon[0]
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    formData.append('project_id', id);
    const response = await axios.post('/ProjectDetails/addFeature', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: 'Bearer ' + token
      }
    });

    // console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default addProjectFeature;
