import axios from 'axios';

const getAllDaftar = async (token, type) => {
  try {
    const getQuestions = await axios.get(`/AccountingRequest/all`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return getQuestions;
  } catch (error) {
    console.log(error);
  }
};

export default getAllDaftar;
