import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingPayments: false,
  setLoadingPayments: (load) => {},
  allPayments: [],
  setAllPayments: (Payments) => {},
  PaymentModalOpened: false,
  setPaymentModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedPayment: null,
  setSelectedPayment: (servKey) => {}
};

const PaymentsContext = createContext(INITIAL_VALUES);

export const PaymentsProvider = ({ children }) => {
  const [allPayments, setAllPayments] = useState(INITIAL_VALUES.allPayments);
  const [loadingPayments, setLoadingPayments] = useState(INITIAL_VALUES.loadingPayments);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [PaymentModalOpened, setPaymentModalOpened] = useState(INITIAL_VALUES.PaymentModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedPayment, setSelectedPayment] = useState(INITIAL_VALUES.selectedPayment);

  return (
    <PaymentsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingPayments,
        setLoadingPayments,
        allPayments,
        setAllPayments,
        PaymentModalOpened,
        setPaymentModalOpened,
        selectedServId,
        setSelectedServId,
        selectedPayment,
        setSelectedPayment
      }}>
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsContext;
