import axios from 'axios';

const getAllStats = async (token , values) => {
  // console.log('vvsd',values)
  try {
    const response = await axios.get(`/statistics?from=${values?.from?values?.from:""}&to=${values?.to?values?.to:""}`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    
    if (response.status === 200) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default getAllStats;
