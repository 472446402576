import axios from 'axios';

const getAllPosPackages = async (token) => {
  try {
    const response = await axios.get(`/package-pos/index`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    
    if (response.data.response_code === 200 && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default getAllPosPackages;
