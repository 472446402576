/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Button, Form } from 'antd';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import './AddProjectForm.scss';

import { EditOutlined, SendOutlined } from '@ant-design/icons';

import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import addProject from '../../apis/project-api/addProject';
import getAllServices from '../../apis/services/getAllServices';
import { store } from 'react-notifications-component';
import UploadProjectFile from './UploadProjectFile';
const schema = yup.object().shape({
  name_ar: yup.string().required('الأسم بالعربي مطلوب'),
  name_en: yup.string().required('الأسم بالانجليزي مطلوب'),
  desc_ar: yup.string().test('desc_ar', 'من فضلك ادخل تفاصيل الخدمة باللغة العربية', (v) => {
    let result = true;
    if (!v || v === '<p><br></p>') {
      result = false;
    }
    return result;
  }),
  desc_en: yup.string().test('desc_en', 'من فضلك ادخل تفاصيل الخدمة باللغة الانجليزية', (v) => {
    let result = true;
    if (!v || v === '<p><br></p>') {
      result = false;
    }
    return result;
  }),
  // appleLink: yup.string().required('رابط علي الايفون مطلوب'),
  // webLink: yup.string().required('رابط علي الاندرويد مطلوب'),
  // androidLink: yup.string().required('رابط علي الاندرويد مطلوب'),
  image_project: yup.array().min(1, 'من فضلك اختار صورة المشروع'),
  status: yup.string().required('مطلوب الحاله'),
  service_id: yup.string().required('الخدمه مطلوبه')
});

const defaultValues = {
  name_ar: '',
  name_en: '',
  desc_ar: '',
  desc_en: '',
  image_project: [],
  status: '',
  appleLink: '',
  webLink: '',
  androidLink: '',
  service_id: ''
};

const AddProjectForm = ({ count, setCount, setIsModalVisible }) => {
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors }
  // } = useForm({
  //   resolver: yupResolver(schema)
  // });
  const [descArEditorState, setDescArEditorState] = useState(EditorState.createEmpty());
  const [descEnEditorState, setDescEnEditorState] = useState(EditorState.createEmpty());

  const [urls, setUrls] = useState([]);

  const { user } = useContext(UesrContext);
  const [service, setService] = useState([]);
  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (data) => {
    

    try {
      const fetchedData = await addProject(data, user?.token);
      if (!fetchedData) {
        store.addNotification({
          title: 'حدث خطأ ',
          message: fetchedData?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم اضافه المشروع بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        setCount(count + 1);

        setIsModalVisible(false);
        reset({ ...defaultValues });
      }
    } catch (error) {
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    setValue('desc_ar', stateToHTML(descArEditorState.getCurrentContent()));
  }, [stateToHTML(descArEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('desc_en', stateToHTML(descEnEditorState.getCurrentContent()));
  }, [stateToHTML(descEnEditorState.getCurrentContent())]);

  useEffect(() => {
    const getServices = async () => {
      try {
        const res = await getAllServices(user?.token);
        if (res?.status && res?.data?.status === 1 && res?.data?.data) {
          const filteredGalleryServies = res.data.data.filter((d) => d.type === 2);
          setService(filteredGalleryServies);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getServices();
  }, [user?.token]);
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="add-project-form">
      <UploadProjectFile
        urls={urls}
        setUrls={setUrls}
        accept="image/png, image/jpg, image/jpeg, image/gif"
        maxFiles={1}
        name="image_project"
        setValue={setValue}
        watch={watch}
        register={register}
        unregister={unregister}
        multiple={false}
        label={
          <>
            <EditOutlined />
            صورة المشروع
          </>
        }
        errorMsg={errors?.image_project ? errors?.image_project?.message : ''}
      />
      <div className="form-field-wrapper">
        <AntdTextField
          name="name_ar"
          type="text"
          placeholder="اســم المشروع باللغة العربية..."
          errorMsg={errors?.name_ar?.message}
          validateStatus={errors?.name_ar ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
      </div>

      <div className="form-field-wrapper">
        <AntdTextField
          name="name_en"
          type="text"
          placeholder="اســم المشروع باللغة الانجليزيه..."
          errorMsg={errors?.name_en?.message}
          validateStatus={errors?.name_en ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
      </div>

      <div className="editor-wrapper">
        <p className="editor-title">
          <EditOutlined />
          تفاصيل المشروع باللغة العربية
        </p>
        <Editor
          toolbar={{
            options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
          }}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          editorState={descArEditorState}
          onEditorStateChange={(v) => {
            setDescArEditorState(v);
            if (!getValues()?.desc_ar || getValues()?.desc_ar === '<p><br></p>') {
              setError('desc_ar', {
                type: 'required',
                message: 'من فضلك ادخل تفاصيل المشروع باللغة العربية'
              });
            } else {
              // setError('desc_ar', )
              clearErrors('desc_ar');
            }
          }}
        />
        <p className="error-p">{errors?.desc_ar ? errors?.desc_ar.message : ''}</p>
      </div>

      <div className="editor-wrapper">
        <p className="editor-title">
          <EditOutlined />
          تفاصيل المشروع باللغة الانجليزية
        </p>
        <Editor
          toolbar={{
            options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
          }}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          editorState={descEnEditorState}
          // onBlur={(v) => console.log(v)}
          onEditorStateChange={(v) => {
            setDescEnEditorState(v);
            if (!getValues()?.desc_en || getValues()?.desc_en === '<p><br></p>') {
              setError('desc_en', {
                type: 'required',
                message: 'من فضلك ادخل تفاصيل المشروع باللغة الانجليزية'
              });
            } else {
              // setError('desc_en', )
              clearErrors('desc_en');
            }
          }}
        />
        <p className="error-p">{errors?.desc_en ? errors?.desc_en.message : ''}</p>
      </div>
      <AntdSelectOption
        name="status"
        errorMsg={errors?.status?.message}
        validateStatus={errors?.status ? 'error' : ''}
        control={control}
        setValue={setValue}
        options={[
          { title: 'نشط', value: '1' },
          { title: 'غير نشط', value: '2' }
        ]}
        label={
          <p className="select-option-label">
            <EditOutlined />
            حالة المشروع
          </p>
        }
        formClassName="add-project-form"
      />

      <AntdSelectOption
        name="service_id"
        errorMsg={errors?.service_id?.message}
        validateStatus={errors?.service_id ? 'error' : ''}
        control={control}
        setValue={setValue}
        options={
          service?.length > 0 &&
          service.map((serv) => ({
            title: serv.name_ar,
            value: serv.key
          }))
        }
        label={
          <p className="select-option-label">
            <EditOutlined />
            نوع الخدمه
          </p>
        }
        formClassName="add-project-form"
      />

      <div className="form-field-wrapper">
        <AntdTextField
          name="appleLink"
          type="text"
          placeholder="رابط المشروع علي ايفون..."
          errorMsg={errors?.appleLink?.message}
          validateStatus={errors?.appleLink ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
      </div>

      <div className="form-field-wrapper">
        <AntdTextField
          name="androidLink"
          type="text"
          placeholder="رابط المشروع علي اندرويد..."
          errorMsg={errors?.androidLink?.message}
          validateStatus={errors?.androidLink ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
      </div>

      <div className="form-field-wrapper">
        <AntdTextField
          name="webLink"
          type="text"
          placeholder="رابط المشروع علي الويب..."
          errorMsg={errors?.webLink?.message}
          validateStatus={errors?.webLink ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
      </div>

      <Button
        className="submit-btn"
        htmlType="submit"
        type="primary"
        icon={<SendOutlined />}
        loading={isSubmitting}>
        اضافة المشروع
      </Button>
    </Form>
  );
};

export default AddProjectForm;
