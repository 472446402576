// import { Button, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined, UserSwitchOutlined } from '@ant-design/icons';
// import { store } from 'react-notifications-component';
import { Link as RouterLink } from 'react-router-dom';
import { AddPos, deleteFatoorh } from '../../apis/fatorah-apis/deleteFatoorh';

import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import { store } from 'react-notifications-component';
import toggleUserStatusApi from '../../apis/customer/toggleUserStatusApi';
import './CustomerTable.scss';

const customerTableColumnsUsers = (
  type,
  setFetchCount,
  token,
  setSelectedQuestion,
  setQuestionModalOpened,
  setSelectedCustomer,
  setCustomerModalOpened,
  compoentType,
  user,
  setLoadingcustomer
) => {
  const statusTypes = {
    active: 1,
    unactive: 0
  };
  const handleAddRequest = async (key, phone, type) => {
    try {
      // setLoadingAboutData(true);
      const res = await AddPos(key, phone, token);
      if (res?.status === 200 && res?.data?.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        // setLoadingAboutData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم أضافه نقطه بيع بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        // setLoadingAboutData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      // setLoadingAboutData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const handleDeleteRequest = async (key, phone, type) => {
    try {
      // setLoadingAboutData(true);
      const res = await deleteFatoorh(key, phone, type, token);
      console.log(res?.status);
      console.log(res?.data?.status);
      if (res?.status === 200 && res?.data?.status === 1) {
        setFetchCount((prevState) => prevState + 1);
        // setLoadingAboutData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        // setLoadingAboutData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      // setLoadingAboutData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const handleEditRequest = async (key, record, type) => {
    setSelectedQuestion(record);
    setQuestionModalOpened(true);
  };
  const handleEditPassword = async (key, record, type) => {
    setSelectedCustomer(record);
    setCustomerModalOpened(true);
  };

  const handleToggleActive = async (record) => {
    try {
      // setLoadingcustomer(true);
      const formData = new FormData();
      formData.append('ref_key', record?.ref_key);
      formData.append('status', record?.status == '1' ? 0 : 1);

      let res = await toggleUserStatusApi(token, formData);
      if (res.status === 200) {
        // setLoadingcustomer(false);
        setFetchCount((prev) => prev + 1);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تغيير الحالة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        // setLoadingcustomer(false);
        setFetchCount((prev) => prev + 1);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setFetchCount((prev) => prev + 1);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const renderUserStatusBtn = (record) => {
    if (record?.id === user?.id) {
      return '---';
    } else {
      if (record?.status === statusTypes?.active)
        return (
          <button
            onClick={() => {
              handleToggleActive(record);
            }}
            className="active toggle-user-status-btn">
            مفعل
          </button>
        );
      else if (record?.status == statusTypes?.unactive) {
        return (
          <button
            onClick={() => {
              handleToggleActive(record);
            }}
            className="not-active toggle-user-status-btn">
            غير مفعل
          </button>
        );
      }
    }
  };

  // console.log('type',type)

  if (type === 2) {
    return [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key'
      },

      {
        title: 'نوع الباقه',
        dataIndex: 'package',
        key: 'package',
        render: (_, record) => {
          return record.package.name;
        }
      },
      {
        title: 'أجمالي المبلغ',
        dataIndex: 'total',
        key: 'total'
      },
      {
        title: ' الدفع',
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (_, record) => {
          // console.log(record);
          if (record?.package_id == 1) {
            return 'الدفع مجاني';
          } else {
            if (record.payment_status === 1) {
              return 'تم الدفع';
            } else {
              return 'لم يتم الدفع';
            }
          }
        }
      },
      {
        title: ' نوع الخدمه',
        dataIndex: 'subscriptionType',
        key: 'subscriptionType',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return 'صاحب عمل';
          } else if (record.subscriptionType === 2) {
            return 'مزود خدمه';
          }
        }
      },
      {
        title: ' اسم المسوق',
        dataIndex: 'influencer',
        key: 'influencer',
        render: (_, record) => {
          if (record.influencer) {
            return record.influencer.name;
          }
        }
      },
      {
        title: 'الرقم الضريبي',
        dataIndex: 'tax_number',
        key: 'tax_number'
      },
      {
        title: 'تاريخ البدايه',
        dataIndex: 'start_date',
        key: 'start_date'
      },
      {
        title: 'تاريخ النهايه',
        dataIndex: 'end_date',
        key: 'end_date'
      },
      {
        title: 'عدد نقاط البيع',
        dataIndex: 'realNumPos',
        key: 'realNumPos',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return record.realNumPos;
          } else if (record.subscriptionType === 2) {
            return 'لا يوجد';
          }
        }
      },
      {
        title: 'قرب الانتهاء',
        dataIndex: 'expire_soon',
        key: 'expire_soon',
        render: (_, record) => {
          if (record.expire_soon) {
            return <Tag color="cyan">حساب سينتهي قريبا</Tag>;
          } else {
            return '';
          }
        }
      }
    ];
  } else if (compoentType) {
    return [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key'
      },

      {
        title: 'الأسم ',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'رقم الهاتف',
        dataIndex: 'phone',
        key: 'phone'
      },
      {
        title: 'البريد الالكتروني',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'النشاط',
        dataIndex: 'activity',
        key: 'activity'
      },

      {
        title: 'نوع الباقه',
        dataIndex: 'package',
        key: 'package',
        render: (_, record) => {
          return record?.package?.name;
        }
      },
      {
        title: 'أجمالي المبلغ',
        dataIndex: 'total',
        key: 'total'
      },
      {
        title: ' الدفع',
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (_, record) => {
          // console.log(record);
          if (record?.package_id == 1) {
            return 'الدفع مجاني';
          } else {
            if (record.payment_status === 1) {
              return 'تم الدفع';
            } else {
              return 'لم يتم الدفع';
            }
          }
        }
      },
      {
        title: ' نوع الخدمه',
        dataIndex: 'subscriptionType',
        key: 'subscriptionType',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return 'صاحب عمل';
          } else if (record.subscriptionType === 2) {
            return 'مزود خدمه';
          }
        }
      },
      {
        title: ' اسم المسوق',
        dataIndex: 'influencer',
        key: 'influencer',
        render: (_, record) => {
          if (record.influencer) {
            return record.influencer.name;
          }
        }
      },
      {
        title: 'الرقم الضريبي',
        dataIndex: 'tax_number',
        key: 'tax_number'
      },
      {
        title: 'السجل التجاري',
        dataIndex: 'commercialRecord',
        key: 'commercialRecord'
      },
      {
        title: 'تاريخ البدايه',
        dataIndex: 'start_date',
        key: 'start_date'
      },
      {
        title: 'تاريخ النهايه',
        dataIndex: 'end_date',
        key: 'end_date'
      },
      {
        title: 'عدد نقاط البيع',
        dataIndex: 'realNumPos',
        key: 'realNumPos',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return record.realNumPos;
          } else if (record.subscriptionType === 2) {
            return 'لا يوجد';
          }
        }
      },
      {
        title: 'قرب الانتهاء',
        dataIndex: 'expire_soon',
        key: 'expire_soon',
        render: (_, record) => {
          if (record.expire_soon) {
            return <Tag color="cyan">حساب سينتهي قريبا</Tag>;
          } else {
            return '';
          }
        }
      },
      {
        title: 'حذف بيانات صاحب العمل',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Popconfirm
              title="هل أنت متأكد من حذف بيانات صاحب العمل"
              onConfirm={(key) => handleDeleteRequest(889977, record.phone, 1)}
              okText="نعم"
              cancelText="لا">
              <Tooltip title="حذف بيانات صاحب العمل">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      },
      {
        title: 'حذف فواتير صاحب العمل',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Popconfirm
              title="هل أنت متأكد من حذف  فواتير صاحب العمل"
              onConfirm={(key) => handleDeleteRequest(889977, record.phone, '')}
              okText="نعم"
              cancelText="لا">
              <Tooltip title="حذف فواتير صاحب العمل">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      },
      {
        title: 'أضافة نقطه بيع',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return (
              <Popconfirm
                title="هل أنت متأكد من أضافة نقطه بيع صاحب العمل"
                onConfirm={(key) => handleAddRequest(889977, record.phone, '')}
                okText="نعم"
                cancelText="لا">
                <Tooltip title="أضافة نقطه بيع صاحب العمل">
                  <Button
                    className="delete-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            );
          } else if (record.subscriptionType === 2) {
            return '';
          }
        }
      },
      {
        title: 'الحــالة',
        dataIndex: 'userStatus',
        render: (_, record) => {
          return renderUserStatusBtn(record);
        }
      },

      {
        title: 'تعديل الباقه',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Tooltip title="تعديل باقه صاحب العمل">
              <Button
                onClick={(key) => handleEditRequest(889977, record, '')}
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          );
        }
      },
      {
        title: 'المستخدمين',
        dataIndex: 'users',
        key: 'users',
        render: (_, record) => {
          return (
            <Tooltip title={'المستخدمين'}>
              <RouterLink
                to={`/fatorah-request/user/detail/${record?.id}`}
                onClick={(e) => {
                  // console.log(e);
                  // console.log(record);
                }}>
                {' '}
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<UserSwitchOutlined />}
                />
              </RouterLink>
            </Tooltip>
          );
        }
      }
    ];
  } else {
    return [
      {
        title: '#',
        dataIndex: 'key',
        key: 'key'
      },

      {
        title: 'رقم الهاتف',
        dataIndex: 'phone',
        key: 'phone'
      },
      {
        title: 'البريد الالكتروني',
        dataIndex: 'email',
        key: 'email'
      },
      {
        title: 'النشاط',
        dataIndex: 'activity',
        key: 'activity'
      },
      {
        title: 'نوع الباقه',
        dataIndex: 'package',
        key: 'package',
        render: (_, record) => {
          return record?.package?.name;
        }
      },
      {
        title: 'أجمالي المبلغ',
        dataIndex: 'total',
        key: 'total'
      },
      {
        title: ' الدفع',
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (_, record) => {
          // console.log(record);
          if (record?.package_id == 1) {
            return 'الدفع مجاني';
          } else {
            if (record.payment_status === 1) {
              return 'تم الدفع';
            } else {
              return 'لم يتم الدفع';
            }
          }
        }
      },
      {
        title: ' نوع الخدمه',
        dataIndex: 'subscriptionType',
        key: 'subscriptionType',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return 'صاحب عمل';
          } else if (record.subscriptionType === 2) {
            return 'مزود خدمه';
          }
        }
      },
      {
        title: ' اسم المسوق',
        dataIndex: 'influencer',
        key: 'influencer',
        render: (_, record) => {
          if (record.influencer) {
            return record.influencer.name;
          }
        }
      },
      {
        title: 'الرقم الضريبي',
        dataIndex: 'tax_number',
        key: 'tax_number'
      },
      {
        title: 'السجل التجاري',
        dataIndex: 'commercialRecord',
        key: 'commercialRecord'
      },
      {
        title: 'تاريخ البدايه',
        dataIndex: 'start_date',
        key: 'start_date'
      },
      {
        title: 'تاريخ النهايه',
        dataIndex: 'end_date',
        key: 'end_date'
      },
      {
        title: 'عدد نقاط البيع',
        dataIndex: 'realNumPos',
        key: 'realNumPos',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return record.realNumPos;
          } else if (record.subscriptionType === 2) {
            return 'لا يوجد';
          }
        }
      },
      {
        title: 'قرب الانتهاء',
        dataIndex: 'expire_soon',
        key: 'expire_soon',
        render: (_, record) => {
          if (record.expire_soon) {
            return <Tag color="cyan">حساب سينتهي قريبا</Tag>;
          } else {
            return '';
          }
        }
      },
      {
        title: 'حذف بيانات صاحب العمل',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Popconfirm
              title="هل أنت متأكد من حذف بيانات صاحب العمل"
              onConfirm={(key) => handleDeleteRequest(889977, record.phone, 1)}
              okText="نعم"
              cancelText="لا">
              <Tooltip title="حذف بيانات صاحب العمل">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      },
      {
        title: 'حذف فواتير صاحب العمل',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Popconfirm
              title="هل أنت متأكد من حذف  فواتير صاحب العمل"
              onConfirm={(key) => handleDeleteRequest(889977, record.phone, '')}
              okText="نعم"
              cancelText="لا">
              <Tooltip title="حذف فواتير صاحب العمل">
                <Button
                  className="delete-btn"
                  size="large"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          );
        }
      },
      {
        title: 'أضافة نقطه بيع',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          if (record.subscriptionType === 1) {
            return (
              <Popconfirm
                title="هل أنت متأكد من أضافة نقطه بيع صاحب العمل"
                onConfirm={(key) => handleAddRequest(889977, record.phone, '')}
                okText="نعم"
                cancelText="لا">
                <Tooltip title="أضافة نقطه بيع صاحب العمل">
                  <Button
                    className="delete-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<PlusOutlined />}
                  />
                </Tooltip>
              </Popconfirm>
            );
          } else if (record.subscriptionType === 2) {
            return '';
          }
        }
      },

      {
        title: 'تعديل كلمة المرور',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Tooltip title="تعديل كلمة المرور">
              <Button
                onClick={(key) => handleEditPassword(889977, record, '')}
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          );
        }
      },
      {
        title: 'الحــالة',
        dataIndex: 'userStatus',
        render: (_, record) => {
          return renderUserStatusBtn(record);
        }
      },
      {
        title: 'تعديل الباقه',
        dataIndex: 'delete',
        width: 92,
        key: 'delete',
        render: (_, record) => {
          return (
            <Tooltip title="تعديل باقه صاحب العمل">
              <Button
                onClick={(key) => handleEditRequest(889977, record, '')}
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          );
        }
      },
      {
        title: 'الأسم ',
        dataIndex: 'name',
        key: 'name'
      }
    ];
  }
};

export default customerTableColumnsUsers;
