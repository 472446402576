import axios from 'axios';

const getAllAdmin = async (token, type) => {
  try {
    const response = await axios.get(`/Admin/all?type=${type}`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    if (response.data.response_code === 200 && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default getAllAdmin;
