import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingNfcCustomers: false,
  setLoadingNfcCustomers: (load) => {},
  allNfcCustomers: [],
  setAllNfcCustomers: (NfcCustomers) => {},
  allNfcCustomersTable: [],
  setAllNfcCustomersTable: (NfcCustomers) => {},
  NfcCustomersModalOpened: false,
  setNfcCustomersModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedNfcCustomers: null,
  setSelectedNfcCustomers: (servKey) => {}
};

const NfcCustomersContext = createContext(INITIAL_VALUES);

export const NfcCustomersProvider = ({ children }) => {
  const [allNfcCustomers, setAllNfcCustomers] = useState(INITIAL_VALUES.allNfcCustomers);
  const [allNfcCustomersTable, setAllNfcCustomersTable] = useState(
    INITIAL_VALUES.allNfcCustomersTable
  );
  const [loadingNfcCustomers, setLoadingNfcCustomers] = useState(
    INITIAL_VALUES.loadingNfcCustomers
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [NfcCustomersModalOpened, setNfcCustomersModalOpened] = useState(
    INITIAL_VALUES.NfcCustomersModalOpened
  );
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedNfcCustomers, setSelectedNfcCustomers] = useState(
    INITIAL_VALUES.selectedNfcCustomers
  );

  return (
    <NfcCustomersContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingNfcCustomers,
        setLoadingNfcCustomers,
        allNfcCustomers,
        setAllNfcCustomers,
        NfcCustomersModalOpened,
        setNfcCustomersModalOpened,
        selectedServId,
        setSelectedServId,
        selectedNfcCustomers,
        setSelectedNfcCustomers,
        allNfcCustomersTable,
        setAllNfcCustomersTable
      }}>
      {children}
    </NfcCustomersContext.Provider>
  );
};

export default NfcCustomersContext;
