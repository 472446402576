/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
// import UesrContext from '../../contexts/user-context/UserProvider';

import { Input, Select } from 'antd';
// import getCategories from '../../apis/category-apis/getCategories';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import getAllAdmin from '../../apis/Admin/getAllAdmin';
import UesrContext from '../../contexts/user-context/UserProvider';

const CustomerCategoryFilter = () => {
  const { user } = useContext(UesrContext);
  const { Option } = Select;

  const { setSelectedFilter, setSearchText } = useContext(CustomerContext);

  const [admin, setAdmin] = useState([]);

  const [select, setSelect] = useState(0);

  // useEffect(() => {
  //   RenderInput();
  // }, [selectedFilter]);

  // function handleChangebtn() {
  //   setSelect(10);
  
  // }
  function handleChangeSearch(v) {
    
    setSearchText(v.target.value);
    setValues((prev) => ({
      ...prev,
      email: v.target.value
    }));
  }
  function handleChangeSearchPhone(v) {
    
    setSearchText(v.target.value);
    setValues((prev) => ({
      ...prev,
      email: v.target.value
    }));
    setValues((prev) => ({
      ...prev,
      phone: v.target.value
    }));
  }
  function handleChangeAdmin(v) {
    
    setSearchText(v);
    setValues((prev) => ({
      ...prev,
      id: v
    }));
  }

  const [values, setValues] = useState({
    email: '',
    phone: '',
    id: '0'
  });

  function handleChange(value) {
    setValues({ ...values, phone: '' });
    setValues({ ...values, email: '' });
    setValues({ ...values, id: '0' });

    setSearchText('');
    setSelectedFilter(value);

    setSelect(value);
  }
  useEffect(() => {
    const getAdmin = async () => {
      try {
        const getAdminRes = await getAllAdmin(user?.token);
        setAdmin(getAdminRes);
        // }
      } catch (error) {
        // setLoadingAdmin(false);
        console.log(error);
      }
    };
    getAdmin();
  }, []);
  // function RenderInput() {
  
  //   if (selectedFilter === 10) {
  //     return (
  //       <Input type="text" onChange={handleChangeSearch} placeholder="أكتب البريد الالكتروني" />
  //     );
  //   } else if (selectedFilter === 11) {
  //     return <Input type="text" onChange={handleChangeSearch} placeholder="أكتب رقم الهاتف" />;
  //   } else if (selectedFilter === 12) {
  //     return (
  //       <Select
  //         className="customer-filter-select"
  //         defaultValue={0}
  //         style={{ width: 162 }}
  //         size="large"
  //         onChange={handleChangeAdmin}>
  //         {admin?.length > 0 &&
  //           admin.map((op, index) => (
  //             <Option key={index} value={op.id}>
  //               {op.name}
  //             </Option>
  //           ))}
  //       </Select>
  //     );
  //   } else {
  //     console.log('kjffdss');
  //   }
  // }
  return (
    <>
      <Select
        className="customer-filter-select"
        defaultValue="0"
        style={{ width: 162 }}
        size="large"
        onChange={handleChange}>
        <Option value="0">جميع العملاء</Option>
        <Option value="10">البحث بالبريد الألكتروني</Option>
        {/* <Option value="444">عملاء سُلم</Option> */}

        <Option value="11">البحث برقم الهاتف</Option>
        <Option value="12">البحث بالمسوق</Option>
        <Option value="1">عملاء قرب الأنتهاء</Option>
        <Option value="2">عملاء منتهين</Option>
        <Option value="3">اشتراك مجاني</Option>
        <Option value="4">اشتراك شهر</Option>
        <Option value="5">اشتراك 3 شهور</Option>
        <Option value="6">اشتراك 6 شهور</Option>

        <Option value="7">اشتراك سنوي</Option>
        <Option value="8">تم الدفع</Option>
        <Option value="9">لم يتم الدفع</Option>
      </Select>
      {/* {RenderInput()} */}
      {/* <button onClick={handleChangebtn}> dddd</button> */}

      <Input
        type="text"
        name="email"
        value={values.email}
        onChange={handleChangeSearch}
        placeholder="أكتب البريد الالكتروني"
        className={`${select == 10 ? 'active' : 'noactive'}`}
      />

      <Input
        type="text"
        value={values.phone}
        onChange={handleChangeSearchPhone}
        placeholder="أكتب رقم الهاتف"
        className={`${select == 11 ? 'active' : 'noactive'}`}
      />

      <Select
        className={`customer-filter-select ${select == 12 ? 'active' : 'noactive'}`}
        value={values.id}
        style={{ width: 162 }}
        size="large"
        onChange={handleChangeAdmin}>
        <Option value="0">جميع المسوقين</Option>
        {admin?.length > 0 &&
          admin.map((op, index) => (
            <Option key={index} value={op.id}>
              {op.name}
            </Option>
          ))}
      </Select>

      {/* <Input type="button" value="" /> */}
    </>
  );
};

export default CustomerCategoryFilter;
