import axios from 'axios';

const singleAdmin = async (token, id) => {
  try {
    const response = await axios.get(`/Admin/single?id=${id}`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    
    // if (response.status === 200 && response.data) {
    return response;
    // }
  } catch (e) {
    console.log(e);
  }
};

export default singleAdmin;
