import React, { useContext } from 'react';
import { Button, Modal } from 'antd';

import { PlusOutlined } from '@ant-design/icons';
import AddPackageDetailesForm from './AddPackageDetailesForm';
import PackageDetailesTable from './PackageDetailesTable';
import PackageDetailesContext from '../../contexts/PackageDetailes-context/PackageDetailesProvider';

import './PackageDetailesPage.scss';

const PackageDetailesPage = () => {
  const {
    PackageDetailesModalOpened,
    setPackageDetailesModalOpened,
    setSelectedServId,
    setSelectedPackageDetailes
  } = useContext(PackageDetailesContext);

  return (
    <div className="PackageDetailes-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setPackageDetailesModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة تفاصيل الباقه</span>
        </Button>
      </div>

      <PackageDetailesTable />

      {PackageDetailesModalOpened && (
        <Modal
          className="add-PackageDetailes-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={setSelectedServId ? 'تعديل الباقه' : 'اضـافة باقه جديدة'}
          visible={PackageDetailesModalOpened}
          onOk={() => {
            setPackageDetailesModalOpened(false);
            setSelectedServId('');
            setSelectedPackageDetailes(null);
          }}
          onCancel={() => {
            setPackageDetailesModalOpened(false);
            setSelectedServId('');
            setSelectedPackageDetailes(null);
          }}
          footer={false}>
          <AddPackageDetailesForm />
        </Modal>
      )}
    </div>
  );
};

export default PackageDetailesPage;
