import axios from 'axios';

const GetAllTechnicalSupport = async (token, filterValues) => {
  // console.log(token);
  try {
    const response = await axios.get(
      `/tickets?search=${filterValues?.search ? filterValues?.search : ''}`,
      {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    if (response.data.response_code === 200 && response.data.data) {
      // console.log(response.data);
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default GetAllTechnicalSupport;
