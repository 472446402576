/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import DaftarContext from '../../contexts/Daftar-context/DaftarProvider';
// import getDaftarUsApi from '../../apis/Daftar-us-api/getDaftarUsApi';
import getAllDaftar from '../../apis/getAllDaftar';
import daftarTableColumns from './daftarTableColumns';
const DaftarTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingDaftarData,
    setLoadingDaftarData,
    allDaftarData,
    setAllDaftarData,
    setDaftarModalOpened,
    setSelectedDaftarId
  } = useContext(DaftarContext);
  const [DaftarDataSource, setDaftarDataSource] = useState([]);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoadingDaftarData(true);
        const getServicesRes = await getAllDaftar(user?.token);
        if (getServicesRes.status === 200 && getServicesRes?.data?.data) {
          setAllDaftarData(getServicesRes.data.data);
          setLoadingDaftarData(false);
        }
      } catch (error) {
        setLoadingDaftarData(false);
        console.log(error);
      }
    };
    getServices();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allDaftarData) {
      const mappedDaftarData = allDaftarData.map((serv) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setDaftarDataSource(mappedDaftarData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allDaftarData, allDaftarData.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="services-table"
      dataSource={DaftarDataSource}
      columns={daftarTableColumns(
        user?.token,
        setFetchCount,
        setLoadingDaftarData,
        setDaftarModalOpened,
        setSelectedDaftarId
      )}
      loading={loadingDaftarData}
      scroll={{ x: 1200 }}
    />
  );
};

export default DaftarTable;
