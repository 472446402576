import { Col, Row, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import SupscriptionStatsContext from '../../contexts/supsctription-stats-context/SupscriptionStatsProvider';
import './SupscriptionStatsCharts.scss';

const options = {
  width: '100%',
  height: 400,
  chartArea: { width: '95%', height: '95%' },
  colors: ['#FEC868', '#509b8f'],
  legend: { textStyle: { fontSize: 14 } }
};
const options2 = {
  width: '100%',
  height: 400,
  chartArea: { width: '95%', height: '95%' },
  colors: ['#cf1322', '#0088FE'],
  legend: { textStyle: { fontSize: 14 } }
};
const options3 = {
  width: '100%',
  height: 400,
  chartArea: { width: '95%', height: '95%' },
  colors: ['#dc3912', '#0088FE'],
  legend: { textStyle: { fontSize: 14 } }
};
const options4 = {
  width: '100%',
  height: 400,
  chartArea: { width: '95%', height: '95%' },
  colors: ['#FF7B54', '#FFB26B'],
  legend: { textStyle: { fontSize: 14 } }
};

const SupscriptionStatsCharts = ({ allSupscriptionStats }) => {
  const [newSubscriptionData, setNewSubscriptionData] = useState();
  const [expiredSubscriptionData, setExpiredSubscriptionData] = useState();
  const [closeToExpireSubscriptionData, setCloseToExpireSubscriptionData] = useState();
  const [paidFreeData, setPaidFreeData] = useState();

  const [barData, setbarData] = useState();
  const [barOptions, setBarOptions] = useState();

  const { loadingSupscriptionStats } = useContext(SupscriptionStatsContext);
  useEffect(() => {
    if (!loadingSupscriptionStats && allSupscriptionStats) {
      setNewSubscriptionData([
        ['Name', 'Number Of subscription'],
        ['الاشتراكات الجديدة', allSupscriptionStats.newSubscription_count],
        [
          'الاشتراكات الغير جديدة ',
          allSupscriptionStats.subscription_count - allSupscriptionStats.newSubscription_count
        ]
      ]);

      setExpiredSubscriptionData([
        ['Name', 'Number Of subscription'],
        ['الاشتراكات المنتهية', allSupscriptionStats.expiredSubscription_count],
        [' الاشتراكات الغير منتهية', allSupscriptionStats.subscription_count]
      ]);

      setCloseToExpireSubscriptionData([
        ['Name', 'Number Of subscription'],
        ['الاشتراكات القريبة من الانتهاء', allSupscriptionStats.subscriptionCloseToExpiry_count],
        [
          ' الاشتراكات الغير قريبة من الانتهاء',
          allSupscriptionStats.subscription_count -
            allSupscriptionStats.subscriptionCloseToExpiry_count
        ]
      ]);

      setPaidFreeData([
        ['Name', 'Number Of subscription'],
        ['الاشتراكات  المجانية ', allSupscriptionStats.free_subscription_count],
        [' الاشتراكات المدفوعة   ', allSupscriptionStats.paid_subscription_count]
      ]);
    }
  }, [allSupscriptionStats]);
  return (
    <div className="SupscriptionStatsCharts">
      {!loadingSupscriptionStats && allSupscriptionStats ? (
        <Row gutter={20} justify="center">
          <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            {allSupscriptionStats?.newSubscription_count ? (
              <div>
                <Chart chartType="PieChart" data={newSubscriptionData} options={options} />
                <div className="details-wrapper">
                  <p>
                    الاشتراكات الجديدة
                    <span style={{ color: '#FEC868' }}>
                      {` ${allSupscriptionStats.newSubscription_count}`}
                    </span>
                  </p>
                  <p>
                    الاشتراكات الغير جديدة
                    <span style={{ color: '#509b8f' }}>
                      {` ${
                        allSupscriptionStats.subscription_count -
                        allSupscriptionStats.newSubscription_count
                      }`}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="no_data_div">لا يوجد اشتراكات جديدة</div>
            )}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            {allSupscriptionStats?.expiredSubscription_count ? (
              <div>
                <Chart chartType="PieChart" data={expiredSubscriptionData} options={options2} />
                <div className="details-wrapper">
                  <p>
                    الاشتراكات المنتهية
                    <span style={{ color: '#cf1322' }}>
                      {` ${allSupscriptionStats.expiredSubscription_count}`}
                    </span>
                  </p>
                  <p>
                    الاشتراكات الغير منتهية
                    <span style={{ color: '#0088FE' }}>
                      {` ${allSupscriptionStats.subscription_count}`}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="no_data_div">لا يوجد اشتراكات منتهية</div>
            )}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            {allSupscriptionStats?.subscriptionCloseToExpiry_count ? (
              <div>
                <Chart
                  chartType="PieChart"
                  data={closeToExpireSubscriptionData}
                  options={options3}
                />
                <div className="details-wrapper">
                  <p>
                    الاشتراكات القريبة من الانتهاء
                    <span style={{ color: '#dc3912' }}>
                      {` ${allSupscriptionStats.subscriptionCloseToExpiry_count}`}
                    </span>
                  </p>
                  <p>
                    الاشتراكات الغير قريبة من الانتهاء
                    <span style={{ color: '#0088FE' }}>
                      {` ${
                        allSupscriptionStats.subscription_count -
                        allSupscriptionStats.subscriptionCloseToExpiry_count
                      }`}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="no_data_div">لا يوجد اشتراكات قريبة من الانتهاء</div>
            )}
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6}>
            {allSupscriptionStats?.free_subscription_count ? (
              <div>
                <Chart chartType="PieChart" data={paidFreeData} options={options4} />
                <div className="details-wrapper">
                  <p>
                    الاشتراكات المجانية
                    <span style={{ color: '#FF7B54' }}>
                      {` ${allSupscriptionStats.free_subscription_count}`}
                    </span>
                  </p>
                  <p>
                    الاشتراكات المدفوعة
                    <span style={{ color: '#FFB26B' }}>
                      {` ${allSupscriptionStats.paid_subscription_count}`}
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className="no_data_div">لا يوجد اشتراكات مجانية </div>
            )}
          </Col>
        </Row>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default SupscriptionStatsCharts;
