/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';

import './ProjectsTable.scss';
import { Table } from 'antd';
import columns from './ProjectTableColums';
import getProjects from '../../apis/project-api/getProjects';
import UesrContext from '../../contexts/user-context/UserProvider';
import { useLocation } from 'react-router';
const ProjectsTable = ({
  projectCount,
  setProjectCount,
  setIsEditModalVisible,
  setcategory,
  setIsDetailesModalVisible,
  serviceId
}) => {
  const [projects, setProjects] = useState([]);

  const location = useLocation();

  const { user } = useContext(UesrContext);

  useEffect(() => {
    // console.log(serviceId);
    if (location && location.state && location.state.serviceId) {
      const fetchCategory = async () => {
        try {
          const fetchedData = await getProjects(user?.token);

          if (!fetchedData) {
          } else {
            const filterData = fetchedData.filter(
              (c) => c.service == parseInt(location.state.serviceId)
            );
            setProjects(filterData);
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchCategory();
    } else if (serviceId > 0) {
      const fetchCategory = async () => {
        try {
          const fetchedData = await getProjects(user?.token);

          if (!fetchedData) {
          } else {
            // eslint-disable-next-line eqeqeq
            const filterData = fetchedData.filter((c) => c.service == parseInt(serviceId));
            setProjects(filterData);
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchCategory();
      // console.log(projectCount);
    } else {
      const fetchCategory = async () => {
        try {
          const fetchedData = await getProjects(user?.token);

          if (!fetchedData) {
          } else {
            setProjects(fetchedData);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchCategory();
      // console.log(projectCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectCount, user?.token, location, serviceId]);
  return (
    <div>
      <Table
        columns={columns(
          projectCount,
          setProjectCount,
          setIsEditModalVisible,
          setcategory,
          user?.token,
          setIsDetailesModalVisible
        )}
        dataSource={projects}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

export default ProjectsTable;
