/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined, DeleteOutlined } from '@ant-design/icons';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddPackageDetailesForm.scss';
import UploadPackageDetailesFile from './UploadPackageDetailesFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createPackageDetailes from '../../apis/PackageDetailes/createPackageDetailes';
import UesrContext from '../../contexts/user-context/UserProvider';
import PackageDetailesContext from '../../contexts/PackageDetailes-context/PackageDetailesProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';

const defaultValues = {
  icon: null,
  title: '',
  title_en: '',

  details: [{ description: '', description_en: '' }]
};

const AddPackageDetailesForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setPackageDetailesModalOpened,
    allPackageDetailes,
    setSelectedServId,
    selectedServId,
    selectedPackageDetailes,
    setSelectedPackageDetailes
  } = useContext(PackageDetailesContext);

  let schema;

  if (selectedPackageDetailes && selectedServId) {
    schema = Yup.object().shape({
      title: Yup.string().required('الأسم مطلوب'),
      title_en: Yup.string().required('الأسم بالانجليزي مطلوب')

      // details: Yup.string().required('الوصف مطلوب')
    });
  } else {
    schema = Yup.object().shape({
      icon: Yup.mixed().required('من فضلك اختار صورة الباقه'),
      title: Yup.string().required('الأسم مطلوب'),
      title_en: Yup.string().required('الأسم بالانجليزي مطلوب')

      // details: Yup.string().required('الوصف مطلوب')
    });
  }
  
  
  useEffect(() => {
    
    
    if (selectedServId) {
      const foundPackageDetailes = allPackageDetailes.find((serv) => serv.id === selectedServId);
      if (foundPackageDetailes) setSelectedPackageDetailes(foundPackageDetailes);
    }
  }, [selectedServId]);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (selectedPackageDetailes?.icon) {
      
      setUrls([selectedPackageDetailes.icon]);
    }
  }, [selectedPackageDetailes]);
  
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (data) => {
    try {
      let res;
      if (selectedPackageDetailes) {
        res = await createPackageDetailes(
          `/package-feature`,
          data,
          user?.token,
          selectedPackageDetailes.id
        );
      } else {
        res = await createPackageDetailes('/package-feature', data, user?.token, 0);
      }
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء التفاصيل',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setPackageDetailesModalOpened(false);
        setSelectedServId('');
        setSelectedPackageDetailes(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء التفاصيل المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // handle initial values

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'details'
  });
  useEffect(() => {
    if (selectedPackageDetailes && selectedServId) {
      const { title, title_en, details } = selectedPackageDetailes;
      setValue('title', title);
      setValue('title_en', title_en);

      // fields = details;
      if (details) {
        setValue('details', details);
      }
      // for (let i = 0; i < details.length; i++) {
      //   append({ description: details[i].description, description_en: details[i].description_en });
      // }

      // append({ description: '', description_en: '' });
      // setValue('details', details);
    }
  }, [selectedPackageDetailes, selectedServId]);

  return (
    <Form
      className="add-PackageDetailes-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <UploadPackageDetailesFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="icon"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الباقه
            </>
          }
          urls={urls}
          errorMsg={errors?.icon ? errors?.icon?.message : ''}
        />

        <div className="form-field-wrapper">
          <AntdTextField
            name="title"
            type="text"
            placeholder="الأسم..."
            errorMsg={errors?.title?.message}
            validateStatus={errors?.title ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="title_en"
            type="text"
            placeholder="الأسم بالانجليزي..."
            errorMsg={errors?.title_en?.message}
            validateStatus={errors?.title_en ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* <div className="form-field-wrapper">
          <AntdTextField
            name="details"
            type="text"
            placeholder="الوصف..."
            errorMsg={errors?.details?.message}
            validateStatus={errors?.details ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div> */}
        <ul>
          {fields.map((item, index) => {
            return (
              <li key={item.id}>
                <input
                  className="input"
                  placeholder="التفاصيل بالعربي..."
                  defaultValue={`${item.description}`} // make sure to set up defaultValue
                  {...register(`details.${index}.description`)}
                />

                <input
                  className="input"
                  placeholder="التفاصيل الانجليزي..."
                  defaultValue={`${item.description_en}`} // make sure to set up defaultValue
                  {...register(`details.${index}.description_en`)}
                />
                {/* <Controller
                  render={({ field }) => <input {...field} />}
                  placeholder="التفاصيل الانجليزي..."
                  name={`details.${index}.details`}
                  control={control}
                  defaultValue={item.description_en} // make sure to set up defaultValue
                /> */}
                <button type="button" onClick={() => remove(index)} className="delete">
                  <DeleteOutlined />
                </button>
              </li>
            );
          })}
        </ul>
        <a
          className="add"
          type="primary"
          onClick={() => {
            append({ description: '', description_en: '' });
          }}>
          اضافه وصف
        </a>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة تفاصيل الباقه
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddPackageDetailesForm;
