/* eslint-disable react-hooks/exhaustive-deps */
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { exportToExcel } from 'react-json-to-excel';
import getCustomersPdfApi from '../../apis/customer/getCustomersPdf';
import getAllFatorah from '../../apis/fatorah-apis/getAllFatorah';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import AddQuestionForm from './AddQuestionsForm';
import './CustomerTable.scss';
import UpdatePasswordCustomerForm from './UpdatePasswordCustomerForm';
import customerTableColumnsUsers from './customerTableColumnsUsers';

const CustomerTable = ({ filterDataValue, setFilterDataValue }) => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingcustomer,
    setLoadingcustomer,
    selectedFilter,
    allcustomer,
    setAllcustomer,
    searchText,
    setSelectedQuestion,
    setQuestionModalOpened,
    selectedQuestion,
    questionModalOpened,
    setSelectedCustomer,
    selectedCustomer,
    customerModalOpened,
    setCustomerModalOpened
  } = useContext(CustomerContext);
  const [customerDataSource, setcustomerDataSource] = useState([]);

  //  filter date with date target
  // useEffect(() => {
  //   if (filterDataValue !== null && customerDataSource.length) {
  //     const filteredData = customerDataSource.filter((item) => {
  //       const startDate = new Date(item.start_date);
  //       const endDate = new Date(item.end_date);

  //       return (
  //         startDate >= new Date(filterDataValue?.startDate) &&
  //         endDate <= new Date(filterDataValue?.endDate)
  //       );
  //     });
  //     setcustomerDataSource(filteredData);
  //     // console.log(filteredData);
  //   } else {
  //     let isMounted = true;
  //     console.log(isMounted);

  //     if (allcustomer) {
  //       const mappedcustomer = allcustomer.map((ques) => ({
  //         ...ques,
  //         key: ques.id
  //       }));

  //       if (isMounted) {
  //         setcustomerDataSource(mappedcustomer);
  //       }
  //     }

  //     return () => {
  //       isMounted = false;
  //     };
  //   }
  // }, [filterDataValue]);
  useEffect(() => {
    let isMounted = true;
    const getcustomer = async () => {
      try {
        setLoadingcustomer(true);
        let getcustomerRes;
        if (selectedFilter) {
          if (selectedFilter !== '8') {
            getcustomerRes = await getAllFatorah(
              user?.token,
              selectedFilter,
              searchText,
              filterDataValue
            );
          } else {
            getcustomerRes = await getAllFatorah(
              user?.token,
              selectedFilter,
              searchText,
              filterDataValue
            );
          }
        } else {
          getcustomerRes = await getAllFatorah(user?.token, '0', searchText, filterDataValue);
        }

        if (getcustomerRes) {
          if (isMounted) {
            setAllcustomer(getcustomerRes);
            setLoadingcustomer(false);
          }
        }
      } catch (error) {
        setLoadingcustomer(false);
        console.log(error);
      }
    };
    getcustomer();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, selectedFilter, searchText, filterDataValue]);

  //  change  ques and key in data target
  // const getDataNotFilterDate = useMemo(() => {
  //   let isMounted = true;
  //   console.log(isMounted);

  //   if (allcustomer) {
  //     const mappedcustomer = allcustomer.map((ques) => ({
  //       ...ques,
  //       key: ques.id
  //     }));

  //     if (isMounted) {
  //       setcustomerDataSource(mappedcustomer);
  //       console.log(mappedcustomer);
  //     }
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [allcustomer, allcustomer.length, fetchCount]);
  useEffect(() => {
    let isMounted = true;
    // console.log(isMounted);

    if (allcustomer) {
      const mappedcustomer = allcustomer.map((ques) => ({
        ...ques,
        key: ques.id
      }));

      if (isMounted) {
        setcustomerDataSource(mappedcustomer);
        // console.log(mappedcustomer);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allcustomer, allcustomer.length, fetchCount]);
  const cols = customerTableColumnsUsers(
    user?.type,
    setFetchCount,
    user?.token,
    setSelectedQuestion,
    setQuestionModalOpened
  );

  const expordDataToExcel = async () => {
    try {
      const res = await getAllFatorah(user?.token, selectedFilter, searchText, filterDataValue);
      if (res) {
        console.log('🚀 ~ file: CustomerTable.jsx:170 ~ expordDataToExcel ~ data:', res);
        exportToExcel(res, 'صاحب عمل');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const expordDataToExcel = () => {
  //   // console.log(customerDataSource[0]);

  //   let slicedCols = cols?.slice(0, -5);
  //   const excel = new Excel();
  //   excel
  //     ?.addSheet('sheet 1')
  //     ?.addColumns(slicedCols)
  //     ?.addDataSource(customerDataSource, {
  //       str2Percent: true
  //     })
  //     ?.saveAs('صاحب عمل.xlsx');
  // };

  const handleExportPdf = async () => {
    let res = await getCustomersPdfApi(user?.token, selectedFilter, searchText);
  };
  let headers = [
    { label: '#', key: 'key' },
    { label: 'الاسم', key: 'name' },
    { label: 'رقم الهاتف', key: 'phone' },
    { label: 'البريد الألكتروني	', key: 'email' },
    { label: 'النشاط', key: 'activity' },
    // { label: 'نوع الباقه', key: 'package' },
    { label: 'أجمالي المبلغ', key: 'total' },
    // { label: 'الدفع', key: 'payment_status' },
    // { label: ' اسم المسوق', key: 'influencer' },
    // { label: ' نوع الخدمه', key: 'subscriptionType' },
    { label: 'الرقم الضريبي', key: 'tax_number' },
    { label: 'السجل التجاري', key: 'commercialRecord' },
    { label: 'تاريخ البدايه', key: 'start_date' },
    { label: 'تاريخ النهايه', key: 'end_date' }
    // { label: 'عدد نقاط البيع', key: 'realNumPos' }
    // { label: 'قرب الانتهاء', key: 'expire_soon' }

    // { label: ' 	', key: 'email' }
  ];
  return (
    <>
      {/* <CSVLink
        data={customerDataSource}
        headers={headers}
        className="ant-btn     ant-btn-rtl "
        style={{
          marginBottom: '15px',
          backgroundColor: '#35bb71',
          color: '#fff',
          borderColor: '#35bb71'
        }}>
        <FileExcelOutlined style={{ marginLeft: '5px' }} /> تصدير اكسل
      </CSVLink> */}
      <button
        onClick={expordDataToExcel}
        className="ant-btn ant-btn-rtl "
        style={{
          marginBottom: '15px',
          backgroundColor: '#35bb71',
          color: '#fff',
          borderColor: '#35bb71'
        }}>
        <FileExcelOutlined style={{ marginLeft: '5px' }} /> تصدير اكسل{' '}
      </button>

      <button
        className="ant-btn ant-btn-danger ant-btn-rtl export-pdf-btn "
        style={{ marginBottom: '15px' }}
        onClick={handleExportPdf}>
        <FilePdfOutlined /> تصدير Pdf
      </button>

      <Table
        pagination={{ pageSize: 10 }}
        className="customer-table"
        dataSource={customerDataSource}
        columns={customerTableColumnsUsers(
          user?.type,
          setFetchCount,
          user?.token,
          setSelectedQuestion,
          setQuestionModalOpened,
          setSelectedCustomer,
          setCustomerModalOpened,
          user,
          setLoadingcustomer,
          loadingcustomer
        )}
        loading={loadingcustomer}
        scroll={{ x: 1200 }}
      />
      {questionModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedQuestion ? 'تعديل الباقه' : 'اضـافة الميزه جديدة'}
          visible={questionModalOpened}
          onOk={() => {
            setQuestionModalOpened(false);
            setSelectedQuestion(null);
          }}
          onCancel={() => {
            setQuestionModalOpened(false);

            setSelectedQuestion(null);
          }}
          footer={false}>
          <AddQuestionForm />
        </Modal>
      )}
      {customerModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title="تعديل  كلمة المرور"
          visible={customerModalOpened}
          onOk={() => {
            setCustomerModalOpened(false);
            setSelectedCustomer(null);
          }}
          onCancel={() => {
            setCustomerModalOpened(false);
            setSelectedCustomer(null);
          }}
          footer={false}>
          <UpdatePasswordCustomerForm />
        </Modal>
      )}
    </>
  );
};

export default CustomerTable;
