import axios from 'axios';

const deleteNotificationApi = async (notificationId, token) => {
  try {
    const formData = new FormData();

    if (notificationId) {
      formData.append('id', notificationId);
    }

    const response = await axios.delete(`/notifications?id=${notificationId}`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default deleteNotificationApi;
