import { DollarCircleOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import React, { useContext } from 'react';
import './MerchantsStatsGrid.scss';
import cash from '../../assets/imgs/icons/statistics/buyinvoice.svg';
import saleIcon from '../../assets/imgs/icons/statistics/money-check-dollar-solid.svg';
import plusIcon from '../../assets/imgs/icons/statistics/calendar-plus-solid.svg';
import minusIcon from '../../assets/imgs/icons/statistics/calendar-minus-solid.svg';
import handIcon from '../../assets/imgs/icons/statistics/hand-holding-dollar-solid.svg';
import MerchantsStatsContext from '../../contexts/merchants-stats-context/MerchantsStatsProvider';

const MerchantsStatsGrid = ({ allMerchantsStats }) => {
  const { loadingMerchantsStats } = useContext(MerchantsStatsContext);
  return (
    <div className="MerchantsStatsGrid">
      {!loadingMerchantsStats && allMerchantsStats ? (
        <>
          <Row gutter={20} style={{ marginBottom: '15px' }}>
            <Col xs={24} sm={24} md={12}>
              <div className="item">
                <div className="item_icon_wrapper">
                  <img src={saleIcon} alt="total amount" />
                </div>
                <span className="num">{allMerchantsStats?.saleInvoicePrices?.toFixed()}</span>
                <p> اجمالي المبيعات</p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div className="item">
                <div className="item_icon_wrapper">
                  <img src={plusIcon} alt="total amount" />
                </div>
                <span className="num">
                  {allMerchantsStats?.saleInvoicePricesInCurrentDay?.toFixed()}
                </span>
                <p> المبيعات في اليوم الحالي </p>
              </div>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={24} sm={24} md={8}>
              <div className="item">
                <div className="item_icon_wrapper">
                  <img src={cash} alt="total amount" />
                </div>
                <span className="num">{allMerchantsStats?.buyInvoicePricesCash?.toFixed()}</span>
                <p> اجمالي المشتريات</p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className="item">
                <div className="item_icon_wrapper">
                  <img src={minusIcon} alt="total amount" />
                </div>
                <span className="num">
                  {allMerchantsStats?.buyInvoicePricesInCurrentDay?.toFixed()}
                </span>
                <p> المشتريات في اليوم الحالي </p>
              </div>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className="item">
                <div className="item_icon_wrapper">
                  <img src={handIcon} alt="total amount" />
                </div>
                <span className="num">
                  {allMerchantsStats?.buyInvoicePricesInstallment?.toFixed()}
                </span>
                <p> اقساط المشتريات </p>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default MerchantsStatsGrid;
