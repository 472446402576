/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import getAllSelles from '../../apis/Selles/getAllSelles';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import SellesColumns from './SellesTableColumns';
import SellesContext from '../../contexts/Selles-context/SellesProvider';
const SellesTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingSelles,
    setLoadingSelles,
    allSelles,
    setAllSelles,
    setSellesModalOpened,
    setSelectedServId
  } = useContext(SellesContext);
  const [SellesDataSource, setSellesDataSource] = useState([]);

  useEffect(() => {
    const getSelles = async () => {
      try {
        setLoadingSelles(true);
        const getSellesRes = await getAllSelles(user?.token);
        // console.log(getSellesRes);
        // if (getSellesRes.status === 200 && getSellesRes?.data?.data) {
        setAllSelles(getSellesRes);
        setLoadingSelles(false);
        // }
      } catch (error) {
        setLoadingSelles(false);
        console.log(error);
      }
    };
    getSelles();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allSelles) {
      const mappedSelles = allSelles.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setSellesDataSource(mappedSelles);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allSelles, allSelles.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Selles-table"
      dataSource={SellesDataSource}
      columns={SellesColumns(
        user?.token,
        setFetchCount,
        setLoadingSelles,
        setSellesModalOpened,
        setSelectedServId
      )}
      loading={loadingSelles}
      scroll={{ x: 1200 }}
    />
  );
};

export default SellesTable;
