import axios from 'axios';

const addProjectTechnoloty = async (values, id, token) => {
  try {
    const formData = new FormData();
    formData.append('project_id', id);

    for (let i = 0; i < values.image_project.length; i++) {
      formData.append('images[]', values.image_project[i]);
    }

    const response = await axios.post('/ProjectDetails/addTec', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',

        Authorization: 'Bearer ' + token
      }
    });

    // console.log('request project response: ', response);
    if (response.status === 200 && response.data.status === 1 && response.data.data) {
      return response.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default addProjectTechnoloty;
