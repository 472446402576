import { Modal, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getAllFatorah from '../../apis/fatorah-apis/getAllFatorah';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import AddQuestionForm from './AddQuestionsForm';
import customerTableColumns from './customerTableColumns';
import { Excel } from 'antd-table-saveas-excel';
import { FileExcelOutlined } from '@ant-design/icons';
import { CSVLink, CSVDownload } from 'react-csv';

const SollemPage = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingcustomer,
    setLoadingcustomer,
    selectedFilter,
    allcustomer,
    setAllcustomer,
    searchText,
    setSelectedQuestion,
    setQuestionModalOpened,
    selectedQuestion,
    questionModalOpened,
    setSelectedCustomer,
    setCustomerModalOpened
  } = useContext(CustomerContext);
  const [customerDataSource, setcustomerDataSource] = useState([]);
  // console.log(customerDataSource);

  useEffect(() => {
    let isMounted = true;
    const getcustomer = async () => {
      try {
        setLoadingcustomer(true);
        let getcustomerRes;
        if (selectedFilter) {
          if (selectedFilter !== '8') {
            getcustomerRes = await getAllFatorah(user?.token, '444', searchText);
          } else {
            getcustomerRes = await getAllFatorah(user?.token, '444', searchText);
          }
        } else {
          getcustomerRes = await getAllFatorah(user?.token, '444', searchText);
        }

        if (getcustomerRes) {
          if (isMounted) {
            setAllcustomer(getcustomerRes);
            setLoadingcustomer(false);
          }
        }
      } catch (error) {
        setLoadingcustomer(false);
        console.log(error);
      }
    };
    getcustomer();

    return () => {
      isMounted = false;
    };
  }, [fetchCount, selectedFilter, searchText, setAllcustomer, setLoadingcustomer, user?.token]);

  useEffect(() => {
    let isMounted = true;

    if (allcustomer) {
      const mappedcustomer = allcustomer.map((ques) => ({
        ...ques,
        key: ques.id
      }));

      if (isMounted) {
        setcustomerDataSource(mappedcustomer);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allcustomer, allcustomer.length, fetchCount]);

  const [compoentType, setCompoentType] = useState('sollem');
  const cols = customerTableColumns(
    user?.type,
    setFetchCount,
    user?.token,
    setSelectedQuestion,
    setQuestionModalOpened
  );

  const expordDataToExcel = () => {
    let slicedCols = cols.slice(0, -2);
    console.log(cols);
    console.log(slicedCols);

    const excel = new Excel();

    excel
      .addSheet('Sheet 1')
      .addColumns(slicedCols)
      .addDataSource(customerDataSource, {
        str2Percent: true
      })
      .saveAs('عملاء سلم.xlsx');
  };
  let headers = [
    { label: '#', key: 'key' },
    { label: 'الاسم', key: 'name' },
    { label: 'رقم الهاتف', key: 'phone' },
    { label: 'البريد الألكتروني	', key: 'email' },
    { label: 'النشاط', key: 'activity' },
    // { label: 'نوع الباقه', key: 'package' },
    { label: 'أجمالي المبلغ', key: 'total' },
    // { label: 'الدفع', key: 'payment_status' },
    { label: ' اسم المسوق', key: 'influencer' },
    // { label: ' نوع الخدمه', key: 'subscriptionType' },
    { label: 'الرقم الضريبي', key: 'tax_number' },
    { label: 'السجل التجاري', key: 'commercialRecord' },
    { label: 'تاريخ البدايه', key: 'start_date' },
    { label: 'تاريخ النهايه', key: 'end_date' }
    // { label: 'عدد نقاط البيع', key: 'realNumPos' }
    // { label: 'قرب الانتهاء', key: 'expire_soon' }

    // { label: ' 	', key: 'email' }
  ];
  console.log(customerDataSource);

  return (
    <>
      <CSVLink
        data={customerDataSource}
        headers={headers}
        className="ant-btn     ant-btn-rtl "
        style={{
          marginBottom: '15px',
          backgroundColor: '#35bb71',
          color: '#fff',
          borderColor: '#35bb71'
        }}>
        <FileExcelOutlined style={{ marginLeft: '5px' }} /> تصدير اكسل
      </CSVLink>
      {/* <button
        onClick={expordDataToExcel}
        className="ant-btn  ant-btn-rtl "
        style={{
          marginBottom: '15px',
          backgroundColor: '#35bb71',
          color: '#fff',
          borderColor: '#35bb71'
        }}>
        <FileExcelOutlined style={{ marginLeft: '5px' }} /> تصدير اكسل{' '}
      </button> */}
      <Table
        pagination={{ pageSize: 10 }}
        className="customer-table"
        dataSource={customerDataSource}
        columns={customerTableColumns(
          user?.type,
          setFetchCount,
          user?.token,
          setSelectedQuestion,
          setQuestionModalOpened,
          setSelectedCustomer,
          setCustomerModalOpened,
          compoentType
        )}
        loading={loadingcustomer}
        scroll={{ x: 1200 }}
      />
      {questionModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedQuestion ? 'تعديل الباقه' : 'اضـافة الميزه جديدة'}
          visible={questionModalOpened}
          onOk={() => {
            setQuestionModalOpened(false);
            setSelectedQuestion(null);
          }}
          onCancel={() => {
            setQuestionModalOpened(false);

            setSelectedQuestion(null);
          }}
          footer={false}>
          <AddQuestionForm />
        </Modal>
      )}
    </>
  );
};

export default SollemPage;
