import axios from 'axios';

const createAbout = async (url, values, token, id, type) => {
  try {
    const { title, body, title_en, body_en, service_imgs, detailsIds} = values;
    const pickedValues = { title, body, title_en, body_en};
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    formData.append('type', type);
    detailsIds.forEach((id, index) => {
      formData.append(`detailsIds[${index}]`, id);
    });

    if (id !== 0) {
      formData.append('id', id);
    }
    if (service_imgs && service_imgs[0]) {
      formData.append('icon', service_imgs[0]);
    }
    const createQuestionsRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createQuestionsRes;
  } catch (error) {
    console.log(error);
  }
};

export default createAbout;
