/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './EditUserForm.scss';
// import UploadAdminFile from './UploadAdminFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import UpdateCustomerUser from '../../apis/customer-user-api/updateCustomerUser';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import { store } from 'react-notifications-component';

const defaultValues = {
  email: ''
};

const EditUserForm = ({ setTargetClient, targetClient, setAdminModalOpened, setReloadingData }) => {
  const { user } = useContext(UesrContext);

  let schema;
  if (targetClient?.id) {
    schema = Yup.object().shape({
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
      name: Yup.string().required('الاسم مطلوب'),

      status: Yup.string().required('الحاله مطلوب')
    });
  } else {
    schema = Yup.object().shape({
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
      name: Yup.string().required('الاسم مطلوب'),
      status: Yup.string().required('الحاله مطلوب')
    });
  }

  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    // register,
    watch,
    // unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  // console.log('errros: ', errors);
  // console.log('watch: ', watch('Admin_imgs'));

  const onSubmit = async (data) => {
    // console.log(data);
    let res = await UpdateCustomerUser(`/clients/${targetClient?.id}/update`, data, user?.token);
    // console.log(targetClient?.id);
    try {
      let res = await UpdateCustomerUser(`/clients/${targetClient?.id}/update`, data, user?.token);

      // console.log('create Admin response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء تعديل البيانات',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        setReloadingData(false);
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });
        setAdminModalOpened(false);
        setTargetClient({});
        setReloadingData(true);
        // setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تمت العملية بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  // handle initial values
  useEffect(() => {
    if (targetClient && targetClient?.id) {
      const { email, phone, status, name } = targetClient;
      // console.log(name);
      // console.log(status);
      // console.log(phone);
      // console.log(email);
      setValue('email', email);
      setValue('phone', phone);
      setValue('status', String(status));
      setValue('name', name);
    }
  }, [targetClient]);

  return (
    <Form
      className="add-Admin-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <AntdSelectOption
          name="status"
          errorMsg={errors?.status?.message}
          validateStatus={errors?.status ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نشط', value: '1' },
            { title: 'غير نشط', value: '0' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              حالة المستخدم
            </p>
          }
          formClassName="add-Admin-form"
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          تعديل
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default React.memo(EditUserForm);
