import axios from 'axios';

const createNfcCode = async (url, values, token, id) => {
  try {
    const { customer_id, tag_nfid, status, admin } = values;

    const formData = new FormData();
    if (customer_id) {
      formData.append('customer_id', customer_id);
    }
    if (tag_nfid) {
      formData.append('tag_nfid', tag_nfid);
    }
    if (status) {
      formData.append('status', status);
    }

    if (id !== 0) {
      formData.append('id', id);
    }

    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createNfcCode;
