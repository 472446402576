import axios from 'axios';

const getContact = async (token) => {
  try {
    const response = await axios.get(`/contacts`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    if (response.status === 200 && response.data.response_code === 200 && response.data.data) {
      return response.data.data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default getContact;
