import React, { useContext } from 'react';

import { useForm } from 'react-hook-form';

import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import addCategories from '../../../src/apis/category-apis/addCategories';
import { SendOutlined } from '@ant-design/icons';

import './AddCategoryForm.scss';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
const schema = yup.object().shape({
  name_ar: yup.string().required('الأسم بالعربي مطلوب'),
  name_en: yup.string().required('الأسم بالانجليزي مطلوب')
});

const defaultValues = {
  name_ar: '',
  name_en: ''
};

const AddCategoryForm = ({ count, setCount, setIsModalVisible }) => {
  const { user } = useContext(UesrContext);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [form] = Form.useForm();
  const onSubmit = async (data) => {
    try {
      const fetchedData = await addCategories(data, user?.token);
      if (!fetchedData) {
      } else {
        setCount(count + 1);
        setIsModalVisible(false);
        setValue('name_ar', '');
        setValue('name_en', '');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="add-project-form">
      <div className="form-field-wrapper">
        <p className="field-label">أسم القسم بالعربيه</p>
        <AntdTextField name="name_ar" type="text" control={control} />
        <p className="error-filed">{errors.name_ar?.message}</p>
      </div>
      <div className="form-field-wrapper">
        <p className="field-label">أسم القسم بالانجليزي</p>
        <AntdTextField name="name_en" type="text" control={control} />
        <p className="error-filed">{errors.name_en?.message}</p>
      </div>
      <Button
        className="submit-btn"
        htmlType="submit"
        type="primary"
        icon={<SendOutlined />}
        loading={isSubmitting}>
        تعديل القسم
      </Button>
    </Form>
  );
};

export default AddCategoryForm;
