import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingSelles: false,
  setLoadingSelles: (load) => {},
  allSelles: [],
  setAllSelles: (Selles) => {},
  SellesModalOpened: false,
  setSellesModalOpened: (v) => {},
  selectedServId: 0,
  setSelectedServId: (id) => {},
  selectedSelles: null,
  setSelectedSelles: (servKey) => {}
};

const SellesContext = createContext(INITIAL_VALUES);

export const SellesProvider = ({ children }) => {
  const [allSelles, setAllSelles] = useState(INITIAL_VALUES.allSelles);
  const [loadingSelles, setLoadingSelles] = useState(INITIAL_VALUES.loadingSelles);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [SellesModalOpened, setSellesModalOpened] = useState(INITIAL_VALUES.SellesModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedSelles, setSelectedSelles] = useState(INITIAL_VALUES.selectedSelles);

  return (
    <SellesContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingSelles,
        setLoadingSelles,
        allSelles,
        setAllSelles,
        SellesModalOpened,
        setSellesModalOpened,
        selectedServId,
        setSelectedServId,
        selectedSelles,
        setSelectedSelles
      }}>
      {children}
    </SellesContext.Provider>
  );
};

export default SellesContext;
