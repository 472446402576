/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import AboutContext from '../../contexts/aboutus-context/AboutUsProvider';
// import getAboutUsApi from '../../apis/about-us-api/getAboutUsApi';
import aboutColumns from './aboutTableColumns';
import getAllQuestions from '../../apis/questions/getAllQuestions';

const AboutTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingAboutData,
    setLoadingAboutData,
    allAboutData,
    setAllAboutData,
    setAboutModalOpened,
    setSelectedAboutId,
    setSelectedAbout
  } = useContext(AboutContext);
  const [aboutDataSource, setAboutDataSource] = useState([]);

  useEffect(() => {
    const getServices = async () => {
      try {
        setLoadingAboutData(true);
        const getServicesRes = await getAllQuestions(user?.token, 2);
        if (getServicesRes.status === 200 && getServicesRes?.data?.data) {
          setAllAboutData(getServicesRes.data.data);
          setLoadingAboutData(false);
        }
      } catch (error) {
        setLoadingAboutData(false);
        console.log(error);
      }
    };
    getServices();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allAboutData) {
      const mappedAboutData = allAboutData.map((serv) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setAboutDataSource(mappedAboutData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allAboutData, allAboutData.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="services-table"
      dataSource={aboutDataSource}
      columns={aboutColumns(
        user?.token,
        setFetchCount,
        setLoadingAboutData,
        setAboutModalOpened,
        setSelectedAboutId,
        setSelectedAbout
      )}
      loading={loadingAboutData}
      scroll={{ x: 1200 }}
    />
  );
};

export default AboutTable;
