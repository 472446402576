import moment from 'moment';
import React from 'react';

const ActivityLogTableColumns = () => {
  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'اسم المسئول',
      dataIndex: 'causer',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record?.causer }}></div>
        );
      }
    },
    {
      title: 'الوصف',
      dataIndex: 'description',
      width: 62,
      render: (_, record) => {
        return (
          <div
            className="service-img"
            dangerouslySetInnerHTML={{ __html: record?.description }}></div>
        );
      }
    },
    {
      title: 'الكود البرمجى',
      dataIndex: 'log_name',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record?.log_name }}></div>
        );
      }
    },
    {
      title: 'اسم الكائن',
      dataIndex: 'subject',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record?.subject }}></div>
        );
      }
    },

    {
      title: 'التاريخ',
      dataIndex: 'created_at',
      width: 62,
      render: (_, record) => {
        return (
          <div
            className="service-img"
            dangerouslySetInnerHTML={{
              __html: moment(record?.created_at).format('DD-MM-YYYY')
            }}></div>
        );
      }
    }
  ];
};

export default ActivityLogTableColumns;
