/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { stateToHTML } from 'draft-js-export-html';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import './BlogForm.scss';
import UploadQuestionFile from './UploadQuestionFile';

import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import createBlog from '../../apis/blog-api/createBlog';
import editBlog from '../../apis/blog-api/editBlog';
import BlogContext from '../../contexts/blog-context/BlogProvider';

const defaultValues = {
  title: '',
  body: '',
  service_imgs: null
};
const BlogForm = () => {
  const [descArEditorState, setDescArEditorState] = useState(EditorState.createEmpty());
  const [descEnEditorState, setDescEnEditorState] = useState(EditorState.createEmpty());
  const [titleArEditorState, settitleArEditorState] = useState(EditorState.createEmpty());
  const [titleEnEditorState, settitleEnEditorState] = useState(EditorState.createEmpty());
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setBlogModalOpened,
    allBlogData,
    setSelectedBlogId,
    selectedBlogId,
    selectedBlog,
    setSelectedBlog
  } = useContext(BlogContext);

  let schema;
  if (selectedBlog && selectedBlogId) {
    schema = Yup.object().shape({
      blog_titlear: Yup.string().required('من فضلك ادخل  عنوان المقالة باللغة العربية'),
      // blog_titleen: Yup.string().required('من فضلك ادخل  عنوان المقالة باللغة الانجليزية'),
      img_desc: Yup.string().required('من فضلك ادخل  وصف الصورة  '),

      // title: Yup.string().required('من فضلك ادخل اسم المقالة'),
      // title_en: Yup.string().required('من فضلك ادخل اسم المقالة بالانجليزي'),
      body: Yup.string().test('body', 'من فضلك ادخل تفاصيل المقالة باللغة العربية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      })
      // service_imgs: Yup.mixed().required('من فضلك اختار الصورة')
      // body_en: Yup.string().test('body_en', 'من فضلك ادخل تفاصيل المقالة باللغة الانجليزية', (v) => {
      //   let result = true;
      //   if (!v || v === '<p><br></p>') {
      //     result = false;
      //   }
      //   return result;
      // }),
    });
  } else {
    schema = Yup.object().shape({
      blog_titlear: Yup.string().required('من فضلك ادخل  عنوان المقالة باللغة العربية'),
      img_desc: Yup.string().required('من فضلك ادخل  وصف الصورة  '),
      // blog_titleen: Yup.string().required('من فضلك ادخل  عنوان المقالة باللغة الانجليزية'),

      // title: Yup.string().required('من فضلك ادخل اسم المقالة'),
      // title_en: Yup.string().required('من فضلك ادخل اسم المقالة بالانجليزي'),
      // // body: Yup.string().required('من فضلك ادخل وصف المقالة'),
      // // body_en: Yup.string().required('من فضلك ادخل وصف المقالة بالانجليزي'),
      body: Yup.string().test('body', 'من فضلك ادخل تفاصيل المقالة باللغة العربية', (v) => {
        let result = true;
        if (!v || v === '<p><br></p>') {
          result = false;
        }
        return result;
      }),
      // body_en: Yup.string().test('body_en', 'من فضلك ادخل تفاصيل المقالة باللغة الانجليزية', (v) => {
      //   let result = true;
      //   if (!v || v === '<p><br></p>') {
      //     result = false;
      //   }
      //   return result;
      // }),
      service_imgs: Yup.mixed().required('من فضلك اختار الصورة')
    });
  }

  useEffect(() => {
    if (selectedBlogId) {
      const foundData = allBlogData.find((d) => d.id === selectedBlogId);

      if (foundData) setSelectedBlog(foundData);
    }
  }, [selectedBlogId]);

  // const [urls, setUrls] = useState([]);

  // useEffect(() => {
  //   if (selectedBlog?.image) {
  //     setUrls([selectedBlog?.image]);
  //   }
  // }, [selectedBlog]);

  const {
    control,

    setValue,
    getValues,
    handleSubmit,
    register,
    watch,
    setError,
    unregister,
    clearErrors,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const onSubmit = async (values) => {
    try {
      // if (!getValues()?.body || getValues()?.body === '<p><br></p>') {
      //   setError('body', {
      //     type: 'required',
      //     message: 'من فضلك ادخل تفاصيل الخدمة باللغة العربية'
      //   });
      // } else {
      //   // setError('desc_ar', )
      //   clearErrors('body');
      // }

      let res;

      if (selectedBlog) {
        let modifiedData = {
          title: values.blog_titlear,
          body: values.body,
          img_desc: values.img_desc
        };
        if (typeof values.service_imgs === 'object') {
          modifiedData['photo'] = values.service_imgs[0];
        } else {
          modifiedData['photo'] = values.service_imgs;
        }

        res = await editBlog(`/blog-update?blog_id=${selectedBlog.id}`, modifiedData, user?.token);
      } else {
        // console.log(values)
        let modifiedData = {
          title: values.blog_titlear,
          body: values.body,
          photo: values.service_imgs[0],
          img_desc: values.img_desc
        };
        res = await createBlog('/blog-store', modifiedData, user?.token);
      }

      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشــاء ميزه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });
        // setValue('body', '');
        // setValue('body_en', '');
        setDescArEditorState(EditorState.createEmpty());
        setDescEnEditorState(EditorState.createEmpty());

        settitleArEditorState(EditorState.createEmpty());
        settitleEnEditorState(EditorState.createEmpty());

        setBlogModalOpened(false);
        setSelectedBlogId('');
        setSelectedBlog(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم حفظ البيانات المدخلة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  useEffect(() => {
    setValue('body', stateToHTML(descArEditorState.getCurrentContent()));
  }, [stateToHTML(descArEditorState.getCurrentContent())]);

  // useEffect(() => {
  //   setValue('body_en', stateToHTML(descEnEditorState.getCurrentContent()));
  // }, [stateToHTML(descEnEditorState.getCurrentContent())]);

  useEffect(() => {
    setValue('title', stateToHTML(titleArEditorState.getCurrentContent()));
  }, [stateToHTML(titleArEditorState.getCurrentContent())]);

  // useEffect(() => {
  //   setValue('title_en', stateToHTML(titleEnEditorState.getCurrentContent()));
  // }, [stateToHTML(titleEnEditorState.getCurrentContent())]);

  // handle initial values
  const [testVal, settestVal] = useState();
  useEffect(() => {
    if (selectedBlogId && selectedBlog) {
      const { title, photo, content, img_desc } = selectedBlog;
      // form.setFieldsValue({
      //   blog_titlear:title,
      // })
      setValue('blog_titlear', title);
      // setValue('title_en', title_en);
      setValue('body', content);
      setValue('service_imgs', photo);
      setValue('img_desc', img_desc);

      // setValue('body_en', body_en);
      // setValue('is_required', String(is_required));

      let newContent = content.replaceAll('<figure>', '').replace('</figure>', '');

      const contentBlockDescAr = htmlToDraft(newContent);

      if (contentBlockDescAr) {
        const contentState = ContentState.createFromBlockArray(contentBlockDescAr.contentBlocks);
        const editorStateDescAr = EditorState.createWithContent(contentState);

        setDescArEditorState(editorStateDescAr);
      }
      // const contentBlockDescEn = htmlToDraft(body_en);
      // if (contentBlockDescEn) {
      //   const contentState = ContentState.createFromBlockArray(contentBlockDescEn.contentBlocks);
      //   const editorStateDescEn = EditorState.createWithContent(contentState);
      //   setDescEnEditorState(editorStateDescEn);
      // }

      // const contentBlocktitleAr = htmlToDraft(title);
      // if (contentBlocktitleAr) {
      //   const contentState = ContentState.createFromBlockArray(contentBlocktitleAr.contentBlocks);
      //   const editorStatetitleAr = EditorState.createWithContent(contentState);
      //   settitleArEditorState(editorStatetitleAr);
      // }
      // const contentBlocktitleEn = htmlToDraft(title_en);
      // if (contentBlocktitleEn) {
      //   const contentState = ContentState.createFromBlockArray(contentBlocktitleEn.contentBlocks);
      //   const editorStatetitleEn = EditorState.createWithContent(contentState);
      //   settitleEnEditorState(editorStatetitleEn);
      // }
    }
  }, [selectedBlogId, selectedBlog]);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (selectedBlog?.photo) {
      setUrls([selectedBlog.photo]);
    }
  }, [selectedBlog]);
  return (
    <Form
      // initialValues={selectedQuestion && selectedQuestionId ? { ...watch() } : defaultValues}
      // initialValues={{
      //   cat_id: '2'
      // }}
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdTextField
          name="blog_titlear"
          type="text"
          label="  عنوان المقالة باللغة العربية"
          placeholder=" عنوان المقالة باللغة العربية..."
          errorMsg={errors?.blog_titlear?.message}
          validateStatus={errors?.blog_titlear ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />
        {/* <AntdTextField
          name="blog_titleen"
          type="text"
          label=" عنوان المقالة باللغة الانجليزية"
          placeholder="عنوان المقالة باللغة الانجليزية..."
          errorMsg={errors?.blog_titleen?.message}
          validateStatus={errors?.blog_titleen ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        /> */}
        <UploadQuestionFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="service_imgs"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة المقالة
            </>
          }
          urls={urls}
          errorMsg={errors?.service_imgs ? errors?.service_imgs?.message : ''}
        />
        <AntdTextField
          name="img_desc"
          type="text"
          label="  وصف الصورة  "
          placeholder=" وصف الصورة  ..."
          errorMsg={errors?.img_desc?.message}
          validateStatus={errors?.img_desc ? 'error' : ''}
          prefix={<EditOutlined />}
          control={control}
        />

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            وصف المقالة باللغة العربية
          </p>
          <Editor
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descArEditorState}
            onEditorStateChange={(v) => {
              setDescArEditorState(v);

              if (!getValues()?.body || getValues()?.body === '<p><br></p>') {
                // console.log("first")
                setError('body', {
                  type: 'required',
                  message: 'من فضلك ادخل وصف المقالة باللغة العربية'
                });
              } else {
                // setError('body', )
                clearErrors('body');
              }
            }}
          />
          <p className="error-p">{errors?.body ? errors?.body.message : ''}</p>
        </div>

        {/* <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            وصف المقالة باللغة الانجليزية
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={descEnEditorState}
            // onBlur={(v) => console.log(v)}
            onEditorStateChange={(v) => {
              setDescEnEditorState(v);
              if (!getValues()?.body_en || getValues()?.body_en === '<p><br></p>') {
                setError('body_en', {
                  type: 'required',
                  message: 'من فضلك ادخل وصف المقالة باللغة الانجليزية'
                });
              } else {
                // setError('body_en', )
                clearErrors('body_en');
              }
            }}
          />
          <p className="error-p">{errors?.body_en ? errors?.body_en.message : ''}</p>
        </div> */}
        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          {selectedBlogId ? 'تعديل المقالة' : ' انشاء مقالة'}
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(watch(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default BlogForm;
