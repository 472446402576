import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingMerchantsStats: false,
  setLoadingMerchantsStats: (load) => {},
  allMerchantsStats: null,
  setAllMerchantsStats: (merchantsstats) => {},
  serviceModalOpened: false,
  setServiceModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedService: null,
  setSelectedService: (servKey) => {}
};

const MerchantsStatsContext = createContext(INITIAL_VALUES);

export const MerchantsStatsProvider = ({ children }) => {
  const [allMerchantsStats, setAllMerchantsStats] = useState(INITIAL_VALUES.allMerchantsStats);
  const [loadingMerchantsStats, setLoadingMerchantsStats] = useState(INITIAL_VALUES.loadingMerchantsStats);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [serviceModalOpened, setServiceModalOpened] = useState(INITIAL_VALUES.serviceModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedService, setSelectedService] = useState(INITIAL_VALUES.selectedService);

  return (
    <MerchantsStatsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingMerchantsStats,
        setLoadingMerchantsStats,
        allMerchantsStats,
        setAllMerchantsStats,
        serviceModalOpened,
        setServiceModalOpened,
        selectedServId,
        setSelectedServId,
        selectedService,
        setSelectedService
      }}>
      {children}
    </MerchantsStatsContext.Provider>
  );
};

export default MerchantsStatsContext;
