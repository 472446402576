import axios from 'axios';

const deleteEmailApi = async (id, token) => {
  try {
    const response = await axios.post(`Emails/delete/${id}`, null, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    
    if (response.status === 200 && response.data.status === 1) {
      return response;
    }
  } catch (e) {
    console.log(e);
  }
};

export default deleteEmailApi;
