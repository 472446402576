/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './EditUserForm.scss';
// import UploadAdminFile from './UploadAdminFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { store } from 'react-notifications-component';
import updateOtpUser from '../../apis/otp-user-api/updateOtpUser';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import DateForm from './DateForm';

const defaultValues = {
  email: ''
};
const EditUserForm = ({ setTargetClient, targetClient, setAdminModalOpened, setReloadingData }) => {
  const { user } = useContext(UesrContext);
  const [targetDate, setTargetDate] = useState(null);
  let schema;
  if (targetClient?.id) {
    schema = Yup.object().shape({
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      otp: Yup.string().required('الكود مطلوب')
    });
  } else {
    schema = Yup.object().shape({
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      otp: Yup.string().required('الكود مطلوب')
    });
  }
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    // register,
    watch,
    // unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  // console.log('errros: ', errors);
  // console.log('watch: ', watch('Admin_imgs'));

  const onSubmit = async (data) => {
    // console.log(data);
    let dataPush = { ...data, expires_at: targetDate };
    // console.log(res);
    // // console.log(targetClient?.id);
    try {
      let res = await updateOtpUser(`/otp`, dataPush, user?.token);
      // console.log('create Admin response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأارسال البيانات',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        setReloadingData(false);
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });
        setAdminModalOpened(false);
        setTargetClient(null);
        setReloadingData(true);
        // setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تمت العملية بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  // handle initial values
  useEffect(() => {
    if (targetClient !== null) {
      // console.log(targetClient);
      const { otp, phone, expires_at } = targetClient;
      setTargetDate(expires_at);

      setValue('otp', otp);
      setValue('phone', phone);
    }
  }, [targetClient]);
  useEffect(() => {
    return () => {
      // console.log(targetDate);
      setTargetDate(null);
    };
  }, []);
  // console.log(targetClient);

  return (
    <Form
      className="add-Admin-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <div className="form-field-wrapper">
          <AntdTextField
            name="otp"
            type="text"
            placeholder="الكود..."
            errorMsg={errors?.otp?.message}
            validateStatus={errors?.otp ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            disabledType={targetClient !== null ? true : false}
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        {/* {targetClient?.phone ? (
          ''
        ) : (
          <div className="form-field-wrapper">
            <AntdTextField
              name="phone"
              type="text"
              placeholder="رقم الهاتف..."
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              prefix={<EditOutlined />}
              control={control}
            />
          </div>
        )} */}
        <div
        // style={{ direction: 'tlr' }}
        >
          <DateForm {...{ targetDate, setTargetDate }} />
        </div>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          {targetClient?.phone ? 'تعديل' : 'اضافة'}
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default React.memo(EditUserForm);
