import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Descriptions, Form } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { MessageBox, MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import * as Yup from 'yup';
import sendMsgTicketApi from '../../apis/TechnicalSupport/sendMsgTicketApi';
import SendIcon from '../../common/SendIcon';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import './TechnicalSupportPage.scss';

const schema = Yup.object().shape({
  content: Yup.string().optional('ادخل الرسالة')
});
const ChatSupportPage = () => {
  const { user } = useContext(UesrContext);
  let params = useParams();
  // console.log(params);
  const {
    selectedTechnicalSupport,
    setSelectedTechnicalSupport,
    setSelectedServId,
    setFetchCount,
    setAllMsgs,
    allMsgs
  } = useContext(TechnicalSupportContext);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      content: '',
      ticket_id: params?.id,
      to_id: ''
    }
  });
  const messageListReferance = React.createRef();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      let getTargetMessage = async () => {
        try {
          const response = await axios.get(`/tickets/${params?.id}`, {
            baseURL: process.env.REACT_APP_BASE_URL,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + user?.token
            }
          });

          if (response.data.response_code === 200 && response.data.data) {
            // console.log(response.data);
            // return response.data.data;
            setSelectedTechnicalSupport(response.data.data);
            setFetchCount((prevState) => prevState + 1);
          }
        } catch (e) {
          console.log(e);
        }
      };
      getTargetMessage();
    }

    return () => {
      isMounted = false;
    };
  }, [allMsgs]);

  const onSubmit = async (data) => {
    try {
      let res;

      res = await sendMsgTicketApi(user?.token, data);

      // console.log('create Selles response: ', res);
      if (res?.status === 200 && res?.data?.data) {
        setAllMsgs(res?.data?.data);
        setValue('content', '');
        setFetchCount((prevState) => prevState + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [form] = Form.useForm();
  // selectedTechnicalSupport?.status
  console.log(selectedTechnicalSupport?.status);
  const renderChatBox = () => {
    return (
      <div className="chat-box">
        <div className="chat-box-content">
          <MessageBox
            reply={{
              photoURL: 'https://facebook.github.io/react/img/logo.svg',
              title: 'فاتورة',
              titleColor: '#8717ae',
              message: 'مرحبا بك ف الدعم الفنى لفاتورة'
            }}
            onReplyMessageClick={() => console.log('reply clicked!')}
            position={'right'}
            type={'text'}
            text={'مرحبا بك ف الدعم الفنى لفاتورة'}
          />

          {selectedTechnicalSupport?.messages && (
            <MessageList
              referance={messageListReferance}
              className="message-list"
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={selectedTechnicalSupport?.messages}
              // dataSource={[
              //   {
              //       position: 'left',
              //       type: 'text',
              //       text: allMsgs?.content,
              //       date: new Date(),
              //   },
              // ]}
            />
          )}
        </div>
        {selectedTechnicalSupport?.status == 'open' ? (
          <Form
            className="message-shared-form"
            form={form}
            // layout="vertical"
            onFinish={handleSubmit(onSubmit)}>
            <div className="text-field-label-wrap">
              <div className="text-field-wrap">
                <AntdTextField
                  setValue={setValue}
                  className="form-text-field"
                  name="content"
                  type="text"
                  placeholder="إكتب رسالتك..."
                  errorMsg={errors?.content?.message}
                  validateStatus={errors?.content ? 'error' : ''}
                  control={control}
                />
              </div>
            </div>
            <Button
              disabled={watch('content') ? false : true}
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // loading={isSubmittingForm}
            >
              <SendIcon />
            </Button>
          </Form>
        ) : (
          <div className="text-field-label-wrap" style={{ padding: '10px 0' }}>
            <div style={{ width: '100%' }} className="err_message_chat">
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="rgba(0, 0, 0, 0.85)"
                  version="1.1"
                  id="Layer_1"
                  width="20px"
                  height="20px"
                  viewBox="0 0 92 92"
                  enable-background="new 0 0 92 92">
                  <path
                    id="XMLID_1517_"
                    d="M78.5,13.5c-17.9-17.9-47.1-17.9-65.1,0c-17.9,17.9-17.9,47.1,0,65.1c9,9,20.8,13.5,32.5,13.5  c11.8,0,23.6-4.5,32.5-13.5C96.5,60.6,96.5,31.4,78.5,13.5z M19.1,72.9C5.3,59,4.4,37,16.4,22.1l53.5,53.5  C55,87.6,33,86.8,19.1,72.9z M75.6,69.9L22.1,16.4C29,10.8,37.5,8,46,8c9.7,0,19.5,3.7,26.9,11.1C86.7,33,87.6,55,75.6,69.9z"
                  />
                </svg>
              </>
              لا يمكنك إرسال رسالة الان
            </div>
          </div>
        )}

        {/* <Form
          className="message-shared-form"
          form={form}
          // layout="vertical"
          onFinish={handleSubmit(onSubmit)}>
          <div className="text-field-label-wrap">
            <div className="text-field-wrap">
              <AntdTextField
                setValue={setValue}
                className="form-text-field"
                name="content"
                type="text"
                placeholder="إكتب رسالتك..."
                errorMsg={errors?.content?.message}
                validateStatus={errors?.content ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <Button
            disabled={watch('content') ? false : true}
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // loading={isSubmittingForm}
          >
            <SendIcon />
          </Button>
        </Form>
        <div style={{ width: '100%' }} className="err_message_chat">
          لا يمكنك إرسال رسالة الان
        </div> */}
      </div>
    );
  };
  // console.log(selectedTechnicalSupport);

  return (
    <div className="Selles-page">
      <h2 style={{ display: 'block', marginBottom: '20px' }}>رسائل الدعم</h2>
      <div
        className="chat_message_detail"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          // maxWidth: '100%'
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px'
        }}>
        <Descriptions title="" bordered column={1} style={{ flex: '1', width: '100%' }}>
          {selectedTechnicalSupport?.client?.name && (
            <Descriptions.Item
              label="الاسم"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.client?.name}
            </Descriptions.Item>
          )}
            {selectedTechnicalSupport?.client?.phone && (
            <Descriptions.Item
              label="رقم الهاتف"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.client?.phone}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.subject && (
            <Descriptions.Item
              label="الموضوع"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.subject}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.priority && (
            <Descriptions.Item
              label="الأولوية"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.priority}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.status && (
            <Descriptions.Item
              label="حالة"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.status}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.created_at && (
            <Descriptions.Item
              label="تاريخ النشاء"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.created_at}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.description && (
            <Descriptions.Item
              label="الوصف"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.description}
            </Descriptions.Item>
          )}
        </Descriptions>
        <div style={{ flex: '1', width: '100%' }}>
          <img src={selectedTechnicalSupport?.file} alt="" width={'100%'} height={'360px'} />
        </div>
      </div>
      {renderChatBox()}
    </div>
  );
};

export default ChatSupportPage;
