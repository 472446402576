import React, { useContext, useEffect, useState } from 'react';
import getAllStats from '../../apis/stats api/getAllStats';
import SupscriptionStatsContext from '../../contexts/supsctription-stats-context/SupscriptionStatsProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import SubscriptionStatsGrid from './SubscriptionStatsGrid';
import SupscriptionStatsCharts from './SupscriptionStatsCharts';
import { DatePicker } from 'antd';
import './SubscriptionStatsPage.scss';
const { RangePicker } = DatePicker;

const SubscriptionStatsPage = () => {
  const { user } = useContext(UesrContext);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const {
    setLoadingSupscriptionStats,
    setAllSupscriptionStats,
    allSupscriptionStats,
    fetchCount,
    setFetchCount
  } = useContext(SupscriptionStatsContext);

  useEffect(() => {
    getStats();
  }, [fetchCount, fromDate]);

  const getStats = async () => {
    try {
      setLoadingSupscriptionStats(true);
      if (fromDate) {
        let getStatsRes = await getAllStats(user?.token, { from: fromDate, to: toDate });

        if (getStatsRes.status === 200) {
          setLoadingSupscriptionStats(false);
          setAllSupscriptionStats(getStatsRes.data);
        }
      } else {
        let getStatsRes = await getAllStats(user?.token);

        if (getStatsRes.status === 200) {
          setLoadingSupscriptionStats(false);
          setAllSupscriptionStats(getStatsRes.data);
        }
      }
    } catch (error) {
      setLoadingSupscriptionStats(false);
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [allSupscriptionStats, fetchCount]);

  const handlePickerChange = (value, string) => {
    setFromDate(string[0]);
    setToDate(string[1]);
    // console.log('picker value', value, string);
  };
  // console.log("data: ",allSupscriptionStats)

  return (
    <div className="supscriptionstats">
      <h1 style={{ textAlign: 'center', fontSize: '1.7rem', marginBottom: '20px' }}>
        احصائيات الاشتراكات
      </h1>
      <RangePicker
        onChange={handlePickerChange}
        style={{ marginBottom: '15px' }}
        className={`filter-range-picker`}
        size="large"
        allowClear={true}
        placeholder={['تاريخ من', 'تاريخ الى']}
      />
      <SubscriptionStatsGrid allSupscriptionStats={allSupscriptionStats} />
      <SupscriptionStatsCharts allSupscriptionStats={allSupscriptionStats} />
      
    </div>
  );
};

export default SubscriptionStatsPage;
