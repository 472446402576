import React from 'react';
import InfoForm from './InfoForm';
import './InfoPage.scss';

function InfoPage() {
  return (
    <div className="Info-page">
      <InfoForm />
    </div>
  );
}

export default InfoPage;
