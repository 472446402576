import axios from 'axios';

const signoutApi = async (token) => {
  try {
    const signoutRes = await axios.post('/logout', null, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    
    return signoutRes;
  } catch (error) {
    console.log(error);
  }
};

export default signoutApi;
