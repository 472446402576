import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { AnswersProvider } from '../../contexts/answers-context/AnswersProvider';
import { EmailsProvider } from '../../contexts/emails-context/EmailsProvider';
import { QuestionsProvider } from '../../contexts/questions-context/QuestionsProvider';
import { ServicesProvider } from '../../contexts/services-context/ServicesProvider';
import AnswersPage from '../../pages/answers-page/AnswersPage';
import { CategoryPage } from '../../pages/category/CategoryPage';
import ContactUsPage from '../../pages/contactus-page/ContactUsPage';
import NewsLetterPage from '../../pages/news-letter-page/NewsLetterPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import ProfilePage from '../../pages/profile-page/ProfilePage';
import ProjectsPage from '../../pages/projects/ProjectsPage';
import QuestionsPage from '../../pages/questions-page/QuestionsPage';
import ServicesPage from '../../pages/services-page/ServicesPage';
import routerLinks from './routerLinks';
// import { FatorahProvider } from '../../contexts/fatorah-context/FatorahProvider';
// import FatorahPage from '../../pages/fatorah-page/FatorahPage';
import { AdminProvider } from '../../contexts/Admin-context/AdminProvider';
import { DaftarProvider } from '../../contexts/Daftar-context/DaftarProvider';
import { PackageDetailesProvider } from '../../contexts/PackageDetailes-context/PackageDetailesProvider';
import { PackagesProvider } from '../../contexts/Packages-context/PackagesProvider';
import { PaymentsProvider } from '../../contexts/Payments-context/PaymentsProvider';
import { SellesProvider } from '../../contexts/Selles-context/SellesProvider';
import { TechnicalSupportProvider } from '../../contexts/Technical-support/TechnicalSupportProvider';
import { AboutProvider } from '../../contexts/aboutus-context/AboutUsProvider';
import { BlogProvider } from '../../contexts/blog-context/BlogProvider';
import { CustomerProvider } from '../../contexts/customer-context/CustomerProvider';
import { MerchantsStatsProvider } from '../../contexts/merchants-stats-context/MerchantsStatsProvider';
import { NotificationCenterProvider } from '../../contexts/notification-center-context/NotificationCenterProvider';
import { PaymentLogsProvider } from '../../contexts/payment-logs-conrext/PaymentLogsProvider';
import { SupscriptionStatsProvider } from '../../contexts/supsctription-stats-context/SupscriptionStatsProvider';
import { SystemErrorProvider } from '../../contexts/systemErrors-context/SystemErrorProvider';
import AboutUsPage from '../../pages/about-us/AboutUsPage';
import AdminPage from '../../pages/admin-page/AdminPage';
import BlogPage from '../../pages/blog-page/BlogPage';
import CustomerPage from '../../pages/customer-page/CustomerPage';
import SollemPage from '../../pages/customer-page/SollemPage';
import CustomerUserPage from '../../pages/customer-user-page/CustomerUserPage';
import DaftarPage from '../../pages/daftar/DaftarPage';
import InfoPage from '../../pages/info-page/InfoPage';
import MerchantsStatsPage from '../../pages/merchants-stats-page/MerchantsStatsPage';
import NotificationCenterPage from '../../pages/notification-center/NotificationCenterPage';
import OtpUserPage from '../../pages/otp-users-page/OtpUserPage';
import PackageDetailesPage from '../../pages/package-detailes-page/PackageDetailesPage';
import PackagesPage from '../../pages/package-page/PackagesPage';
import PaymentLog from '../../pages/paymentLogs/PaymentLog';
import PaymentsPage from '../../pages/payments-page/PaymentsPage';
import SellesPage from '../../pages/selles-page/SellesPage';
import SubscriptionStatsPage from '../../pages/subscription-stats-page/SubscriptionStatsPage';
import SystemErrors from '../../pages/systemErrors-page/SystemErrors';
import TechnicalSupportPage from '../../pages/technicalSuport-page/TechnicalSuportPage';
import { PosPackagesProvider } from '../../contexts/Pos-Packages-context/PosPackagesProvider';
import PosPackagesPage from '../../pages/pos-package-page/PosPackagesPage';
import ChatSupportPage from '../../pages/technicalSuport-page/ChatSupportPage';
import NfcCustomers from '../../pages/nfc-customers-page/NfcCustomers';
import { NfcCustomersProvider } from '../../contexts/nfc-customers-context/NfcCustomersProvider';
import ActivityLog from '../../pages/activity-Log/ActivityLog';

const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  return (
    <Switch>
      <Route exact path={routerLinks.homePage} component={HomePage} />
      <Route exact path={routerLinks.categoryPage} component={CategoryPage} />
      <Route exact path={routerLinks.ProfilePage} component={ProfilePage} />
      <Route exact path={routerLinks.infoPage} component={InfoPage} />
      <Route exact path={routerLinks.servicesPage}>
        <ServicesProvider>
          <ServicesPage />
        </ServicesProvider>
      </Route>
      <Route exact path={routerLinks.aboutUsPage}>
        <AboutProvider>
          <AboutUsPage />
        </AboutProvider>
      </Route>
      <Route exact path={routerLinks.blogPage}>
        <BlogProvider>
          <AboutProvider>
            <BlogPage />
          </AboutProvider>
        </BlogProvider>
      </Route>
      <Route exact path={routerLinks.DaftarPage}>
        <DaftarProvider>
          <DaftarPage />
        </DaftarProvider>
      </Route>
      <Route exact path={routerLinks.AdminPage}>
        <AdminProvider>
          <AdminPage />
        </AdminProvider>
      </Route>
      <Route exact path={routerLinks.supportPage}>
        <SellesProvider>
          <SellesPage />
        </SellesProvider>
      </Route>
      <Route exact path={routerLinks.TechnicalSupport}>
        <TechnicalSupportProvider>
          <TechnicalSupportPage />
        </TechnicalSupportProvider>
      </Route>
      <Route exact path={routerLinks.chatSupport}>
        <TechnicalSupportProvider>
          <ChatSupportPage />
        </TechnicalSupportProvider>
      </Route>
      <Route exact path={routerLinks.paymentsPage}>
        <PaymentsProvider>
          <PaymentsPage />
        </PaymentsProvider>
      </Route>
      <Route exact path={routerLinks.PackagesPage}>
        <PackagesProvider>
          <PackagesPage />
        </PackagesProvider>
      </Route>
      <Route exact path={routerLinks.PackageDetailes}>
        <PackageDetailesProvider>
          <PackageDetailesPage />
        </PackageDetailesProvider>
      </Route>
      <Route exact path={routerLinks.PosPackagesPage}>
        <PosPackagesProvider>
          <PosPackagesPage />
        </PosPackagesProvider>
      </Route>
      <Route exact path={routerLinks.projectsPage} component={ProjectsPage} />
      <Route exact path={routerLinks.ContactUsPage} component={ContactUsPage} />
      <Route exact path={routerLinks.ContactUsPage} component={ContactUsPage} />
      <Route exact path={routerLinks.fatorahRequestPage}>
        <QuestionsProvider>
          <CustomerProvider>
            <CustomerPage />
          </CustomerProvider>
        </QuestionsProvider>
      </Route>
      <Route exact path={routerLinks.fatorahRequestUserDetailPage} component={CustomerUserPage} />
      <Route exact path={routerLinks.fatorahOtpUsers} component={OtpUserPage} />
      <Route exact path={routerLinks.SollemPage}>
        <QuestionsProvider>
          <CustomerProvider>
            <SollemPage />
          </CustomerProvider>
        </QuestionsProvider>
      </Route>
      <Route exact path={routerLinks.questionsPage}>
        <QuestionsProvider>
          <QuestionsPage />
        </QuestionsProvider>
      </Route>
      <Route exact path={routerLinks.answersPage}>
        <AnswersProvider>
          <AnswersPage />
        </AnswersProvider>
      </Route>
      <Route exact path={routerLinks.newsLetterPage}>
        <EmailsProvider>
          <NewsLetterPage />
        </EmailsProvider>
      </Route>
      <Route exact path={routerLinks.notificationCenterPage}>
        <NotificationCenterProvider>
          <CustomerProvider>
            <NotificationCenterPage />
          </CustomerProvider>
        </NotificationCenterProvider>
      </Route>
      <Route exact path={routerLinks.subscriptionStatspage}>
        <SupscriptionStatsProvider>
          <SubscriptionStatsPage />
        </SupscriptionStatsProvider>
      </Route>
      <Route exact path={routerLinks.merchantsStatsPage}>
        <MerchantsStatsProvider>
          <MerchantsStatsPage />
        </MerchantsStatsProvider>
      </Route>
      <Route exact path={routerLinks.PaymentLog}>
        <PaymentLogsProvider>
          <PaymentLog />
        </PaymentLogsProvider>
      </Route>
      <Route exact path={routerLinks.SystemErrors}>
        <SystemErrorProvider>
          <SystemErrors />
        </SystemErrorProvider>
      </Route>
      <Route exact path={routerLinks.NfcCustomers}>
        <NfcCustomersProvider>
          <NfcCustomers />
        </NfcCustomersProvider>
      </Route>
      <Route exact path={routerLinks.ActivityLog}>
        <ActivityLog />
      </Route>
      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
