/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import './BlogPage.scss';
import BlogForm from './BlogForm';
import BlogTable from './BlogTable';
import BlogContext from '../../contexts/blog-context/BlogProvider';
import BlogDetailsModel from './BlogDetailsModel';


const BlogPage = () => {
  const {
    blogModalOpened,
    setBlogModalOpened,
    setSelectedBlogId,
    selectedBlogId,
    setSelectedBlog,
    blogDetailsModalOpened,
    setBlogDetailsModalOpened,
    selectedBlog
  } = useContext(BlogContext);

  return (
    <div className="about-us-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setBlogModalOpened(true)}
          className="add-about-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة مقالة</span>
        </Button>

        {/* <ServicesFilter /> */}
      </div>

      <BlogTable />

      {blogModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedBlogId ? 'تعديل المقالة' : 'اضـافة المقالة جديدة'}
          visible={blogModalOpened}
          onOk={() => {
            setBlogModalOpened(false);
            setSelectedBlogId('');
            setSelectedBlog(null);
          }}
          onCancel={() => {
            setBlogModalOpened(false);
            setSelectedBlogId('');
            setSelectedBlog(null);
          }}
          footer={false}>
          <BlogForm />
        </Modal>
      )}

      {blogDetailsModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={"عرض التفاصيل"}
          visible={blogDetailsModalOpened}
          onOk={() => {
            setBlogDetailsModalOpened(false);
            setSelectedBlogId('');
            setSelectedBlog(null);
          }}
          onCancel={() => {
            setBlogDetailsModalOpened(false);
            setSelectedBlogId('');
            setSelectedBlog(null);
          }}
          footer={false}>
          <BlogDetailsModel selectedBlog={selectedBlog} />
        </Modal>
      )}
    </div>
  );
};

export default BlogPage;
