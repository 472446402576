import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import SellesContext from '../../contexts/Selles-context/SellesProvider';
import AddSellesForm from './AddSellesForm';
import SellesTable from './SellesTable';

import './SellesPage.scss';

const SellesPage = () => {
  const {
    SellesModalOpened,
    setSellesModalOpened,
    selectedServId,
    setSelectedServId,
    setSelectedSelles
  } = useContext(SellesContext);

  return (
    <div className="Selles-page">
      <div className="new-filter-wrap">
        <Button
          onClick={() => setSellesModalOpened(true)}
          className="add-serv-btn"
          type="primary"
          icon={<PlusOutlined />}>
          <span className="btn-text">اضافة الموظف</span>
        </Button>

        {/* <SellesFilter /> */}
      </div>

      <SellesTable />

      {SellesModalOpened && (
        <Modal
          className="add-Selles-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          title={selectedServId !== 0 ? 'تعديل الموظف' : 'اضافه الموظف '}
          visible={SellesModalOpened}
          onOk={() => {
            setSellesModalOpened(false);
            setSelectedServId(0);
            setSelectedSelles(null);
          }}
          onCancel={() => {
            setSellesModalOpened(false);
            setSelectedServId(0);
            setSelectedSelles(null);
          }}
          footer={false}>
          <AddSellesForm />
        </Modal>
      )}
    </div>
  );
};

export default SellesPage;
