import { DeleteOutlined, EditOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { store } from 'react-notifications-component';
import DeleteTechnicalSupport from '../../apis/TechnicalSupport/deleteTechnicalSupport';
import routerLinks from '../../components/app/routerLinks';
const PopOverAnswers = ({ content, title }) => (
  <Popover title={title}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);
const TechnicalSupportTableColumns = (
  token,
  setFetchCount,
  setLoadingSelles,
  setSellesModalOpened,
  setSelectedServId,
  setViewTicketModalOpened,
  setSelectedTechnicalSupport
) => {
  const userRoles = [
    { type: 'pending', name: 'pending' },
    { type: 'open', name: 'open' },
    { type: 'close', name: 'close' }
  ];
  let handleRoleFun = (el_data) => {
    // console.log(el_data.role);
    let targetRole = userRoles.map((el) => {
      if (el.type == el_data?.status) {
        return <div className={`role_support ${el.name}`}>{el.name}</div>;
      }
    });
    return targetRole;
  };

  const handleDeleteRequest = async (key) => {
    try {
      setLoadingSelles(true);
      const res = await DeleteTechnicalSupport(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingSelles(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف  بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSelles(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingSelles(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };
  const handleEditSelles = (el) => {
    setSellesModalOpened(true);
    setSelectedServId(el?.id);
    setSelectedTechnicalSupport(el);
  };
  const handleViewTicket = (el) => {
    setSelectedServId(el?.id);
    setSelectedTechnicalSupport(el);
  };
  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      width: 62,
      render: (_, record) => {
        // console.log( record);
        return record?.client?.name;
      }
    },
    // {
    //   title: 'النوع ',
    //   dataIndex: 'type',
    //   width: 62
    // },
    {
      title: 'رقم الهاتف',
      dataIndex: 'priority',
      width: 62,

      render: (_, record) => {
        // console.log(record);
        return record?.client?.phone;
      }
    },
    {
      title: 'الأولوية',
      dataIndex: 'priority',
      width: 62,

      render: (_, record) => {
        // console.log(record);
        return record?.priority;
      }
    },
    {
      title: 'الحالة',
      dataIndex: 'status',
      width: 62,
      render: (_, record) => {
        // console.log(record);
        return handleRoleFun(record);
      }
    },
    {
      title: 'تاريخ الإنشاء',
      dataIndex: 'start_date',
      width: 62,
      render: (_, record) => {
        // console.log(record);
        return record?.created_at ? record?.created_at : '----';
      }
    },
    {
      title: 'معاينة التذكرة',
      dataIndex: 'created_at',
      width: 62,
      render: (_, record) => {
        // console.log(record);
        return (
          <Link to={`/chat-support/${record?.id}`}>
            <Button
              size="large"
              type="dashed"
              onClick={() => {
                handleViewTicket(record);
              }}
              shape="circle"
              icon={<EyeTwoTone />}
            />
          </Link>
        );

        // return <PopOverAnswers title="تفاصيل الخاصيه" />;
      }
    },

    {
      title: 'تعديل ',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditSelles(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف ',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من الحذف </p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.id)}>
            <Tooltip title="حذف">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default TechnicalSupportTableColumns;
