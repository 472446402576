import React, { useContext, useEffect, useState } from 'react';
import NfcCustomersContext from '../../contexts/nfc-customers-context/NfcCustomersProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import getAllNfcCostumers from '../../apis/nfc-customers/getAllNfcCostumers';
import { Table } from 'antd';
import ActivityLogTableColumns from './ActivityLogTableColumns';
import getAllActivities from '../../apis/activity/getAllActivitiesApi';

const ActivityLogTable = () => {
  const { user } = useContext(UesrContext);
  const [activityLogsDataSource, setActivityLogsDataSource] = useState([]);
  const [loadingActivityLogs, setLoadingActivityLogs] = useState(false);
  const [allActivityLogsTable, setAllActivityLogsTable] = useState([]);

  useEffect(() => {
    const allActivities = async () => {
      try {
        setLoadingActivityLogs(true);
        const getAllActivitiesRes = await getAllActivities(user?.token);
        if (getAllActivitiesRes?.data?.response_code === 200 && getAllActivitiesRes?.data?.data) {
          setAllActivityLogsTable(getAllActivitiesRes.data.data);
          console.log(getAllActivitiesRes.data.data);
          setLoadingActivityLogs(false);
        }
       
      } catch (error) {
        setLoadingActivityLogs(false);
        console.log(error);
      }
    };
    allActivities();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (allActivityLogsTable) {
      const mappedActivityLogs = allActivityLogsTable.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setActivityLogsDataSource(mappedActivityLogs);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allActivityLogsTable, allActivityLogsTable?.length]);
  return (
    <Table
      pagination={{ pageSize: 10 }}
      showSizeChanger={false}
      className="activityLog-table"
      dataSource={activityLogsDataSource}
      columns={ActivityLogTableColumns()}
      loading={loadingActivityLogs}
      scroll={{ x: 1200 }}
    />
  );
};

export default ActivityLogTable;
