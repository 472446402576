import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingBlogData: false,
  setLoadingBlogData: (load) => {},
  allBlogData: [],
  setAllBlogData: (v) => {},
  blogModalOpened: false,
  setBlogModalOpened: (v) => {},
  blogDetailsModalOpened: false,
  setBlogDetailsModalOpened: (v) => {},
  selectedBlogId: '',
  setSelectedBlogId: (id) => {},
  selectedBlog: null,
  setSelectedBlog: (servKey) => {}
};

const BlogContext = createContext(INITIAL_VALUES);

export const BlogProvider = ({ children }) => {
  const [allBlogData, setAllBlogData] = useState(INITIAL_VALUES.allBlogData);
  const [loadingBlogData, setLoadingBlogData] = useState(INITIAL_VALUES.loadingBlogData);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [blogModalOpened, setBlogModalOpened] = useState(INITIAL_VALUES.blogModalOpened);
  const [blogDetailsModalOpened, setBlogDetailsModalOpened] = useState(INITIAL_VALUES.blogDetailsModalOpened);
  const [selectedBlogId, setSelectedBlogId] = useState(INITIAL_VALUES.selectedBlogId);
  const [selectedBlog, setSelectedBlog] = useState(INITIAL_VALUES.selectedBlog);

  return (
    <BlogContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingBlogData,
        setLoadingBlogData,
        allBlogData,
        setAllBlogData,
        blogModalOpened,
        setBlogModalOpened,
        selectedBlogId,
        setSelectedBlogId,
        selectedBlog,
        setSelectedBlog,
        blogDetailsModalOpened,
        setBlogDetailsModalOpened
      }}>
      {children}
    </BlogContext.Provider>
  );
};

export default BlogContext;
