import { EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import React from 'react';

const SystemErrorsTableColumns = (
  token,
  setFetchCount,
  setLoadingSystemErrorsData,
  setSelectedSystemErrorsId,
  setSystemErrorsDetailsModalOpened,
  setSelectedSystemErrors
) => {
  const handleViewBlogDetailsModal = (record) => {
    setSelectedSystemErrors(record);
    setSystemErrorsDetailsModalOpened(true);
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      key: 'id'
    },
    {
      title: 'الرسايل',
      dataIndex: 'message',
      key: 'message',
      width: 62,
      render: (_, record) => {
        return (
          <div
            className="service-img"
            style={{ direction: 'ltr' }}
            dangerouslySetInnerHTML={{ __html: record.message ? record.message : '---' }}></div>
        );
      }
    },
    {
      title: 'التاريخ',
      dataIndex: 'date',
      key: 'date',
      width: 62,
      render: (_, record) => {
        return (
          <div
            className="service-img"
            dangerouslySetInnerHTML={{ __html: record.created_at.slice(0, 10) }}></div>
        );
      }
    },
    // {
    //   title: 'line',
    //   dataIndex: 'line',
    //   key: 'line',
    //   width: 62,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-img" dangerouslySetInnerHTML={{ __html: record.total }}></div>
    //     );
    //   }
    // },
    // {
    //   title: 'التتبع',
    //   dataIndex: 'trace',
    //   key: 'trace',
    //   width: 62,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-img" dangerouslySetInnerHTML={{ __html: record.total }}></div>
    //     );
    //   }
    // },
    // {
    //   title: 'Created_at',
    //   dataIndex: 'created',
    //   key: 'created',
    //   width: 62,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-img" dangerouslySetInnerHTML={{ __html: record.total }}></div>
    //     );
    //   }
    // },
    // {
    //   title: 'Updated_at',
    //   dataIndex: 'updated',
    //   key: 'updated',
    //   width: 62,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-img" dangerouslySetInnerHTML={{ __html: record.total }}></div>
    //     );
    //   }
    // },
    {
      title: 'التفاصيل',
      dataIndex: 'details',
      width: 82,
      render: (_, record) => {
        return (
          <Tooltip title="تفاصيل الخطأ">
            <Button
              className="edit-btn SystemErrors-btn"
              onClick={() => handleViewBlogDetailsModal(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EyeOutlined />}
            />
          </Tooltip>
        );
      }
    }
  ];
};

export default SystemErrorsTableColumns;
