import React, { useState, useContext, useEffect } from 'react';
// import { PlusOutlined } from '@ant-design/icons';

// import { CategoryTable } from '../../components/category-page-components/CategoryTable';
import AddCategoryForm from '../../components/category-page-components/AddCategoryForm';
import PopUp from '../../common/popup/PopUp';
// import { Button } from 'antd';

import './CategoryPage.scss';
import EditCategoryForm from '../../components/category-page-components/EditCategoryForm';
import UesrContext from '../../contexts/user-context/UserProvider';
import singleAdmin from '../../apis/Admin/singleAdmin';

export const CategoryPage = () => {
  const [count, setCount] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);

  const { user } = useContext(UesrContext);
  const [category, setcategory] = useState({});

  useEffect(() => {
    const getAdmin = async () => {
      try {
        const getAdminRes = await singleAdmin(user?.token, user?.id);
        
        setcategory(getAdminRes.data);
        // if (getAdminRes.status === 200 && getAdminRes?.data?.data) {
        // setAllAdmin(getAdminRes);
        // setLoadingAdmin(false);
        // }
      } catch (error) {
        // setLoadingAdmin(false);
        console.log(error);
      }
    };
    getAdmin();
  }, [user?.token, user?.id]);
  // console.log(category.balance);
  const renderbalance = () => {
    if (category) {
      return <div>الرصيد الخاص بك: {category.balance}</div>;
    } else {
      return <div>الرصيد الخاص بك: {category.balance}</div>;
    }
  };
  return (
    <div className="category-page">
      {renderbalance()}
      {/* <Button onClick={showModal} className="add-btn" type="primary" icon={<PlusOutlined />}>
        <span className="btn-text">اضافة قسم</span>
      </Button> */}
      <PopUp
        children={
          <AddCategoryForm
            count={count}
            setCount={setCount}
            setIsModalVisible={setIsModalVisible}
          />
        }
        title="أضافه قسم"
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />

      <PopUp
        children={<EditCategoryForm count={count} setCount={setCount} />}
        title="تعديل قسم"
        setIsModalVisible={setIsEditModalVisible}
        isModalVisible={isEditModalVisible}
      />

      {/* <CategoryTable
        count={count}
        setCount={setCount}
        setIsEditModalVisible={setIsEditModalVisible}
        isEditModalVisible={isEditModalVisible}
        setcategory={setcategory}
      /> */}
    </div>
  );
};
