import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingDaftarData: false,
  setLoadingDaftarData: (load) => {},
  allDaftarData: [],
  setAllDaftarData: (v) => {},
  DaftarModalOpened: false,
  setDaftarModalOpened: (v) => {},
  selectedDaftarId: '',
  setSelectedDaftarId: (id) => {},
  selectedDaftar: null,
  setSelectedDaftar: (servKey) => {}
};

const DaftarContext = createContext(INITIAL_VALUES);

export const DaftarProvider = ({ children }) => {
  const [allDaftarData, setAllDaftarData] = useState(INITIAL_VALUES.allDaftarData);
  const [loadingDaftarData, setLoadingDaftarData] = useState(INITIAL_VALUES.loadingDaftarData);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [DaftarModalOpened, setDaftarModalOpened] = useState(INITIAL_VALUES.DaftarModalOpened);
  const [selectedDaftarId, setSelectedDaftarId] = useState(INITIAL_VALUES.selectedDaftarId);
  const [selectedDaftar, setSelectedDaftar] = useState(INITIAL_VALUES.selectedDaftar);

  return (
    <DaftarContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingDaftarData,
        setLoadingDaftarData,
        allDaftarData,
        setAllDaftarData,
        DaftarModalOpened,
        setDaftarModalOpened,
        selectedDaftarId,
        setSelectedDaftarId,
        selectedDaftar,
        setSelectedDaftar
      }}>
      {children}
    </DaftarContext.Provider>
  );
};

export default DaftarContext;
