/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddSellesForm.scss';
// import UploadSellesFile from './UploadSellesFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import UesrContext from '../../contexts/user-context/UserProvider';
import SellesContext from '../../contexts/Selles-context/SellesProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import createSelles from '../../apis/Selles/createSelles';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';

const defaultValues = {
  email: '',
  password: ''
};

const AddSellesForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setSellesModalOpened,
    allSelles,
    setSelectedServId,
    selectedServId,
    selectedSelles,
    setSelectedSelles
  } = useContext(SellesContext);
  let schema;
  if (selectedServId !== 0) {
    schema = Yup.object().shape({
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
      name: Yup.string().required('الاسم مطلوب'),
      // discount: Yup.string().required('الخصم مطلوب'),
      // my_ratio: Yup.string().required('نسبة الموظف مطلوب'),
      // code: Yup.string().required('الكود مطلوب'),
      // platform: Yup.string().required('المنصه مطلوب'),
      status: Yup.string().required('الحاله مطلوب')
    });
  } else if (selectedServId === 0) {
    schema = Yup.object().shape({
      password: Yup.string().required('الرقم السري مطلوب'),
      phone: Yup.string()
        .required('من فضلك ادخل رقم الهاتف')
        .matches(/^[0-9]+$/, 'لا يسمح الا بالارقــام'),
      email: Yup.string().required('البريد الألكتروني').email('من فضلك ادخل بريد الكترونى صحيح'),
      name: Yup.string().required('الاسم مطلوب'),
      // discount: Yup.string().required('الخصم مطلوب'),
      // my_ratio: Yup.string().required('نسبة الموظف مطلوب'),
      // code: Yup.string().required('الكود مطلوب'),
      // platform: Yup.string().required('المنصه مطلوب'),
      status: Yup.string().required('الحاله مطلوب')
    });
  }

  // console.log(selectedSelles);
  // console.log(selectedServId);
  useEffect(() => {
    // console.log(selectedServId);
    // console.log(allSelles);
    if (selectedServId) {
      const foundSelles = allSelles.find((serv) => serv.id === selectedServId);
      if (foundSelles) setSelectedSelles(foundSelles);
    }
  }, [selectedServId]);

  // const [urls, setUrls] = useState([]);
  // useEffect(() => {
  //   if (selectedSelles?.icon) {
  //     console.log('selected Selles: ', selectedSelles);
  //     setUrls([selectedSelles.icon]);
  //   }
  // }, [selectedSelles]);
  
  const {
    control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    // register,
    watch,
    // unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  // console.log('errros: ', errors);
  // console.log('watch: ', watch('Selles_imgs'));

  const onSubmit = async (data) => {
    // console.log(selectedSelles);
    try {
      let res;
      if (selectedSelles) {
        res = await createSelles(`/Admin/update`, data, user?.token, selectedSelles.id, 3);
      } else {
        res = await createSelles('/Admin/create', data, user?.token, 0, 3);
      }
      // console.log('create Selles response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء موظف',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setSellesModalOpened(false);
        setSelectedServId(0);
        setSelectedSelles(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء موظف المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedSelles && selectedServId) {
      const { email, password, phone, status, name } = selectedSelles;
      setValue('email', email);
      setValue('password', password);

      setValue('phone', phone);
      setValue('status', String(status));
      setValue('name', name);
    }
  }, [selectedSelles, selectedServId]);

  return (
    <Form
      className="add-Selles-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <div className="form-field-wrapper">
          <AntdTextField
            name="email"
            type="text"
            placeholder="البريد الألكتروني..."
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="password"
            type="password"
            placeholder="الرقم السري..."
            errorMsg={errors?.password?.message}
            validateStatus={errors?.password ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <div className="form-field-wrapper">
          <AntdTextField
            name="phone"
            type="text"
            placeholder="رقم الهاتف..."
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>
        <div className="form-field-wrapper">
          <AntdTextField
            name="name"
            type="text"
            placeholder="الاسم..."
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<EditOutlined />}
            control={control}
          />
        </div>

        <AntdSelectOption
          name="status"
          errorMsg={errors?.status?.message}
          validateStatus={errors?.status ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'نشط', value: '1' },
            { title: 'غير نشط', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              حالة الموظف
            </p>
          }
          formClassName="add-Selles-form"
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة موظف
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>  
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddSellesForm;
