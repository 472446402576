import './Logo.scss';
import LogoImg from '../../assets/imgs/icons/Idea.png'
import React from 'react';
import { Link } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
import { useContext } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';

const Logo = ({ className }) => {
  const { user } = useContext(UesrContext);
  if (user?.type) {
    if (user?.type === 1) {
      return (
        <Link className={className} to={routerLinks?.homePage}>
          <img src="/assets/imgs/logo/logo.png" alt="app logo" />
        </Link>
      );
    } else if (user?.type === 2) {
      return (
        <Link className={className} to={routerLinks?.categoryPage}>
          <img src="/assets/imgs/logo/logo.png" alt="app logo" />
        </Link>
      );
    } else if (user?.type === 3) {
      return (
        <Link className={className} to={routerLinks?.servicesPage}>
          <img src="/assets/imgs/logo/logo.png" alt="app logo" />
        </Link>
      );
    } else {
      return (
        <Link className={className} to={routerLinks?.homePage}>
          <img src="/assets/imgs/logo/logo.png" alt="app logo" />
        </Link>
      );
    }
  } else {
    return (
      <Link className={className} to={routerLinks?.homePage}>
        <img src="/assets/imgs/logo/logo.png" alt="app logo" />
      </Link>
    );
  }
};

export default Logo;
