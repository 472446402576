import { Modal } from 'antd';
import React, { useContext } from 'react';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';
import ViewTechnicalSupport from './ViewTechnicalSupport';

const SelectTicketSupportModal = () => {
  const {
    ViewTicketModalOpened,
    setViewTicketModalOpened,
    selectedServId,
    setSelectedServId,
    setSelectedTechnicalSupport
  } = useContext(TechnicalSupportContext);
  return (
    <>
      <Modal
        className="add-Selles-modal"
        width="90%"
        style={{ maxWidth: '900px' }}
        title={'عرض التفاصيل'}
        visible={ViewTicketModalOpened}
        onOk={() => {
          setViewTicketModalOpened(false);
          setSelectedServId(0);
          setSelectedTechnicalSupport(null);
        }}
        onCancel={() => {
          setViewTicketModalOpened(false);
          setSelectedTechnicalSupport(null);
        }}
        footer={false}>
        <ViewTechnicalSupport />
      </Modal>
    </>
  );
};

export default SelectTicketSupportModal;
