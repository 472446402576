/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import sendEmailsMessageApi from '../../apis/emails-apis/sendEmailsMessageAp';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import EmailsContext from '../../contexts/emails-context/EmailsProvider';

const defaultValues = {
  title: '',
  message: ''
};

const schema = Yup.object().shape({
  title: Yup.string().required('من فضلك عنوان الرسالة'),
  message: Yup.string().test('message', 'من فضلك ادخل تفاصيل الرســالة', (v) => {
    let result = true;
    if (!v || v === '<p><br></p>') {
      result = false;
    }
    return result;
  })
});

const SendEmailsMessageForm = () => {
  const { user } = useContext(UesrContext);
  const { setEmailsModalOpened } = useContext(EmailsContext);
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    setError,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });
  const [messageEditorState, setMessageEditorState] = useState(EditorState.createEmpty());

  const onSubmit = async (data) => {
    try {
      const res = await sendEmailsMessageApi(data, user?.token);
      // console.log('res: ', res);
      if (res.status === 200 && res.data.status === 0) {
        store.addNotification({
          title: 'حدث خطأ حاول فى وقت لاحق',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.status === 1) {
        reset({ ...defaultValues });
        setMessageEditorState(EditorState.createEmpty());
        setEmailsModalOpened(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم ارســال الرسالة بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setValue('message', stateToHTML(messageEditorState.getCurrentContent()));
  }, [stateToHTML(messageEditorState.getCurrentContent())]);

  const [form] = Form.useForm();
  return (
    <Form
      className="send-emails-message-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdTextField
          name="title"
          type="text"
          placeholder={'عنوان الرسالة...'}
          label="عنوان الرســالة"
          errorMsg={errors?.title?.message}
          validateStatus={errors?.title ? 'error' : ''}
          prefix={<UserOutlined />}
          control={control}
        />

        <div className="editor-wrapper">
          <p className="editor-title">
            <EditOutlined />
            تفاصيل الرســالة
          </p>
          <Editor
            toolbar={{
              options: ['inline', 'fontSize', 'fontFamily', 'list', 'emoji', 'image']
            }}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            editorState={messageEditorState}
            // onBlur={(v) => console.log(v)}
            onEditorStateChange={(v) => {
              setMessageEditorState(v);
              if (!getValues()?.message || getValues()?.message === '<p><br></p>') {
                setError('message', {
                  type: 'required',
                  message: 'من فضلك ادخل تفاصيل الرســالة'
                });
              } else {
                // setError('message', )
                clearErrors('message');
              }
            }}
          />
          <p className="error-p">{errors?.message ? errors?.message.message : ''}</p>
        </div>

        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          إرســــال
        </Button>
      </div>
    </Form>
  );
};

export default SendEmailsMessageForm;
