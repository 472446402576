/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form, Input } from 'antd';
import { store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import updateCustomerPasswordApi from '../../apis/customer/updatePasswordApi';
import './updatePasswordForm.scss';
import { LockOutlined } from '@ant-design/icons';

const schema = Yup.object().shape({});

const defaultValues = {};
const UpdatePasswordCustomerForm = () => {
  const { user } = useContext(UesrContext);

  const { setCustomerModalOpened, selectedCustomer, setSelectedCustomer } =
    useContext(CustomerContext);

  const {
    control,
    setValue,
    handleSubmit,
    reset,

    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();
  const onSubmit = async (data) => {
    try {
      let modifiedData = { ...data, ref_key: selectedCustomer?.ref_key };
      let res;
      res = await updateCustomerPasswordApi(modifiedData, user?.token);

      if (res.status === 200 && res.data.status === 0) {
        store.addNotification({
          title: 'حدث خطأ اثناء  تعديل كلمة المرور',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.status === 1) {
        reset({ ...defaultValues });
        setCustomerModalOpened(false);
        setSelectedCustomer(null);
        // setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم تعديل كلمة المرور ',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Form className="update_password_form" form={form} layout="vertical" onFinish={onSubmit}>
      <div className="form-body">
        <Form.Item
          name="password"
          label="كلمة المرور"
          rules={[
            {
              required: true,
              message: '   من فضلك ادخل كلمة المرور !'
            },
            {
              min: 6,
              message: '   من فضلك ادخل كلمة المرور اكثر من 5 احرف!'
            }
          ]}
          hasFeedback>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="كلمة المرور"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="تأكيد كلمة المرور "
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '   من فضلك ادخل كلمة المرور !'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('كلمتا المرور اللتان أدخلتهما غير متطابقتين ! '));
              }
            })
          ]}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="تأكيد كلمة المرور "
          />
        </Form.Item>

        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          تعديل
        </Button>
      </div>
    </Form>
  );
};

export default UpdatePasswordCustomerForm;
