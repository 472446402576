/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import getAllPackageDetailes from '../../apis/PackageDetailes/getAllPackageDetailes';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import PackageDetailesColumns from './PackageDetailesTableColumns';
import PackageDetailesContext from '../../contexts/PackageDetailes-context/PackageDetailesProvider';
const PackageDetailesTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingPackageDetailes,
    setLoadingPackageDetailes,
    allPackageDetailes,
    setAllPackageDetailes,
    setPackageDetailesModalOpened,
    setSelectedServId
  } = useContext(PackageDetailesContext);
  const [PackageDetailesDataSource, setPackageDetailesDataSource] = useState([]);

  useEffect(() => {
    const getPackageDetailes = async () => {
      try {
        setLoadingPackageDetailes(true);
        const getPackageDetailesRes = await getAllPackageDetailes(user?.token);
        // if (getPackageDetailesRes.status === 200 && getPackageDetailesRes?.data?.data) {
        setAllPackageDetailes(getPackageDetailesRes);
        setLoadingPackageDetailes(false);
        // }
      } catch (error) {
        setLoadingPackageDetailes(false);
        console.log(error);
      }
    };
    getPackageDetailes();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allPackageDetailes) {
      const mappedPackageDetailes = allPackageDetailes.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setPackageDetailesDataSource(mappedPackageDetailes);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allPackageDetailes, allPackageDetailes?.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="PackageDetailes-table"
      dataSource={PackageDetailesDataSource}
      columns={PackageDetailesColumns(
        user?.token,
        setFetchCount,
        setLoadingPackageDetailes,
        setPackageDetailesModalOpened,
        setSelectedServId
      )}
      loading={loadingPackageDetailes}
      scroll={{ x: 1200 }}
    />
  );
};

export default PackageDetailesTable;
