import React, { createContext, useState } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingSystemErrorsData: false,
  setLoadingSystemErrorsData: (load) => {},
  allSystemErrorsData: [],
  setAllSystemErrorsData: (v) => {},
  SystemErrorsModalOpened: false,
  setSystemErrorsModalOpened: (v) => {},
  SystemErrorsDetailsModalOpened: false,
  setSystemErrorsDetailsModalOpened: (v) => {},
  selectedSystemErrorsId: '',
  setSelectedSystemErrorsId: (id) => {},
  selectedSystemErrors: null,
  setSelectedSystemErrors: (servKey) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const SystemErrorsContext = createContext(INITIAL_VALUES);

export const SystemErrorProvider = ({ children }) => {
  const [allSystemErrorsData, setAllSystemErrorsData] = useState(
    INITIAL_VALUES.allSystemErrorsData
  );
  const [loadingSystemErrorsData, setLoadingSystemErrorsData] = useState(
    INITIAL_VALUES.loadingSystemErrorsData
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [SystemErrorsModalOpened, setSystemErrorsModalOpened] = useState(
    INITIAL_VALUES.SystemErrorsModalOpened
  );
  const [SystemErrorsDetailsModalOpened, setSystemErrorsDetailsModalOpened] = useState(
    INITIAL_VALUES.SystemErrorsDetailsModalOpened
  );
  const [selectedSystemErrorsId, setSelectedSystemErrorsId] = useState(
    INITIAL_VALUES.selectedSystemErrorsId
  );
  const [selectedSystemErrors, setSelectedSystemErrors] = useState(
    INITIAL_VALUES.selectedSystemErrors
  );
  const [tablePagination, setTablePagination] = useState(INITIAL_VALUES?.tablePagination);

  return (
    <SystemErrorsContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingSystemErrorsData,
        setLoadingSystemErrorsData,
        allSystemErrorsData,
        setAllSystemErrorsData,
        SystemErrorsModalOpened,
        setSystemErrorsModalOpened,
        selectedSystemErrorsId,
        setSelectedSystemErrorsId,
        selectedSystemErrors,
        setSelectedSystemErrors,
        SystemErrorsDetailsModalOpened,
        setSystemErrorsDetailsModalOpened,
        tablePagination,
        setTablePagination
      }}>
      {children}
    </SystemErrorsContext.Provider>
  );
};

export default SystemErrorsContext;
