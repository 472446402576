import { Button, Popconfirm, Tooltip, Popover } from 'antd';
import { DeleteOutlined, EditOutlined, EyeTwoTone } from '@ant-design/icons';
import { store } from 'react-notifications-component';

import deleteQuestionApi from '../../apis/questions/deleteQuestion';

const PopOverAnswers = ({ content, title }) => (
  <Popover title={title} content={content}>
    <Button size="large" type="dashed" shape="circle" icon={<EyeTwoTone />} />
  </Popover>
);

const questionsColumns = (
  token,
  setFetchCount,
  setLoadingQuestions,
  setQuestionModalOpened,
  setSelectedQuestionId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingQuestions(true);
      const res = await deleteQuestionApi(key, token);
      // console.log(res);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingQuestions(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الخاصيه بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingQuestions(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingQuestions(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleEditQuestion = (key) => {
    setQuestionModalOpened(true);
    setSelectedQuestionId(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      key: 'id'
    },
    {
      title: 'صورة الخاصيه',
      dataIndex: 'image',
      width: 180,
      render: (_, record) => {
        return (
          <div className="service-img">
            <img
              src={`${process.env.REACT_APP_BASE_IMG_URL}/${record.icon}`}
              alt={record.id}
              width="100"
            />
          </div>
        );
      }
    },
    {
      title: 'الأسم ',
      dataIndex: 'title',
      key: 'title',
      width: 62
    },

    {
      title: 'تفاصيل الخاصيه',
      dataIndex: 'body',
      width: 82,
      render: (v) => {
        return <PopOverAnswers content={v} title="تفاصيل الخاصيه" />;
      }
    },

    {
      title: 'تعديل الخاصيه',
      dataIndex: 'edit',
      width: 92,
      key: 'edit',
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الخاصيه">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditQuestion(record.key)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف الخاصيه',
      dataIndex: 'delete',
      width: 92,
      key: 'delete',
      render: (_, record) => {
        return (
          <Popconfirm
            title="هل أنت متأكد من حذف الخاصيه؟"
            onConfirm={(key) => handleDeleteRequest(record.key)}
            okText="نعم"
            cancelText="لا">
            <Tooltip title="حذف الخاصيه؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default questionsColumns;
