import axios from 'axios';

const getAllActivities = async (token) => {
  try {
    const response = await axios.get(`/activityLogs`, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

 
      return response;
   
  } catch (e) {
    return e;
  }
};

export default getAllActivities;
