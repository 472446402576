import {
  AppstoreAddOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  BarcodeOutlined,
  CarryOutOutlined,
  CloudServerOutlined,
  CodeOutlined,
  CopyOutlined,
  CustomerServiceOutlined,
  DollarOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
  InteractionOutlined,
  LoadingOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  PhoneOutlined,
  PieChartOutlined,
  QuestionOutlined,
  SolutionOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Layout as AntdLayout, Breadcrumb, Button, Dropdown, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
// import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from 'antd/lib/avatar/avatar';
import slugify from 'slugify';
import signoutApi from '../../apis/auth/signout';
import Logo from '../../common/logo/Logo';
import UesrContext from '../../contexts/user-context/UserProvider';
import './Layout.scss';
import routerLinks from './routerLinks';

const AppLayout = ({ children }) => {
  const { user, removeCurrentUser } = useContext(UesrContext);
  const { pathname } = useLocation();
  const { i18n, t } = useTranslation();
  // console.log(slugify(pathname));
  // console.log(pathname);
  // const { Header, Content, Footer, Sider } = AntdLayout;
  const { Header, Content, Sider } = AntdLayout;
  const matches = useMediaQuery('(max-width:768px)');
  const [collapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const [openedSubmenu, setOpenedSubmenu] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed((prevState) => !prevState);
    setOpenedSubmenu(false);
  };
  let mainLinks;

  if (user.type === 1) {
    mainLinks = [
      {
        id: 1,
        path: routerLinks.homePage,
        name: 'الرئيســية',
        icon: <HomeOutlined />
      },
      {
        id: 2,
        path: routerLinks.paymentsPage,
        name: 'الدفع',
        icon: <PieChartOutlined />
      },

      {
        id: 5,
        path: routerLinks.questionsPage,
        name: 'ماهي فاتوره',
        icon: <QuestionOutlined />
      },

      {
        id: 7,
        path: routerLinks.aboutUsPage,
        name: 'مميزات فاتورة',
        icon: <CopyOutlined />
      },
      {
        id: 8,
        path: routerLinks.ContactUsPage,
        name: 'الرسايل',
        icon: <CustomerServiceOutlined />
      },
      {
        id: 9,
        path: routerLinks.PackagesPage,
        name: 'الباقات',
        icon: <MailOutlined />
      },
      {
        id: 6,
        path: routerLinks.PackageDetailes,
        name: 'تفاصيل الباقه',
        icon: <SolutionOutlined />
      },
      {
        id: 9,
        path: routerLinks.PosPackagesPage,
        name: 'باقات نقاط البيع',
        icon: <MailOutlined />
      },
      {
        id: 10,
        path: routerLinks.fatorahRequestPage,
        name: 'صاحب عمل',
        icon: <MailOutlined />
      },
      {
        id: 12,
        path: routerLinks.NfcCustomers,
        name: 'اكواد-nfc',
        icon: <BarcodeOutlined />
      },
      {
        id: 123,
        path: routerLinks.fatorahOtpUsers,
        name: 'اكواد الدخول',
        icon: <BarcodeOutlined />
      },
      {
        id: 333,
        path: routerLinks.SollemPage,
        name: ' عملاء سُلم',
        icon: <MailOutlined />
      },
      {
        id: 4,
        path: routerLinks.infoPage,
        name: 'المعلومات',
        icon: <CodeOutlined />
      },
      {
        id: 11,
        path: routerLinks.AdminPage,
        name: 'المسوقين',
        icon: <CodeOutlined />
      },
      {
        id: 3,
        path: routerLinks.supportPage,
        name: 'الدعم الفني',
        // name: 'تذاكر الدعم',

        icon: <PhoneOutlined />
      },
      {
        id: 24,
        path: routerLinks.TechnicalSupport,
        // name: 'TechnicalSupport',
        name: 'تذاكر الدعم',

        icon: <CarryOutOutlined />
      },
      {
        id: 18,
        path: routerLinks.DaftarPage,
        name: 'خدمه دفتر',
        icon: <CloudServerOutlined />
      },
      {
        id: 20,
        path: routerLinks.blogPage,
        name: 'المدونة',
        icon: <AppstoreAddOutlined />
      },
      {
        id: 21,
        path: routerLinks.notificationCenterPage,
        name: 'مركز الاشعارات',
        icon: <NotificationOutlined />
      },
      {
        id: 22,
        path: routerLinks.ActivityLog,
        name: 'سجل النشاطات',
        icon: <InteractionOutlined />
      },
      {
        id: 22,
        path: routerLinks.PaymentLog,
        name: 'المعاملات المالية',
        icon: <DollarOutlined />
      },
      {
        id: 23,
        path: routerLinks.SystemErrors,
        name: 'أخطاء النظام',
        icon: <ExclamationCircleOutlined />
      }
    ];
  } else if (user.type === 2) {
    mainLinks = [
      {
        id: 2,
        path: routerLinks.categoryPage,
        name: 'الرصيد',
        icon: <PieChartOutlined />
      },

      {
        id: 10,
        path: routerLinks.fatorahRequestPage,
        name: 'صاحب العمل',
        icon: <MailOutlined />
      }
    ];
  } else if (user.type === 3) {
    mainLinks = [
      {
        id: 2,
        path: routerLinks.servicesPage,
        name: 'الرئيسية',
        icon: <PieChartOutlined />
      },

      {
        id: 8,
        path: routerLinks.ContactUsPage,
        name: 'تواصل معنا',
        icon: <CustomerServiceOutlined />
      },

      {
        id: 10,
        path: routerLinks.fatorahRequestPage,
        name: 'صاحب العمل',
        icon: <MailOutlined />
      },
      {
        id: 24,
        path: routerLinks.TechnicalSupport,
        // name: 'TechnicalSupport',
        name: 'تذاكر الدعم',

        icon: <CarryOutOutlined />
      },
      {
        id: 123,
        path: routerLinks.fatorahOtpUsers,
        name: 'اكواد الدخول',
        icon: <BarcodeOutlined />
      }
    ];
  }

  const renderMainLinks = () => {
    return mainLinks?.map((link) => (
      <Menu.Item
        key={link?.id}
        icon={link?.icon}
        className={
          slugify(pathname) === slugify(link?.path) ||
          (pathname?.includes('fatorah-request/user/detail/') && slugify(link?.path)) ==
            'fatorah-request'
            ? 'ant-menu-item-selected'
            : ''
        }>
        <RouterLink
          to={link?.path}
          onClick={(e) => {
            if (matches == true) {
              toggleCollapsed();
            }
          }}>
          {link?.name}
        </RouterLink>
      </Menu.Item>
    ));
  };

  const [loadingSignout, setLoadingSignout] = useState(false);
  const history = useHistory();
  const handleSignout = async () => {
    try {
      setLoadingSignout(true);
      const res = await signoutApi(user?.token);

      if (res?.status === 200 && res?.data?.response_code === 200) {
        setLoadingSignout(false);
        removeCurrentUser();
        history.push(routerLinks?.homePage);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم تسجيل الخروج بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSignout(false);
        store.addNotification({
          title: 'حدث خطأ اثناء تسجيل الخروج',
          message: 'من فضلك حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      setLoadingSignout(false);
      store.addNotification({
        title: 'حدث خطأ اثناء تسجيل الخروج',
        message: 'من فضلك حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
      console.log(error);
    }
  };

  const [sidebarWidth, setSidebarWidth] = useState(252);

  let location = useLocation();

  useEffect(() => {
    if (window.innerWidth > 992) {
      if (
        location.pathname === routerLinks.subscriptionStatspage ||
        location.pathname === routerLinks.merchantsStatsPage
      ) {
        setOpenedSubmenu(true);
      } else {
        setOpenedSubmenu(false);
      }
    }
  }, [location]);

  let currentLink = '';
  let crumbs = location.pathname
    .split('/')
    .filter((crumb) => {
      return crumb != '';
    })
    .map((crumb) => {
      currentLink += `/ ${t(`${crumb}`)}`;
    });

  return (
    <div className={`app-layout app-${i18n.dir()}`}>
      <AntdLayout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          theme="light"
          width={sidebarWidth}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {}}>
          {/* <div className="logo" /> */}
          <Logo className="logo-link" />
          <Menu
            openKeys={openedSubmenu ? ['submenu'] : []}
            className="app-aside-menu"
            theme="light"
            mode="inline"
            selectable={false}>
            {renderMainLinks()}
            {user?.type === 1 ? (
              <Menu.SubMenu
                onTitleClick={() => setOpenedSubmenu(!openedSubmenu)}
                className="statics_sub_menu"
                title="الاحصائيات"
                icon={<AreaChartOutlined />}
                key="submenu">
                <Menu.Item
                  key={routerLinks?.subscriptionStatspage}
                  icon={<PieChartOutlined />}
                  className={
                    slugify(pathname) === slugify(routerLinks.subscriptionStatspage)
                      ? 'ant-menu-item-selected'
                      : ''
                  }>
                  <RouterLink to={routerLinks?.subscriptionStatspage}>
                    احصائيات الاشتراكات
                  </RouterLink>
                  className=
                  {slugify(pathname) === slugify(routerLinks.subscriptionStatspage)
                    ? 'ant-menu-item-selected'
                    : ''}
                  <RouterLink to={routerLinks.subscriptionStatspage}>
                    احصائيات الاشتراكات
                  </RouterLink>
                </Menu.Item>
                <Menu.Item
                  key={routerLinks.merchantsStatsPage}
                  icon={<BarChartOutlined />}
                  className={
                    slugify(pathname) === slugify(routerLinks.merchantsStatsPage)
                      ? 'ant-menu-item-selected'
                      : ''
                  }>
                  <RouterLink to={routerLinks.merchantsStatsPage}>احصائيات التجار</RouterLink>
                </Menu.Item>
              </Menu.SubMenu>
            ) : (
              ''
            )}
          </Menu>
        </Sider>
        <AntdLayout className="site-layout  app-site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {/* {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger-layout-btn',
              onClick: toggleCollapsed
            })} */}
            {collapsed ? (
              <MenuUnfoldOutlined className="trigger-layout-btn" onClick={toggleCollapsed} />
            ) : (
              <MenuFoldOutlined className="trigger-layout-btn" onClick={toggleCollapsed} />
            )}

            <div className="avatar-wrapper">
              <Dropdown
                trigger={['click']}
                disabled={loadingSignout}
                overlay={
                  <Menu>
                    <Menu.Item key="1" icon={<UserOutlined />}>
                      <RouterLink to={routerLinks.ProfilePage}>الملــف الشخصى</RouterLink>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<LogoutOutlined />} onClick={handleSignout}>
                      تسجيل الخروج
                    </Menu.Item>
                  </Menu>
                }>
                <Button className="profile-menu-btn" type="text">
                  {loadingSignout ? <LoadingOutlined /> : <DownOutlined />}
                  <span className="user-name">{user?.name}</span>
                  <Avatar size={38} icon={<UserOutlined />} src={user?.image} />
                </Button>
              </Dropdown>
            </div>
          </Header>
          <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>{currentLink}</Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              {children}
            </div>
          </Content>
          {/* <Footer style={{ textAlign: 'center' }}>Layout footer</Footer> */}
        </AntdLayout>
      </AntdLayout>
      {/* <ReactNotification className={i18n.dir()} /> */}
    </div>
  );
};

export default AppLayout;
