import React, { useContext, useEffect, useState } from 'react';
import getAllStats from '../../apis/stats api/getAllStats';
import UesrContext from '../../contexts/user-context/UserProvider';
import MerchantsStatsGrid from './MerchantsStatsGrid';
import MerchantsStatsCharts from './MerchantsStatsCharts';
import { DatePicker } from 'antd';
import './MerchantsStatsPage.scss';
import MerchantsStatsContext from '../../contexts/merchants-stats-context/MerchantsStatsProvider';
import getAllMerchantsStats from '../../apis/merchants api/getAllMerchantsStats';
import MerchantsStatsTable from './MerchantsStatsTable';
const { RangePicker } = DatePicker;

const MerchantsStatsPage = () => {
  const { user } = useContext(UesrContext);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const {
    setLoadingMerchantsStats,
    setAllMerchantsStats,
    allMerchantsStats,
    fetchCount,
    setFetchCount
  } = useContext(MerchantsStatsContext);

  useEffect(() => {
    getStats();
  }, [fetchCount, fromDate]);

  const getStats = async () => {
    try {
      setLoadingMerchantsStats(true);
      if (fromDate) {
        let getStatsRes = await getAllMerchantsStats(user?.token, { from: fromDate, to: toDate });
        if (getStatsRes.status === 200) {
          setLoadingMerchantsStats(false);
          setAllMerchantsStats(getStatsRes.data.data);
        }
      } else {
        let getStatsRes = await getAllMerchantsStats(user?.token);

        if (getStatsRes.status === 200 && getStatsRes.data.message === 'success') {
          setLoadingMerchantsStats(false);
          setAllMerchantsStats(getStatsRes.data.data);
        }
      }
    } catch (error) {
      setLoadingMerchantsStats(false);
      console.log(error);
    }
  };

  useEffect(() => {
    let isMounted = true;
    return () => {
      isMounted = false;
    };
  }, [allMerchantsStats, fetchCount]);

  const handlePickerChange = (value, string) => {
    setFromDate(string[0]);
    setToDate(string[1]);
    // console.log('picker value', value, string);
  };

  return (
    <div className="merchantsstats">
      <h1 style={{ textAlign: 'center', fontSize: '1.7rem', marginBottom: '20px' }}>
        احصائيات التجار
      </h1>
      <RangePicker
        onChange={handlePickerChange}
        style={{ marginBottom: '15px' }}
        className={`filter-range-picker`}
        allowClear={true}
        size="large"
        placeholder={['تاريخ من', 'تاريخ الى']}
      />
      <MerchantsStatsGrid allMerchantsStats={allMerchantsStats} />
      <MerchantsStatsCharts allMerchantsStats={allMerchantsStats} />
      <MerchantsStatsTable allMerchantsStats={allMerchantsStats} />
    </div>
  );
};

export default MerchantsStatsPage;
