import axios from 'axios';

const DeleteTechnicalSupport = async (id, token) => {
  console.log(token);
  console.log(id);
  try {
    // const formData = new FormData();

    // if (AdminId) {
    //   formData.append('id', AdminId);
    // }
    const response = await axios.post(
      `tickets/${id}/delete`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    // if (response.status === 200 && response.data.status === 1) {
    return response;
    // }
  } catch (e) {
    console.log(e);
  }
};

export default DeleteTechnicalSupport;
