/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddPaymentForm.scss';
import UploadPaymentFile from './UploadPaymentFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createPayment from '../../apis/Payments/createPayment';
import UesrContext from '../../contexts/user-context/UserProvider';
import PaymentsContext from '../../contexts/Payments-context/PaymentsProvider';

const defaultValues = {
  Payment_imgs: null
};

const AddPaymentForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setPaymentModalOpened,
    allPayments,
    setSelectedServId,
    selectedServId,
    selectedPayment,
    setSelectedPayment
  } = useContext(PaymentsContext);

  let schema;

  if (selectedPayment && selectedServId) {
    schema = Yup.object().shape({});
  } else {
    schema = Yup.object().shape({
      Payment_imgs: Yup.mixed().required('من فضلك اختار صورة الدفع')
    });
  }
  // console.log(selectedPayment);
  // console.log(selectedServId);
  useEffect(() => {
    // console.log(selectedServId);
    // console.log(allPayments);
    if (selectedServId) {
      const foundPayment = allPayments.find((serv) => serv.id === selectedServId);
      if (foundPayment) setSelectedPayment(foundPayment);
    }
  }, [selectedServId]);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (selectedPayment?.icon) {
      // console.log('selected Payment: ', selectedPayment);
      setUrls([selectedPayment.icon]);
    }
  }, [selectedPayment]);
  // console.log(selectedPayment);
  const {
    // control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  // console.log('errros: ', errors);
  // console.log('watch: ', watch('Payment_imgs'));

  const onSubmit = async (data) => {
    // console.log(selectedPayment);
    try {
      let res;
      if (selectedPayment) {
        res = await createPayment(`/payment`, data, user?.token, selectedPayment.id);
      } else {
        res = await createPayment('/payment', data, user?.token, 0);
      }
      // console.log('create Payment response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء الدفع',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setPaymentModalOpened(false);
        setSelectedServId('');
        setSelectedPayment(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء الدفع المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedPayment && selectedServId) {
      const { name_ar, name_en, desc_ar, desc_en, status, type, is_question } = selectedPayment;
      setValue('name_ar', name_ar);
      setValue('name_en', name_en);
      setValue('desc_ar', desc_ar);
      setValue('desc_en', desc_en);
      setValue('status', String(status));
      setValue('type', String(type));
      setValue('is_question', String(is_question));
    }
  }, [selectedPayment, selectedServId]);

  return (
    <Form
      className="add-Payment-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <UploadPaymentFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="Payment_imgs"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الدفع
            </>
          }
          urls={urls}
          errorMsg={errors?.Payment_imgs ? errors?.Payment_imgs?.message : ''}
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة الدفع
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddPaymentForm;
