import axios from 'axios';

const createSelles = async (url, values, token, id, type) => {
  try {
    const { password, phone, email, name, status } = values;
    const pickedValues = {
      phone,
      email,
      name
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }

    // for (let key in pickedValues) {
    //   formData.append(key, pickedValues[key]);
    // }

    if (status) {
      formData.append('status', status);
    }

    if (password) {
      formData.append('password', password);
    }

    if (type) {
      formData.append('type', type);
    }
    if (id !== 0) {
      formData.append('admin_id', id);
    }

    const createSerivceRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return createSerivceRes;
  } catch (error) {
    console.log(error);
  }
};

export default createSelles;
