/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import getAllPayments from '../../apis/Payments/getAllPayments';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import PaymentsColumns from './PaymentsTableColumns';
import PaymentsContext from '../../contexts/Payments-context/PaymentsProvider';
const PaymentsTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingPayments,
    setLoadingPayments,
    allPayments,
    setAllPayments,
    setPaymentModalOpened,
    setSelectedServId
  } = useContext(PaymentsContext);
  const [PaymentsDataSource, setPaymentsDataSource] = useState([]);

  useEffect(() => {
    const getPayments = async () => {
      try {
        setLoadingPayments(true);
        const getPaymentsRes = await getAllPayments(user?.token);
        // console.log(getPaymentsRes);
        // if (getPaymentsRes.status === 200 && getPaymentsRes?.data?.data) {
        setAllPayments(getPaymentsRes);
        setLoadingPayments(false);
        // }
      } catch (error) {
        setLoadingPayments(false);
        console.log(error);
      }
    };
    getPayments();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allPayments) {
      const mappedPayments = allPayments.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setPaymentsDataSource(mappedPayments);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allPayments, allPayments.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Payments-table"
      dataSource={PaymentsDataSource}
      columns={PaymentsColumns(
        user?.token,
        setFetchCount,
        setLoadingPayments,
        setPaymentModalOpened,
        setSelectedServId
      )}
      loading={loadingPayments}
      scroll={{ x: 1200 }}
    />
  );
};

export default PaymentsTable;
