/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import getAllPackages from '../../apis/Packages/getAllPackages';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import PackagesColumns from './PackagesTableColumns';
import PackagesContext from '../../contexts/Packages-context/PackagesProvider';
const PackagesTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingPackages,
    setLoadingPackages,
    allPackages,
    setAllPackages,
    setPackageModalOpened,
    setSelectedServId
  } = useContext(PackagesContext);
  const [PackagesDataSource, setPackagesDataSource] = useState([]);

  useEffect(() => {
    const getPackages = async () => {
      try {
        setLoadingPackages(true);
        const getPackagesRes = await getAllPackages(user?.token);
        // console.log(getPackagesRes);
        // if (getPackagesRes.status === 200 && getPackagesRes?.data?.data) {
        setAllPackages(getPackagesRes);
        setLoadingPackages(false);
        // }
      } catch (error) {
        setLoadingPackages(false);
        console.log(error);
      }
    };
    getPackages();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allPackages) {
      const mappedPackages = allPackages.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setPackagesDataSource(mappedPackages);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allPackages, allPackages?.length, fetchCount]);

  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Packages-table"
      dataSource={PackagesDataSource}
      columns={PackagesColumns(
        user?.token,
        setFetchCount,
        setLoadingPackages,
        setPackageModalOpened,
        setSelectedServId
      )}
      loading={loadingPackages}
      scroll={{ x: 1200 }}
    />
  );
};

export default PackagesTable;
