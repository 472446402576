import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';

import { store } from 'react-notifications-component';
import deleteSellesApi from '../../apis/Selles/deleteSelles';

const SellesColumns = (
  token,
  setFetchCount,
  setLoadingSelles,
  setSellesModalOpened,
  setSelectedServId
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingSelles(true);
      const res = await deleteSellesApi(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingSelles(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الموظف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingSelles(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingSelles(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleEditSelles = (key) => {
    setSellesModalOpened(true);
    setSelectedServId(key);
  };

  return [
    {
      title: '#',
      dataIndex: 'key',
      width: 62
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      width: 62
    },
    {
      title: 'البريد الألكتروني',
      dataIndex: 'email',
      width: 62
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      width: 62
    },

    {
      title: 'تعديل الموظف',
      dataIndex: 'edit',
      width: 92,
      render: (_, record) => {
        return (
          <Tooltip title="تعديل الموظف">
            <Button
              className="edit-btn"
              onClick={(key) => handleEditSelles(record.key)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'حذف الموظف',
      dataIndex: 'delete',
      width: 92,
      render: (_, record) => {
        return (
          <Popconfirm
            title={
              <div style={{ maxWidth: '200px' }}>
                <p>هل انت متأكد من حذف الموظف</p>
              </div>
            }
            okText="نعم"
            cancelText="لا"
            onConfirm={(key) => handleDeleteRequest(record.key)}>
            <Tooltip title="حذف الموظف؟">
              <Button
                className="delete-btn"
                size="large"
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Popconfirm>
        );
      }
    }
  ];
};

export default SellesColumns;
