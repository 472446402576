import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import SystemErrorsContext from '../../contexts/systemErrors-context/SystemErrorProvider';
import { Empty, Pagination, Table } from 'antd';
import SystemErrorsTableColumns from './SystemErrorsTableColumns';
import getErrorLog from '../../apis/error-log/getErrorLog';

const SystemErrorsTable = () => {
  const {
    fetchCount,
    setFetchCount,
    loadingSystemErrorsData,
    setLoadingSystemErrorsData,
    allSystemErrorsData,
    setAllSystemErrorsData,
    setSelectedSystemErrorsId,
    setSelectedSystemErrors,
    setSystemErrorsDetailsModalOpened,
    tablePagination,
    setTablePagination
  } = useContext(SystemErrorsContext);

  const [SystemErrorSource, setSystemErrorSource] = useState([]);
  const { user } = useContext(UesrContext);

  // useEffect(() => {
  //   const getPaymentCall = async () => {
  //     try {
  //         setLoadingSystemErrorsData(true);
  //         const fetchedData = await getPaymentLog(user?.token);
  //         if (fetchedData.status === 200 && fetchedData?.data?.paymentLogs?.data) {
  //           setAllSystemErrorsData(fetchedData?.data?.paymentLogs?.data);
  //           setLoadingSystemErrorsData(false);
  //         }
  //     } catch (error) {
  //         setLoadingSystemErrorsData(false);
  //         console.log(error);
  //     }
  //   };
  //   getPaymentCall();
  // }, [fetchCount]);
  useEffect(() => {
    const getErrorCall = async () => {
      try {
        setLoadingSystemErrorsData(true);
        const fetchedData = await getErrorLog(user?.token, {
          page: tablePagination.current_page,
          per_page: tablePagination.per_page
        });

        if (fetchedData.status === 200 && fetchedData?.data?.errors?.data) {
          setAllSystemErrorsData(fetchedData?.data?.errors?.data);
          setLoadingSystemErrorsData(false);
        }
        if (fetchedData?.data?.errors) {
          setTablePagination({
            current_page: fetchedData?.data?.errors?.current_page,
            per_page: fetchedData?.data?.errors?.per_page,
            total: fetchedData?.data?.errors?.total
          });
        }
      } catch (error) {
        setLoadingSystemErrorsData(false);
        console.log(error);
      }
    };
    getErrorCall();
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allSystemErrorsData) {
      const mappedSystemErrorsData = allSystemErrorsData?.map((serv) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setSystemErrorSource(mappedSystemErrorsData);
        // setSystemErrorSource(mappedBlogData.pack_id);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allSystemErrorsData, allSystemErrorsData?.length, fetchCount]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
        }}
        pagination={false}
        className="PaymentLog-Table"
        dataSource={SystemErrorSource}
        columns={SystemErrorsTableColumns(
          user?.token,
          setFetchCount,
          setLoadingSystemErrorsData,
          setSelectedSystemErrorsId,
          setSystemErrorsDetailsModalOpened,
          setSelectedSystemErrors,
          setSelectedSystemErrors
        )}
        loading={loadingSystemErrorsData}
        scroll={{ x: 800 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            setFetchCount((prev) => prev + 1);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default SystemErrorsTable;
