/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditOutlined, SendOutlined } from '@ant-design/icons';
// import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
// import { EditorState, ContentState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
import './AddServiceForm.scss';
import UploadServiceFile from './UploadServiceFile';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createService from '../../apis/services/createService';
import UesrContext from '../../contexts/user-context/UserProvider';
import ServicesContext from '../../contexts/services-context/ServicesProvider';

const defaultValues = {
  service_imgs: null
};

const AddServiceForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setServiceModalOpened,
    allServices,
    setSelectedServId,
    selectedServId,
    selectedService,
    setSelectedService
  } = useContext(ServicesContext);

  let schema;

  if (selectedService && selectedServId) {
    schema = Yup.object().shape({});
  } else {
    schema = Yup.object().shape({
      service_imgs: Yup.mixed().required('من فضلك اختار صورة الشركه')
    });
  }
  // console.log(selectedService);
  // console.log(selectedServId);
  useEffect(() => {
    // console.log(selectedServId);
    // console.log(allServices);
    if (selectedServId) {
      const foundService = allServices.find((serv) => serv.id === selectedServId);
      if (foundService) setSelectedService(foundService);
    }
  }, [selectedServId]);

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    if (selectedService?.icon) {
      // console.log('selected service: ', selectedService);
      setUrls([selectedService.icon]);
    }
  }, [selectedService]);
  // console.log(selectedService);
  const {
    // control,
    // getValues,
    setValue,
    // setError,
    // clearErrors,
    handleSubmit,
    register,
    watch,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  // console.log('errros: ', errors);
  // console.log('watch: ', watch('service_imgs'));

  const onSubmit = async (data) => {
    // console.log(selectedService);
    try {
      let res;
      if (selectedService) {
        res = await createService(`/company`, data, user?.token, selectedService.id);
      } else {
        res = await createService('/company', data, user?.token, 0);
      }
      // console.log('create service response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء الشركه',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setServiceModalOpened(false);
        setSelectedServId('');
        setSelectedService(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم انشاء الشركه المطلوبة',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();

  // handle initial values
  useEffect(() => {
    if (selectedService && selectedServId) {
      const { name_ar, name_en, desc_ar, desc_en, status, type, is_question } = selectedService;
      setValue('name_ar', name_ar);
      setValue('name_en', name_en);
      setValue('desc_ar', desc_ar);
      setValue('desc_en', desc_en);
      setValue('status', String(status));
      setValue('type', String(type));
      setValue('is_question', String(is_question));
    }
  }, [selectedService, selectedServId]);

  return (
    <Form
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <UploadServiceFile
          accept="image/png, image/jpg, image/jpeg, image/gif"
          maxFiles={1}
          name="service_imgs"
          setValue={setValue}
          watch={watch}
          register={register}
          unregister={unregister}
          multiple={false}
          label={
            <>
              <EditOutlined />
              صورة الشركه
            </>
          }
          urls={urls}
          errorMsg={errors?.service_imgs ? errors?.service_imgs?.message : ''}
        />

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          اضافة الشركه
        </Button>
      </div>

      {/* <div dir="ltr">
        <h1>Values</h1>
        <pre>{JSON.stringify(getValues(), null, 2)}</pre>
      </div> */}
    </Form>
  );
};

export default AddServiceForm;
