import axios from 'axios';

const deleteQuestionApi = async (questionId, token) => {
  try {
    const formData = new FormData();

    if (questionId) {
      formData.append('id', questionId);
    }

    const response = await axios.post(`/feature-delete`, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    
    // if (response.status === 200 && response.data.status === 1) {
    return response;
    // }
  } catch (e) {
    console.log(e);
  }
};

export default deleteQuestionApi;
