/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { store } from 'react-notifications-component';
import UesrContext from '../../contexts/user-context/UserProvider';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
// import getCategories from '../../apis/category-apis/getCategories';
import QuestionsContext from '../../contexts/questions-context/QuestionsProvider';
import createQuestion from '../../apis/questions/createQuestion';

import './AddQuestionForm.scss';
import getAllPackages from '../../apis/Packages/getAllPackages';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import editCustomerEndDate from '../../apis/PackageDetailes/editCusomerEndDate';
import DateForm from './DateForm';

const schema = Yup.object().shape({});



const defaultValues = {};
const AddQuestionForm = () => {
  const { user } = useContext(UesrContext);
  const {
    setFetchCount,
    setQuestionModalOpened,

    setSelectedQuestionId,
    selectedQuestion,
    setSelectedQuestion
  } = useContext(CustomerContext);

  
  const {
    control,
    setValue,
    handleSubmit,
    reset,

    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  const [form] = Form.useForm();
  // 
  const onSubmit = async (data) => {
    
    try {
      let res;

      let dataPush = { ...data, end_date: targetDate };
      res = await editCustomerEndDate(
        '/editPackage',
        dataPush,
        user?.token,
        selectedQuestion?.ref_key
      );

      if (res.status === 200 && res.data.status === 0) {
        store.addNotification({
          title: 'حدث خطأ اثناء انشاء السؤال',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res.status === 200 && res.data.status === 1) {
        reset({ ...defaultValues });
        setQuestionModalOpened(false);
        setSelectedQuestionId('');
        setSelectedQuestion(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم تعديل باقه المستخدم',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      
    }
  };

  const [targetDate, setTargetDate] = useState(null);
  const [categories, setCategoires] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await getAllPackages(user?.token);

        if (res?.length) {
          setCategoires(res.map((d) => ({ title: d?.name, value: d?.id })));
        }
      } catch (error) {
        
      }
    };
    fetchCategories();
  }, []);

  return (
    <Form
      className="add-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdSelectOption
          name="ref_key"
          placeholder="من فضلك اختار الباقه الجديده..."
          errorMsg={errors?.ref_key?.message}
          validateStatus={errors?.ref_key ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={categories?.length ? categories : []}
          label={
            <p className="select-option-label">
              <EditOutlined />
              اختار الباقه الجديده
            </p>
          }
          formClassName="add-service-form"
        />

        <DateForm {...{ targetDate, setTargetDate }} />

        <Button className="submit-btn" htmlType="submit" type="primary" loading={isSubmitting}>
          تعديل
        </Button>
      </div>
    </Form>
  );
};

export default AddQuestionForm;
