import React, { useContext, useEffect, useState } from 'react';
import UesrContext from '../../contexts/user-context/UserProvider';
import getPaymentLog from '../../apis/payment-log/getPaymentLog';
import PaymentLogsContext from '../../contexts/payment-logs-conrext/PaymentLogsProvider';
import { Table, Empty, Pagination } from 'antd';
import paymentLogsTableColumns from './paymentLogsTableColumns';

const PaymentLogTable = () => {
  const {
    fetchCount,
    setFetchCount,
    loadingPaymentLogsData,
    setLoadingPaymentLogsData,
    allPaymentLogsData,
    setAllPaymentLogsData,
    setSelectedPaymentLogsId,
    setSelectedPaymentLogs,
    setPaymentLogsDetailsModalOpened,
    setPaymentLogsInvoiceModalOpened,
    allPaymentLogsInfo,
    setAllPaymentLogsInfo,
    tablePagination,
    setTablePagination
  } = useContext(PaymentLogsContext);
  const [paymentLogSource, setPaymentLogSource] = useState([]);
  const { user } = useContext(UesrContext);

  const token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOTEzZjI4ZDYxNmM4MDU1MGZhNWE3YzNhN2RiODZkNTdmNGI5MjU3YmE1MzEyMDA4MjQ0ZjhhZGZhNjczZGNmZGQ0Y2NjYmZlZDNjOWM1YjgiLCJpYXQiOjE2ODI1OTA4MTkuMzUxOTEyOTc1MzExMjc5Mjk2ODc1LCJuYmYiOjE2ODI1OTA4MTkuMzUxOTE2MDc0NzUyODA3NjE3MTg3NSwiZXhwIjoxNzE0MjEzMjE5LjM0NDE1MTk3MzcyNDM2NTIzNDM3NSwic3ViIjoiMTM3Iiwic2NvcGVzIjpbXX0.eAHyfgAGqB6aocDYc3IhU5qb8vwh5qXS2Vc8W8Cs4CER-6RYfi1ess8MjpykOTpHuus-Jz1fnSyQTxjH44G9JUZ16I2l8SkkLnJ_vKqvIcr06hCB-g-C9gQh-O94jFxxKi2P8ZRL0hSap5BZDXZOkNLTK_qr3_VfEOjNzSuJQm00A3RLeyShu6dEl-zJZx4fzWXq-EAD0OUwufQLR-D4ATRS_KwA_5WSeAGAxbTvYqSBhzKjn445rS1fVwGGRUWu_fHOWZU0JG5cY4H4HaGYwF7BBM5Ck36_PD5P34-tfLinhEPTu6AHvChcxbyj9qi3XDftH1pDZDrBQOUWpUxGaGCMC8x8aHYmyE1YJuPQGqfg0w7ODZt_9wYr6YDvqBobc1XvXK5SkqfRVrt31qA_Nr9Ks0RykM0B3ZW_qtZXcUR1j-Ov5mXhgoN_YXpzSQ62puKdK4JRehDEeQdbuH1h-s3B1KDr_upfM7QNsMwxXREIqsIDozgwMCfILoORw0q5WEYxucomJKem4OV2OGypLuHcSjLot05WEFAeBteDSHqHBRZ60tSeHxK0FenIYM-qo4nI94ud5CYzmO7JC366g7342gOb5QZt27SUxEy7ssqzxQLeeWyxySa5jiTvigZ9IHkGJiZlEIZTjxmY3QRIoBKouhvvke5X5gSpFngWeN0';
  useEffect(() => {
    const getPaymentCall = async () => {
      try {
        setLoadingPaymentLogsData(true);
        const fetchedData = await getPaymentLog(user.token, { page: tablePagination.current_page });
        if (fetchedData.status === 200 && fetchedData?.data) {
          // setAllPaymentLogsData(fetchedData?.data?.paymentLogs);
          setAllPaymentLogsData(fetchedData?.data?.paymentLogs?.data);

          setAllPaymentLogsInfo(fetchedData?.data?.information);
          setLoadingPaymentLogsData(false);
        }
        if (fetchedData?.data?.paymentLogs) {
          setTablePagination({
            current_page: fetchedData?.data?.paymentLogs?.current_page,
            per_page: fetchedData?.data?.paymentLogs?.per_page,
            total: fetchedData?.data?.paymentLogs?.total
          });
        }
        // if (fetchedData?.data?.paymentLogs?.pagination) {
        //   setTablePagination(fetchedData?.data?.paymentLogs?.pagination)
        // }
      } catch (error) {
        setLoadingPaymentLogsData(false);
        console.log(error);
      }
    };
    getPaymentCall();
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allPaymentLogsData) {
      const mappedPaymentLogsData = allPaymentLogsData?.map((serv) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setPaymentLogSource(mappedPaymentLogsData);
        // setPaymentLogSource(mappedBlogData.pack_id);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allPaymentLogsData, allPaymentLogsData?.length, fetchCount]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
        }}
        pagination={false}
        className="PaymentLog-Table"
        dataSource={paymentLogSource}
        columns={paymentLogsTableColumns(
          user.token,
          setFetchCount,
          setLoadingPaymentLogsData,
          setSelectedPaymentLogsId,
          setPaymentLogsDetailsModalOpened,
          setPaymentLogsInvoiceModalOpened,
          setSelectedPaymentLogs
        )}
        loading={loadingPaymentLogsData}
        scroll={{ x: 800 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            setFetchCount((prev) => prev + 1);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PaymentLogTable;
