/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import React, { useContext, useEffect, useState } from 'react';
import getAllAdmin from '../../apis/Admin/getAllAdmin';
import AdminContext from '../../contexts/Admin-context/AdminProvider';
import UesrContext from '../../contexts/user-context/UserProvider';
import AdminColumns from './AdminTableColumns';

const AdminTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingAdmin,
    setLoadingAdmin,
    allAdmin,
    setAllAdmin,
    setAdminModalOpened,
    setSelectedAdmin,
    setSelectedServId
  } = useContext(AdminContext);
  const [AdminDataSource, setAdminDataSource] = useState([]);

  useEffect(() => {
    const getAdmin = async () => {
      try {
        setLoadingAdmin(true);
        const getAdminRes = await getAllAdmin(user?.token, user?.type);
        // console.log(getAdminRes);
        // if (getAdminRes.status === 200 && getAdminRes?.data?.data) {
        setAllAdmin(getAdminRes);
        setLoadingAdmin(false);
        // }
      } catch (error) {
        setLoadingAdmin(false);
        console.log(error);
      }
    };
    getAdmin();
  }, [fetchCount]);

  useEffect(() => {
    let isMounted = true;

    if (allAdmin) {
      const mappedAdmin = allAdmin.map((serv, index) => ({
        ...serv,
        key: serv.id
      }));
      if (isMounted) {
        setAdminDataSource(mappedAdmin);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allAdmin, allAdmin?.length, fetchCount]);

  const cols = AdminColumns(
    user?.token,
    user?.type,
    setFetchCount,
    setLoadingAdmin,
    setAdminModalOpened,
    setSelectedServId
  );

  const expordDataToExcel = () => {
    let slicedCols = cols.slice(0, -2);
    console.log(cols);
    console.log(slicedCols);
    const excel = new Excel();
    console.log('ssss', excel);
    console.log(AdminDataSource);

    excel
      .addSheet('Sheet 1')
      .addColumns(slicedCols)
      .addDataSource(AdminDataSource, {
        str2Percent: true
      })
      .saveAs('المسوقين.xlsx');
  };
  // let headers = [
  //   { label: 'id', key: 'id' },
  //   { label: 'الاسم', key: 'name' },
  //   { label: 'رقم الهاتف', key: 'phone' },
  //   { label: 'البريد الألكتروني	', key: 'email' },
  //   { label: 'الكود', key: 'code' },
  //   { label: 'المنصه', key: 'code' },
  //   { label: 'نسبة الخصم للكود	', key: 'discount' },
  //   { label: 'نسبة المسوق	', key: 'my_ratio' }
  //   // { label: ' 	', key: 'email' }
  // ];
  // console.log(AdminDataSource[0]);

  return (
    <>
      {/* <CSVLink
        data={AdminDataSource}
        headers={headers}
        className="ant-btn     ant-btn-rtl "
        style={{
          marginBottom: '15px',
          backgroundColor: '#35bb71',
          color: '#fff',
          borderColor: '#35bb71'
        }}>
        <FileExcelOutlined style={{ marginLeft: '5px' }} /> تصدير اكسل
      </CSVLink> */}
      {/* <button
        onClick={expordDataToExcel}
        className="ant-btn     ant-btn-rtl "
        style={{
          marginBottom: '15px',
          backgroundColor: '#35bb71',
          color: '#fff',
          borderColor: '#35bb71'
        }}>
        <FileExcelOutlined style={{ marginLeft: '5px' }} /> تصدير اكسل
      </button> */}

      <Table
        pagination={{ pageSize: 5 }}
        className="Admin-table"
        dataSource={AdminDataSource}
        columns={AdminColumns(
          user?.token,
          user?.type,
          setFetchCount,
          setLoadingAdmin,
          setAdminModalOpened,
          setSelectedServId,
          setSelectedAdmin
        )}
        loading={loadingAdmin}
        scroll={{ x: 1200 }}
      />
    </>
  );
};

export default AdminTable;
