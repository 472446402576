import axios from 'axios';

const deletePosPackage = async (token, PackageId ) => {
  try {
    const response = await axios.post(`/package-pos/delete/${PackageId}`, '',{
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default deletePosPackage;
