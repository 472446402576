import { DatePicker, Space } from 'antd';
import React, { useCallback } from 'react';
const { RangePicker } = DatePicker;
const CustomerFilterDate = ({ filterDataValue, setFilterDataValue }) => {
  const onChangeDate = useCallback(
    (date, dateString) => {
      // let datax = new Date(date);
      // console.log(date);
      if (dateString[0] !== '' && dateString[1] !== '') {
        let dataTarget = { startDate: dateString[0], endDate: dateString[1] };
        setFilterDataValue(dataTarget);
        console.log(dataTarget);
      } else {
        setFilterDataValue(null);
      }
    },
    [setFilterDataValue]
  );

  return (
    <>
      <div>
        {/* <Space direction="vertical" size={12}> */}
        <RangePicker
          placeholder={['تاريخ من', 'تاريخ الى']}
          style={{ fontSize: '18px', padding: '7.5px 11px ', borderRadius: '4px ' }}
          onChange={onChangeDate}
        />
        {/* </Space> */}
      </div>
    </>
  );
};

export default CustomerFilterDate;
