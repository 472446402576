import { Modal } from 'antd';
import React from 'react';
import { useContext } from 'react';
import SystemErrorsContext from '../../contexts/systemErrors-context/SystemErrorProvider';
import SystemErrorsDetailsModal from './SystemErrorsModal';
import SystemErrorsTable from './SystemErrorsTable';
import './SystemErrors.scss';

const SystemErrors = () => {
  const {
    setSelectedSystemErrorsId,
    setSelectedSystemErrors,
    SystemErrorsDetailsModalOpened,
    setSystemErrorsDetailsModalOpened,
    selectedSystemErrors
  } = useContext(SystemErrorsContext);

  return (
    <div className="SystemErrors-container">
      <SystemErrorsTable />
      {SystemErrorsDetailsModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '1100px' }}
          title={'عرض التفاصيل'}
          centered
          visible={SystemErrorsDetailsModalOpened}
          onOk={() => {
            setSystemErrorsDetailsModalOpened(false);
            setSelectedSystemErrorsId('');
            setSelectedSystemErrors(null);
          }}
          onCancel={() => {
            setSystemErrorsDetailsModalOpened(false);
            setSelectedSystemErrorsId('');
            setSelectedSystemErrors(null);
          }}
          footer={false}>
          <SystemErrorsDetailsModal selectedSystemErrors={selectedSystemErrors} />
          {/* <BlogDetailsModel selectedBlog={selectedBlog} /> */}
        </Modal>
      )}
    </div>
  );
};

export default SystemErrors;
