import { Descriptions } from 'antd';
import React from 'react';
import './ViewTechnicalSupport.scss';
import TechnicalSupportContext from '../../contexts/Technical-support/TechnicalSupportProvider';
import { useContext } from 'react';

const ViewTechnicalSupport = () => {
  const { selectedTechnicalSupport } = useContext(TechnicalSupportContext);
  // console.log(selectedTechnicalSupport);

  return (
    <>
      <div className="view-technical-support">
        <Descriptions title="" bordered column={1}>
          {selectedTechnicalSupport?.client?.name && (
            <Descriptions.Item
              label="الاسم"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.client?.name}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.subject && (
            <Descriptions.Item
              label="الموضوع"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.subject}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.priority && (
            <Descriptions.Item
              label="الأولوية"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.priority}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.status && (
            <Descriptions.Item
              label="حالة"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.status}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.client?.start_date && (
            <Descriptions.Item
              label="تاريخ النشاء"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.client?.start_date}
            </Descriptions.Item>
          )}
          {selectedTechnicalSupport?.description && (
            <Descriptions.Item
              label="الوصف"
              style={{
                fontSize: '17px',
                color: '#000',
                fontWeight: '500'
              }}>
              {selectedTechnicalSupport?.description}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    </>
  );
};

export default ViewTechnicalSupport;
