import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip, Popconfirm } from 'antd';
import React from 'react';
import deletePaymentLog from '../../apis/payment-log/deletePaymentLog';
import { store } from 'react-notifications-component';
import moment from 'moment';

const paymentLogsTableColumns = (
  token,
  setFetchCount,
  setLoadingPaymentLogsData,
  setSelectedPaymentLogsId,
  setPaymentLogsDetailsModalOpened,
  setPaymentLogsInvoiceModalOpened,
  setSelectedPaymentLogs
) => {
  const handleDeleteRequest = async (key) => {
    try {
      setLoadingPaymentLogsData(true);
      const res = await deletePaymentLog(key, token);
      if (res?.status === 200 && res?.data?.response_code === 200) {
        setFetchCount((prevState) => prevState + 1);
        setLoadingPaymentLogsData(false);
        store.addNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم الحذف بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else {
        setLoadingPaymentLogsData(false);
        store.addNotification({
          title: 'حدث خطأ ',
          message: res?.data?.message || 'حاول فى وقت لاحق',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
      setLoadingPaymentLogsData(false);
      store.addNotification({
        title: 'حدث خطأ ',
        message: 'حاول فى وقت لاحق',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  const handleViewPaymentInvoiceModal = (record) => {
    setSelectedPaymentLogs(record);
    setPaymentLogsInvoiceModalOpened(true);
  };

  const handleViewPaymentDetailsModal = (record) => {
    setSelectedPaymentLogs(record);
    setPaymentLogsDetailsModalOpened(true);
  };

  return [
    {
      title: '#',
      dataIndex: 'id',
      width: 62,
      key: 'id'
    },
    {
      title: 'اسم المستخدم',
      dataIndex: 'name',
      key: 'name',
      width: 62,
      render: (_, record) => {
        console.log("record",record);
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record.name }}></div>
        );
      }
    },
    {
      title: 'البريد الالكتروني',
      dataIndex: 'email',
      key: 'email',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record.email }}></div>
        );
      }
    },
    {
      title: 'المبلغ الكلي المدفوع',
      dataIndex: 'total',
      key: 'total',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record.total }}></div>
        );
      }
    },
    {
      title: 'حالة الدفع',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      render: (_, record) => {
        return (
          <div className="service-img"  dangerouslySetInnerHTML={{ __html: record?.payment_status ? "تم الدفع" : "لم يتم الدفع" }}></div>
        );
      }
    },
    {
      title:"تاريخ الإنشاء",
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html:moment(record?.created_at).format("YYYY-MM-DD") }}></div>
        );
      }
    },
    {
      title: 'تاريخ الدفع',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record?.payment_date }}></div>
        );
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'total',
      key: 'total',
      width: 62,
      render: (_, record) => {
        return (
          <div className="service-img" dangerouslySetInnerHTML={{ __html: record?.phone }}></div>
        );
      }
    },
    {
      title: 'التفاصيل',
      dataIndex: 'details',
      width: 82,
      render: (_, record) => {
        return (
          <Tooltip title="تفاصيل">
            <Button
              className="edit-btn PaymentLog-btn"
              onClick={() => handleViewPaymentDetailsModal(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EyeOutlined />}
            />
          </Tooltip>
        );
      }
    },
    {
      title: 'معاينة الفاتورة',
      dataIndex: 'details',
      width: 82,
      render: (_, record) => {
        return (
          <Tooltip title="معاينة الفاتورة">
            <Button
              className="edit-btn PaymentLog-btn"
              onClick={() => handleViewPaymentInvoiceModal(record)}
              size="large"
              type="dashed"
              shape="circle"
              icon={<EyeOutlined />}
            />
          </Tooltip>
        );
      }
    },
    // {
    //   title: 'حذف',
    //   dataIndex: 'delete',
    //   width: 92,
    //   key: 'delete',
    //   render: (_, record) => {
    //     return (
    //       <Popconfirm
    //         title="هل أنت متأكد من حذف المقالة؟"
    //         onConfirm={(key) => {
    //           handleDeleteRequest(record.key);
    //         }}
    //         okText="نعم"
    //         cancelText="لا">
    //         <Tooltip title="حذف المقالة؟">
    //           <Button
    //             className="delete-btn"
    //             size="large"
    //             type="dashed"
    //             shape="circle"
    //             icon={<DeleteOutlined />}
    //           />
    //         </Tooltip>
    //       </Popconfirm>
    //     );
    //   }
    // }
  ];
};

export default paymentLogsTableColumns;
