import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingAdmin: false,
  setLoadingAdmin: (load) => {},
  allAdmin: [],
  setAllAdmin: (Admin) => {},
  AdminModalOpened: false,
  setAdminModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedAdmin: null,
  setSelectedAdmin: (servKey) => {}
};

const AdminContext = createContext(INITIAL_VALUES);

export const AdminProvider = ({ children }) => {
  const [allAdmin, setAllAdmin] = useState(INITIAL_VALUES.allAdmin);
  const [loadingAdmin, setLoadingAdmin] = useState(INITIAL_VALUES.loadingAdmin);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [AdminModalOpened, setAdminModalOpened] = useState(INITIAL_VALUES.AdminModalOpened);
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedAdmin, setSelectedAdmin] = useState(INITIAL_VALUES.selectedAdmin);

  return (
    <AdminContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingAdmin,
        setLoadingAdmin,
        allAdmin,
        setAllAdmin,
        AdminModalOpened,
        setAdminModalOpened,
        selectedServId,
        setSelectedServId,
        selectedAdmin,
        setSelectedAdmin
      }}>
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
