import axios from 'axios';

const updateInfo = async (values, token, lat, lng) => {
  try {
    const {
      address,
      phone,
      email,
      facebook,
      instagram,
      linkedin,
      twitter,
      pos_price,
      pos_price_year,
      policy_ar,
      policy_en,

      condition_ar,
      condition_en,
      return_en,
      return_ar
    } = values;
    const pickedValues = {
      address,
      phone,
      email,
      facebook,
      instagram,
      linkedin,
      twitter,
      pos_price,
      pos_price_year,
      policy_ar,
      policy_en,
      condition_ar,
      condition_en,
      return_en,
      return_ar
    };
    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    formData.append('lat', lat);
    formData.append('lng', lng);

    const response = await axios.post('/information', formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    // if (response.status === 200 && response.data.status === 1 && response.data.data) {
    return response;
    // }
  } catch (e) {
    console.log(e);
  }
};

export default updateInfo;
