/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import getAllPackages from '../../apis/Packages/getAllPackages';
import UesrContext from '../../contexts/user-context/UserProvider';
import { Table } from 'antd';
import PackagesColumns from './PackagesTableColumns';
import PosPackagesContext from '../../contexts/Pos-Packages-context/PosPackagesProvider';
import getAllPosPackages from '../../apis/Pos-Packages/getAllPosPackages';
const PackagesTable = () => {
  const { user } = useContext(UesrContext);
  const {
    fetchCount,
    setFetchCount,
    loadingPackages,
    setLoadingPackages,
    allPackages,
    setAllPackages,
    setPackageModalOpened,
    setSelectedServId
  } = useContext(PosPackagesContext);

  const [PackagesDataSource, setPackagesDataSource] = useState([]);

  useEffect(() => {
    const getPackages = async () => {
      try {
        setLoadingPackages(true);
        const getPackagesRes = await getAllPosPackages(user?.token);
        console.log(getPackagesRes?.data);
        // console.log(getPackagesRes);
        // if (getPackagesRes.status === 200 && getPackagesRes?.data?.data) {
        setAllPackages(getPackagesRes?.data);
        setLoadingPackages(false);
        // }
      } catch (error) {
        setLoadingPackages(false);
        console.log(error);
      }
    };
    getPackages();
  }, [fetchCount]);
  console.log(allPackages);
  useEffect(() => {
    let isMounted = true;

    if (allPackages && allPackages?.length > 0) {
      const mappedPackages = allPackages?.map((serve, index) => ({
        ...serve,
        key: serve.id
      }));
      if (isMounted) {
        setPackagesDataSource(mappedPackages);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allPackages, allPackages?.length, fetchCount]);
  console.log(PackagesDataSource);
  return (
    <Table
      pagination={{ pageSize: 5 }}
      className="Packages-table"
      dataSource={PackagesDataSource}
      columns={PackagesColumns(
        user?.token,
        setFetchCount,
        setLoadingPackages,
        setPackageModalOpened,
        setSelectedServId
      )}
      loading={loadingPackages}
      scroll={{ x: 1200 }}
    />
  );
};

export default PackagesTable;
