import axios from 'axios';

const toggleClientUserStatusApi = async (token, values, id) => {
  // console.log(id);
  // console.log(values);
  try {
    const res = await axios.post(`/clients/${id}/update`, values, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    // console.log(res);

    return res;
  } catch (error) {
    console.log(error);

    throw error;
  }
};

export default toggleClientUserStatusApi;
