import axios from 'axios';

const editBlog = async (url, values, token) => {
  try {
    const { title, body, photo, img_desc } = values;
    const pickedValues = { title, body,photo , img_desc };

    const formData = new FormData();
    for (let key in pickedValues) {
      formData.append(key, pickedValues[key]);
    }
    

  
    // if (service_imgs && service_imgs[0]) {
    //   formData.append('icon', service_imgs[0]);
    // }
    const editBlogRes = await axios.post(url, formData, {
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return editBlogRes;
  } catch (error) {
    console.log(error);
  }
};

export default editBlog;
