import React, { useContext } from 'react';
import PaymentLogTable from './PaymentLogTable';
import PaymentLogsContext from '../../contexts/payment-logs-conrext/PaymentLogsProvider';
import { Modal } from 'antd';
import './paymentLog.scss';
import InvoiceModalOpened from './InvoiceModalOpened';
import PaymentLogsDetailsModal from './PaymentLogsDetailsModal';

const PaymentLog = () => {
  const {
    setSelectedPaymentLogsId,
    setSelectedPaymentLogs,
    PaymentLogsDetailsModalOpened,
    setPaymentLogsDetailsModalOpened,
    PaymentLogsInvoiceModalOpened,
    setPaymentLogsInvoiceModalOpened,
    selectedPaymentLogs,
    allPaymentLogsInfo
  } = useContext(PaymentLogsContext);
  return (
    <div className="PaymentLog-container">
      <PaymentLogTable />
      {PaymentLogsDetailsModalOpened && (
        <Modal
          className="add-service-modal"
          width="90%"
          style={{ maxWidth: '900px' }}
          centered
          title={'عرض التفاصيل'}
          visible={PaymentLogsDetailsModalOpened}
          onOk={() => {
            setPaymentLogsDetailsModalOpened(false);
            setSelectedPaymentLogsId('');
            setSelectedPaymentLogs(null);
          }}
          onCancel={() => {
            setPaymentLogsDetailsModalOpened(false);
            setSelectedPaymentLogsId('');
            setSelectedPaymentLogs(null);
          }}
          footer={false}>
          <PaymentLogsDetailsModal selectedPaymentLogs={selectedPaymentLogs} />
        </Modal>
      )}
      {PaymentLogsInvoiceModalOpened && (
        <InvoiceModalOpened
          selectedPaymentLogs={selectedPaymentLogs}
          allPaymentLogsInfo={allPaymentLogsInfo}
        />
      )}
    </div>
  );
};

export default PaymentLog;
