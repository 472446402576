/* eslint-disable react-hooks/exhaustive-deps */
import { EditOutlined, SendOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { store } from 'react-notifications-component';
import './AddNfcCustomersForm.scss';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UesrContext from '../../contexts/user-context/UserProvider';
import NfcCustomersContext from '../../contexts/nfc-customers-context/NfcCustomersProvider';
import createNfcCode from '../../apis/nfc-customers/createNfcCode';
import CustomerContext from '../../contexts/customer-context/CustomerProvider';

const defaultValues = {
  customer_id: '',
  tag_nfid: '',
  status: ''
  // admin: ''
};

const AddNfcCustomerForm = () => {
  const { user } = useContext(UesrContext);
  const { allcustomer } = useContext(CustomerContext);

  const {
    setFetchCount,
    setNfcCustomersModalOpened,
    allNfcCustomers,
    setSelectedServId,
    selectedServId,
    selectedNfcCustomers,
    setSelectedNfcCustomers
  } = useContext(NfcCustomersContext);

  let schema;
  if (selectedServId) {
    schema = Yup.object().shape({
      customer_id: Yup.string().required('من فضلك ادخل العميل'),
      tag_nfid: Yup.string().required('tag_nfid من فضلك ادخل'),
      status: Yup.string().required('من فضلك ادخل الحاله')
      // admin: Yup.string().required('من فضلك ادخل المسؤل')
    });
  } else {
    schema = Yup.object().shape({
      customer_id: Yup.string().required('من فضلك ادخل العميل'),
      tag_nfid: Yup.string().required('tag_nfid من فضلك ادخل'),
      status: Yup.string().required('من فضلك ادخل الحاله')
      // admin: Yup.string().required('من فضلك ادخل المسؤل')
    });
  }

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues
  });

  console.log(selectedNfcCustomers);
  const onSubmit = async (data) => {
    try {
      let res;
      if (selectedNfcCustomers) {
        res = await createNfcCode(
          `/customernfc/update`,
          data,
          user?.token,
          selectedNfcCustomers.id,
          2
        );
      } else {
        res = await createNfcCode('/customernfc/create', data, user?.token, 0);
      }
      // console.log('create NfcCustomers response: ', res);
      if (res?.status === 200 && res?.data?.response_code !== 200) {
        store.addNotification({
          title: 'حدث خطأ',
          message: res?.data?.message || 'البيانات المدخلة غير صحيحة',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      } else if (res?.status === 200 && res?.data?.response_code === 200) {
        reset({ ...defaultValues });

        setNfcCustomersModalOpened(false);
        setSelectedServId('');
        setSelectedNfcCustomers(null);
        setFetchCount((prevState) => prevState + 1);
        store.addNotification({
          title: 'تمت العملية بنجاح',
          message: 'تم اضافة كود nfc بنجاح',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [form] = Form.useForm();
  // handle initial values
  useEffect(() => {
    if (selectedNfcCustomers) {
      const { tag_nfid, status } = selectedNfcCustomers;
      setValue('customer_id', String(selectedNfcCustomers.customer.id));
      setValue('tag_nfid', tag_nfid);
      setValue('status', String(status));
      // setValue('admin', admin);
    }
  }, [selectedNfcCustomers]);

  return (
    <Form
      className="add-NfcCustomers-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}>
      <div className="form-body">
        <AntdSelectOption
          name="customer_id"
          errorMsg={errors?.customer_id?.message}
          validateStatus={errors?.customer_id ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={
            allNfcCustomers?.length > 0 &&
            allNfcCustomers.map((item) => ({
              title: item.name,
              value: item.id
            }))
          }
          label={
            <p className="select-option-label">
              <EditOutlined />
              اختر العميل
            </p>
          }
          formClassName="add-NfcCustomers-form"
        />

        <div className="form-field-wrapper">
          <AntdTextField
            name="tag_nfid"
            type="text"
            errorMsg={errors?.tag_nfid?.message}
            validateStatus={errors?.tag_nfid ? 'error' : ''}
            control={control}
            label={
              <p className="select-option-label">
                <EditOutlined />
                tag-nfid
              </p>
            }
          />
        </div>
        <AntdSelectOption
          name="status"
          errorMsg={errors?.status?.message}
          validateStatus={errors?.status ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'مفعل', value: '1' },
            { title: 'غير مفعل', value: '0' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              اختر الحـاله
            </p>
          }
          formClassName="add-NfcCustomers-form"
        />
        {/* 
        <AntdSelectOption
          name="admin"
          errorMsg={errors?.admin?.message}
          validateStatus={errors?.admin ? 'error' : ''}
          control={control}
          setValue={setValue}
          options={[
            { title: 'الكل ', value: '3' },
            { title: 'جملة', value: '1' },
            { title: 'خدمات', value: '2' }
          ]}
          label={
            <p className="select-option-label">
              <EditOutlined />
              اختر المسؤل
            </p>
          }
          formClassName="add-NfcCustomers-form"
        /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          icon={<SendOutlined />}
          loading={isSubmitting}>
          {selectedNfcCustomers ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default AddNfcCustomerForm;
