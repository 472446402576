import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (count) => {},
  loadingPackageDetailes: false,
  setLoadingPackageDetailes: (load) => {},
  allPackageDetailes: [],
  setAllPackageDetailes: (PackageDetailes) => {},
  PackageDetailesModalOpened: false,
  setPackageDetailesModalOpened: (v) => {},
  selectedServId: '',
  setSelectedServId: (id) => {},
  selectedPackageDetailes: null,
  setSelectedPackageDetailes: (servKey) => {}
};

const PackageDetailesContext = createContext(INITIAL_VALUES);

export const PackageDetailesProvider = ({ children }) => {
  const [allPackageDetailes, setAllPackageDetailes] = useState(INITIAL_VALUES.allPackageDetailes);
  const [loadingPackageDetailes, setLoadingPackageDetailes] = useState(
    INITIAL_VALUES.loadingPackageDetailes
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [PackageDetailesModalOpened, setPackageDetailesModalOpened] = useState(
    INITIAL_VALUES.PackageDetailesModalOpened
  );
  const [selectedServId, setSelectedServId] = useState(INITIAL_VALUES.selectedServId);
  const [selectedPackageDetailes, setSelectedPackageDetailes] = useState(
    INITIAL_VALUES.selectedPackageDetailes
  );

  return (
    <PackageDetailesContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        loadingPackageDetailes,
        setLoadingPackageDetailes,
        allPackageDetailes,
        setAllPackageDetailes,
        PackageDetailesModalOpened,
        setPackageDetailesModalOpened,
        selectedServId,
        setSelectedServId,
        selectedPackageDetailes,
        setSelectedPackageDetailes
      }}>
      {children}
    </PackageDetailesContext.Provider>
  );
};

export default PackageDetailesContext;
